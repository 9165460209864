import React, { useEffect } from 'react';
import styles from '../../../styles/UserManual.module.scss';

function CSUserManual() {
  const addZoom = target => {
    // (A) FETCH CONTAINER + IMAGE
    let container = document.getElementById(target);
    let imgsrc =
      container.currentStyle || window.getComputedStyle(container, false);
    imgsrc = imgsrc.backgroundImage.slice(4, -1).replace(/"/g, '');
    let img = new Image();
    // (B) LOAD IMAGE + ATTACH ZOOM
    img.src = imgsrc;
    img.addEventListener('load', () => {
      let imgWidth = img.naturalWidth;
      let imgHeight = img.naturalHeight;
      let ratio = imgHeight / imgWidth;
      // (C) ZOOM ON MOUSE MOVE
      container.addEventListener('mousemove', e => {
        let boxWidth = container.clientWidth;
        let rect = e.target.getBoundingClientRect();
        let xPos = e.clientX - rect.left;
        let yPos = e.clientY - rect.top;
        let xPercent = xPos / (boxWidth / 100) + '%';
        let yPercent = yPos / ((boxWidth * ratio) / 100) + '%';
        Object.assign(container.style, {
          backgroundPosition: xPercent + ' ' + yPercent,
          backgroundSize: imgWidth / 2 + 'px',
        });
      });
      // (D) RESET ON MOUSE LEAVE
      container.addEventListener('mouseleave', () => {
        Object.assign(container.style, {
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        });
      });
    });
  };

  useEffect(() => {
    let images = [
      'activate',
      'activate-page',
      'iwomm-main-sidebar',
      'iwomm-sidebar-open',
      'iwomm-sidebar-closed',
      'create-new-lsr',
      'three-dots-lsr-table',
      'download-report-detail',
      'list-view-tab',
      'clex-create-article',
      'my-account-dropdown',
      'help-feedback-dropdown',
      'user-manual-dropdown',
    ];
    for (let i = 0; i < images.length; i++) {
      addZoom(images[i]);
    }
  }, []);

  return (
    <div className={styles['body-container']}>
      <div className={styles['user-manual-sidebar-container']}>
        <ul className='nav flex-column'>
          <li className='nav-item'>
            <a
              className={`${styles['pointer']} nav-link`}
              data-bs-toggle='collapse'
              href='#iwommIntroCollapse'
              aria-expanded='true'
              aria-controls='iwommIntroCollapse'>
              Intro to Navigating IWOMM
            </a>
            <div
              className={`collapse ${styles['user-manual-sub-nav']} show`}
              aria-expanded='true'
              id='iwommIntroCollapse'>
              <ul>
                <li>
                  <a href='#logging-in'>Logging in</a>
                </li>
                <li>
                  <a href='#dash-banner'>Dashboard and Sidebar</a>
                </li>
                <li className={`nav-item ${styles['nested-nav-item']}`}>
                  <a
                    className={`${styles['pointer']} ${styles['indicator']} nav-link`}
                    data-bs-toggle='collapse'
                    href='#accountCollapse'
                    aria-expanded='false'
                    aria-controls='accountCollapse'>
                    My Account
                  </a>
                  <div
                    className='collapse show'
                    aria-expanded='true'
                    id='accountCollapse'>
                    <ul>
                      <li>
                        <a href='#profile'>Profile</a>
                      </li>
                      <li>
                        <a href='#help-feedback'>Help &amp; Feedback</a>
                      </li>
                      <li>
                        <a href='#user-manual'>User Manual</a>
                      </li>
                      <li>
                        <a href='#logout'>Logout</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className='nav-item'>
            <a className={`${styles['pointer']} nav-link`} href='#my-tasks'>
              My Tasks
            </a>
          </li>
          <li className='nav-item'>
            <a
              className={`${styles['pointer']} nav-link`}
              data-bs-toggle='collapse'
              href='#lsRequestCollapse'
              aria-expanded='true'
              aria-controls='lsRequestCollapse'>
              LS Requests
            </a>
            <div
              className={`collapse ${styles['user-manual-sub-nav']} show`}
              aria-expanded='true'
              id='lsRequestCollapse'>
              <ul>
                <li>
                  <a href='#ls-request-faqs'>LS Request FAQs</a>
                </li>
                <li>
                  <a href='#create-ls-request'>Creating a New LS Request</a>
                </li>
                <li>
                  <a href='#update-ls-request'>Updating an LS Request</a>
                </li>
                <li>
                  <a href='#sub-ls'>Sub LS Request</a>
                </li>
                <li className={`nav-item ${styles['nested-nav-item']}`}>
                  <a
                    className={`${styles['pointer']} ${styles['indicator']} nav-link`}
                    data-bs-toggle='collapse'
                    href='#searchingLsrCollapse'
                    aria-expanded='true'
                    aria-controls='searchingLsrCollapse'>
                    Searching LS Requests
                  </a>
                  <div
                    className='collapse show'
                    aria-expanded='true'
                    id='searchingLsrCollapse'>
                    <ul>
                      <li>
                        <a href='#ls-request-filters'>Filters</a>
                      </li>
                      <li>
                        <a href='#ls-request-search-bar'>Search bar</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href='#export-ls-request'>Export to Excel</a>
                </li>
                <li className={`nav-item ${styles['nested-nav-item']}`}>
                  <a
                    className={`${styles['pointer']} ${styles['indicator']} nav-link`}
                    data-bs-toggle='collapse'
                    href='#dupCheckCollapse'
                    aria-expanded='true'
                    aria-controls='dupCheckCollapse'>
                    Duplicate Check Functionality
                  </a>
                  <div
                    className='collapse show'
                    aria-expanded='true'
                    id='dupCheckCollapse'>
                    <ul>
                      <li>
                        <a href='#viewing-dups'>
                          Viewing Previously Identified Duplicates
                        </a>
                      </li>
                      <li>
                        <a href='#assign-dups'>Assigning Duplicates</a>
                      </li>
                      <li>
                        <a href='#dup-auto-search'>Automated Search</a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className='nav-item'>
            <a
              className={`${styles['pointer']} nav-link`}
              data-bs-toggle='collapse'
              href='#reportTrackingCollapse'
              aria-expanded='true'
              aria-controls='reportTrackingCollapse'>
              Operational Requirements
            </a>
            <div
              className={`collapse ${styles['user-manual-sub-nav']} show`}
              aria-expanded='true'
              id='reportTrackingCollapse'>
              <ul>
                <li>
                  <a href='#report-tracking-faqs'>
                    Operational Requirements FAQs
                  </a>
                </li>
                <li className={`nav-item ${styles['nested-nav-item']}`}>
                  <a
                    className={`${styles['pointer']} ${styles['indicator']} nav-link`}
                    data-bs-toggle='collapse'
                    href='#findingReportCollapse'
                    aria-expanded='true'
                    aria-controls='findingReportCollapse'>
                    Finding a Report
                  </a>
                  <div
                    className='collapse show'
                    aria-expanded='true'
                    id='findingReportCollapse'>
                    <ul>
                      <li>
                        <a href='#find-report-calendar-view'>Calendar View</a>
                      </li>
                      <li>
                        <a href='#find-report-list-view'>List View</a>
                      </li>
                      <li>
                        <a href='#find-report-filters'>Filters</a>
                      </li>
                    </ul>
                  </div>
                </li>
                <li>
                  <a href='#create-report-tracker'>
                    Creating a Operational Requirement
                  </a>
                </li>
                <li>
                  <a href='#update-report-tracker'>
                    Updating a Operational Requirement
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li className='nav-item'>
            <a
              className={`${styles['pointer']} nav-link`}
              data-bs-toggle='collapse'
              href='#clexCollapse'
              aria-expanded='true'
              aria-controls='clexCollapse'>
              CLEX
            </a>
            <div
              className={`collapse ${styles['user-manual-sub-nav']} show`}
              aria-expanded='true'
              id='clexCollapse'>
              <ul>
                <li>
                  <a href='#clex-standards'>Standards</a>
                </li>
                <li>
                  <a href='#clex-homepage'>Homepage</a>
                </li>
                <li>
                  <a href='#clex-search'>Topics</a>
                </li>
                <li>
                  <a href='#clex-about-articles'>Articles</a>
                </li>
                <li>
                  <a href='#clex-add-article'>Creating an Article</a>
                </li>
                <li>
                  <a href='#clex-edit-article'>Editing an Article</a>
                </li>
                <li>
                  <a href='#clex-review-system'>Review system</a>
                </li>
                <li className={`nav-item ${styles['nested-nav-item']}`}>
                  <a
                    className={`${styles['pointer']} ${styles['indicator']} nav-link`}
                    data-bs-toggle='collapse'
                    href='#findingReportCollapse'
                    aria-expanded='true'
                    aria-controls='findingReportCollapse'>
                    Article History
                  </a>
                  <div
                    className='collapse show'
                    aria-expanded='true'
                    id='findingReportCollapse'>
                    <ul>
                      <li>
                        <a href='#article-history-create-topic'>
                          Create a Topic
                        </a>
                      </li>
                      <li>
                        <a href='#article-history-edit-topic'>
                          Editing a Topic
                        </a>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>
            </div>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href='#staff-directory'>
              Staff Directory
            </a>
          </li>
          <li className='nav-item'>
            <a className='nav-link' href='#tech-support'>
              Technical Support
            </a>
          </li>
        </ul>
      </div>

      {/***** 
			*******
			*******
			*******

			USER MANUAL CONTENT BELOW

			*******
			*******
			*******
			*****/}

      <div className={styles['user-manual-content-container']}>
        <div className='container fluid'>
          <div className='row align-items-center'>
            <div className='col-sm-12 col-lg-4'>
              <img
                className={styles['user-manual-iwomm-logo']}
                alt='IWOMM Logo'
                src='/img/Logo/iwomm white logo.png'
              />
            </div>
            <div className='col-sm-12 col-lg-8'>
              <p>
                This document provides information for Central Staff to use the
                IWOMM web applications to access information and manage
                workflow. Much of the information contained in this document can
                also be obtained through the info icons located throughout
                IWOMM.
              </p>
            </div>
          </div>
        </div>
        {/* Logging in Section Start */}
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='logging-in'>
                <h2>Intro to Navigating IWOMM</h2>
                <h3>Logging in</h3>
                <p>To access IWOMM follow these steps:</p>
                <ol>
                  <li>
                    Visit{'   '}
                    <a href='https://iwomm.council.nyc.gov'>
                      iwomm.council.nyc.gov
                    </a>
                  </li>
                  <li>
                    Click on <strong> Activate</strong>
                  </li>
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='activate'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/login-activate-link.png')",
                    }}></div>
                  <li>
                    Enter your <em>username</em>, which will be the beginning of
                    your NYC council email address:
                    <br />
                    <div style={{ textIndent: '50px' }}>
                      Ex: ntriantaphilides@council.nyc.gov would have the
                      username “ntriantaphilides”
                    </div>
                  </li>
                  <li>
                    Click on the <strong> Activate</strong> button
                  </li>
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='activate-page'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/login-activate-button.png')",
                    }}></div>
                  <li>
                    If your username has been entered correctly, you should
                    receive an email with a link to change your password
                  </li>
                </ol>
                <p>
                  Contact the Web Development Team
                  (WebDevelopmentUnit@council.nyc.gov) if you have difficulty
                  setting up an account after following the instructions above.
                </p>
              </section>
            </div>
          </div>
        </div>
        {/* Logging in Section End */}
        {/* Dashboard and Banner Section Start */}
        <div className={`container ${styles['container']}`} id='dash-banner'>
          <div className='row'>
            <div className='col'>
              <h3>Dashboard and Sidebar</h3>
              <p>
                When you successfully log in to IWOMM, the first page you should
                see is your Dashboard. This page highlights your active and/or
                relevant items from each IWOMM application in one convenient
                hub. The Sidebar on the left side of the page allows you to
                navigate through the IWOMM applications. Click a title listed on
                the sidebar to visit that application.
              </p>
              {/* Add infographic of sidebar */}
              <div
                className={styles['user-manual-screen-captures']}
                id='iwomm-main-sidebar'
                style={{
                  backgroundImage:
                    "url('../img/User manual/iwomm-main-sidebar.png')",
                }}></div>
              <p>
                Click the <strong> IWOMM</strong> icon at the top of the Sidebar
                to return to the Dashboard at any time. On any page, click the
                <strong> 'X'</strong> next to the page title (when on the
                homepage, the page title is “Dashboard”) to hide the Sidebar.
              </p>
              {/* Add infographic of the X */}
              <div
                className={styles['user-manual-screen-captures']}
                id='iwomm-sidebar-open'
                style={{
                  backgroundImage:
                    "url('../img/User manual/iwomm-sidebar-open.png')",
                }}></div>
              <p>
                If the Sidebar is hidden, click the three lines next to the page
                title to reveal the Sidebar.
              </p>
              {/* Add infographic of the three lines */}
              <div
                className={styles['user-manual-screen-captures']}
                id='iwomm-sidebar-closed'
                style={{
                  backgroundImage:
                    "url('../img/User manual/iwomm-sidebar-closed.png')",
                }}></div>
            </div>
          </div>
        </div>
        {/* Dashboard and Banner Section End */}
        {/* My Account Section Start */}
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section>
                <h3>My Account</h3>
                <p>
                  Click on the <strong> My Account</strong> dropdown on the top
                  right of the IWOMM page for the following features:
                </p>
              </section>
              <section id='profile'>
                <h4>Profile</h4>
                <p>
                  Visit the Profile page to see the contact information other
                  users may find for you through the Staff Directory, including
                  your email address, phone number and affiliated committees. If
                  any information in your profile needs to be updated, submit a
                  request through <strong> Help &amp; Feedback </strong>
                  (see below). You can also update your account password through
                  this page by clicking <strong> Change Password</strong>.
                </p>
              </section>
              <section id='help-feedback'>
                <h4>Help &amp; Feedback</h4>
                <p>
                  Visit Help &amp; Feedback to submit a request for technical
                  support, report a bug, or provide feedback and feature
                  requests. Complete the prompts and click{' '}
                  <strong> Send</strong>. Someone from the Web Development Team
                  will respond to your request as quickly as possible.
                </p>
              </section>
              <section id='user-manual'>
                <h4>User Manual</h4>
                <p>Clicking User Manual will access this User Manual.</p>
              </section>
              <section id='logout'>
                <h4>Logout</h4>
                <p>Clicking logout will immediately log you out of IWOMM.</p>
              </section>
            </div>
          </div>
        </div>
        {/* Account Section End */}
        {/* My Tasks Section Start */}
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='my-tasks'>
                <h2>My Tasks</h2>
                <p>
                  My Tasks is a shortcut to your action items with access to
                  more information and functionality than the Dashboard. Click
                  the <strong> My Tasks</strong> dropdown menu on the left
                  Sidebar to see an expanded view of your active and/or relevant
                  items from each IWOMM application. You can access more
                  information from the titles in the menu, as follows:
                </p>
                <p>
                  <em>
                    {' '}
                    Note: more information about the functionality of each item
                    is found under the heading of that application later in this
                    User Manual.
                  </em>
                </p>
                <p>
                  Click <strong> My Active LS Requests</strong> to display a
                  table view of all active Legislative Service Requests (“LSRs”)
                  that are assigned to you.
                </p>
                <p>
                  Click <strong> All My LS Requests</strong> to display a table
                  view of all of your assigned LSRs, whether active or inactive.
                </p>
                <p>
                  Click <strong> My Op Reqs</strong> to view the
                  reports/milestones that are designated for you, usually based
                  on the committees that you are affiliated with.
                </p>
                <p>
                  Click <strong> My CLEX Reviews</strong> to access any Articles
                  actively being edited. Articles under review can be accessed,
                  revised and published straight from this page. See the CLEX
                  section of this User Manual for more information.
                </p>
              </section>
            </div>
          </div>
        </div>
        {/* My Tasks Section Ends */}
        {/* LS Requests Section Begins */}
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section>
                <h2>LS Requests</h2>
                <p>
                  Legislative Service Requests (“LSRs”) may be submitted by
                  Council Members, the Public Advocate and Council staff to
                  track the legislative drafting process. Throughout this
                  document, “LS Requests” refers to the IWOMM application or to
                  IWOMM functionality, and “LSR” refers to a request for
                  legislative services.
                </p>
                <p>
                  LS Requests is an application designed to manage the LSR
                  intake process and the evolution of an LSR into a local law,
                  resolution or street co-naming. The application facilitates
                  correspondence between Council Members/the Public Advocate and
                  Central Staff, and displays information about each LSR
                  pertinent to the individual user. The application is designed
                  to be customizable to each user’s unique preferences. The
                  application also uses artificial intelligence to aid in the
                  performance of duplicate checks.
                </p>
                <p>
                  To visit the LS Requests application click on
                  <strong> LS Requests</strong> in the left sidebar or click on
                  an LSR highlighted on your Dashboard page.
                </p>
              </section>

              <section id='ls-request-faqs'>
                <h3>LS Requests FAQs</h3>
                <div className='accordion accordion-flush' id='LsrFAQ'>
                  <div className='accordion-item'>
                    <div
                      className='accordion-header'
                      id='flush-faqLsrHeaderOne'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-faqLsrCollapseOne'
                        aria-expanded='false'
                        aria-controls='flush-faqLsrCollapseOne'>
                        What is the LSR Table?
                      </button>
                    </div>
                    <div
                      id='flush-faqLsrCollapseOne'
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-faqLsrHeaderOne'
                      data-bs-parent='#LsrFAQ'>
                      <div className='accordion-body'>
                        The “LSR Table” refers to the listed view of LSRs as
                        seen on the main LS Requests application page, whether
                        it’s one LSR, the full log, or any other combination of
                        LSRs. Clicking a column heading will sort the LSRs in
                        alphabetical order using the information in that column.
                        Clicking the column heading again will reverse the
                        sorting from Z to A.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item'>
                    <div
                      className='accordion-header'
                      id='flush-faqLsrHeaderTwo'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-faqLsrCollapseTwo'
                        aria-expanded='false'
                        aria-controls='flush-faqLsrCollapseTwo'>
                        How can I view more or less information on my LSR Table?
                      </button>
                    </div>
                    <div
                      id='flush-faqLsrCollapseTwo'
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-faqLsrHeaderTwo'
                      data-bs-parent='#LsrFAQ'>
                      <div className='accordion-body'>
                        Click the <strong> Columns</strong> dropdown below the
                        search bar on the top left of the page to select which
                        columns to view or hide.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item mb-3'>
                    <div
                      className='accordion-header'
                      id='flush-faqLsrHeaderThree'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-faqLsrCollapseThree'
                        aria-expanded='false'
                        aria-controls='flush-faqLsrCollapseThree'>
                        How can I customize my LSR Table?
                      </button>
                    </div>
                    <div
                      id='flush-faqLsrCollapseThree'
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-faqLsrHeaderThree'
                      data-bs-parent='#LsrFAQ'>
                      <div className='accordion-body'>
                        To save your column selections, use the preset options
                        in the
                        <strong> Columns</strong> dropdown as follows:
                        {/* Indent Ordered List */}
                        <ol>
                          <li>
                            Click the <strong> Columns</strong> dropdown on the
                            top left of the page
                          </li>
                          <li>
                            Select one of the presets (i.e., Default, Preset 1,
                            etc.)
                          </li>
                          <li>Select the columns you want to be visible</li>
                          <li>
                            IWOMM will automatically save the columns you have
                            selected for that preset
                          </li>
                          <li>
                            Selecting the preset in the future will apply those
                            column selections
                          </li>
                        </ol>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {/* Underline text below */}
              <p style={{ textDecoration: 'underline' }}>
                Explanation of Columns in the LSR Table:
              </p>
              <div className={styles['column-definitions-container']}>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <strong> 60-Day Date</strong>: The date 60 days after the
                      LSR was last activated.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <strong> Attachment Description</strong>: A description of
                      attachments the CM submitted, if any.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <strong> Bill Matrix</strong>: The bill’s issue matrix
                      prepared by committee staff after a hearing.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <strong> Change of Status Letter</strong>: The letter
                      transmitted by Central Staff to the CM noting more
                      information is needed from the CM in order to proceed, the
                      LSR will take more than 60 days to complete, or Central
                      Staff is recommending withdrawal of the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <strong> CM Attachment</strong>: This where any attachment
                      files submitted by the CM can be accessed.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>CM Notes</b>: Notes for the CM from Central Staff.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Co Prime(s)</b>: The co-prime sponsors of the LSR; this
                      field should not be updated until co-sponsorship
                      agreements have been confirmed by all parties.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Committee</b>: The committee initially designated for
                      the LSR for drafting purposes; this designation may change
                      upon introduction of the legislation.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Committee Report</b>: The Committee Report or Briefing
                      Paper prepared for a hearing on the introduced
                      legislation.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Confidential Comments</b>: Internal notes for Central
                      Staff only.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Contact Email</b>: The email address for the point
                      person in the CM’s office.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Contact Name</b>: The name of the point person in the
                      CM’s office.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Contact Phone</b>: The phone number for the point
                      person in the CM’s office.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Coversheet</b>: The pdf of the signed coversheet
                      transmitted to the CM.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Date Received</b>: The date the LSR was submitted using
                      IWOMM.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Describe The Problem</b>: CM’s description of the
                      problem to be solved by the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Division</b>: The Central Staff division the LSR is
                      assigned to, for drafting purposes; this designation may
                      change upon introduction of the legislation.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Duplicate Checked</b>: Whether Central Staff has
                      searched the log to check for duplicate LSRs and to
                      determine whether this LSR is first-in-time.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Existing Laws</b>: The CM may note any existing laws,
                      regulations or rules related to the LSR here.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>First In Time</b>: Whether this LSR appears to be the
                      earliest-in-time LSR submitted for this proposed
                      legislation.
                      <em>
                        Note: The First In Time determination may change as more
                        information is obtained.
                      </em>
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Hearing Summary</b>: A summary of the bill’s hearing
                      prepared by committee staff.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>In Review</b>: Whether staff have transmitted drafts to
                      supervisors for edits/sign off.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Inspiration For LS Request</b>: Background information
                      provided by the CM submitting the LSR, including whether
                      the LSR was inspired by a news or law review article, a
                      hearing, a constituent complaint or some other source.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Operational Requirement</b>: Whether the LSR will
                      result in the submission of a report or milestone.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Last Actived</b>: The most recent date the LSR was
                      changed from an inactive status to an active status,
                      meaning the LSR is actively being worked on and the 60-day
                      clock is running.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Legal Memo</b>: The confidential legal memo for the
                      LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Legislative Solution</b>: The CM’s description for how
                      legislation will address the problem.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>LSR #</b>: The serial number assigned to the
                      Legislative Service Request by IWOMM.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>LSR Type</b>: Whether the CM requests a local law,
                      resolution or co-naming.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Overlapping LSRs</b>: Other LSRs overlapping with this
                      LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Plain Language Summary</b>: The plain-language summary
                      transmitted to the CM by Central Staff (required for bills
                      only).
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Prepared Legislation</b>: The initially drafted
                      legislation (local law or resolution) transmitted to the
                      CM by Central Staff.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Reintroduction</b>: Whether the LSR is a resubmission
                      of an LSR or introduction from a prior session.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Related Discussions</b>: Any parties the CM notes upon
                      submission for Central Staff to discuss more information
                      about the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Related LSRs</b>: Other LSRs related to, but not
                      necessarily overlapping with, this LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Sponsor</b>: The CM who submitted this LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Staff</b>: The member of Central Staff assigned to this
                      LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Status</b>: The current status of the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Submitted By</b>: The IWOMM user account that submitted
                      the LSR.
                    </div>
                  </li>
                  <li>
                    <div className='alert alert-info' role='alert'>
                      <b>Waived Confidentiality</b>: Whether the CM has waived
                      confidentiality of this LSR for co-sponsorship purposes
                      (Staff can update as appropriate).
                    </div>
                  </li>
                </ul>
              </div>

              <section id='create-ls-request'>
                <h3>Creating a New LS request </h3>
                <p>To submit a new LSR follow these steps:</p>
                <ol>
                  <li>
                    Click the circle with a plus sign, which can be found in the
                    bottom right corner of the page
                  </li>
                  {/* Infographic of the purple circle with a plus sign */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='create-new-lsr'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/create-new-lsr.png')",
                    }}></div>
                  <li>A pop up containing information fields will appear.</li>
                  <li>
                    Complete the fields of information as comprehensively as
                    possible.
                    <em>
                      Note: An information field with an asterisk must be
                      completed in order to proceed
                    </em>
                  </li>
                  <li>
                    When all information fields on a page are complete click
                    <strong> Next </strong>
                    in the top right corner of the pop up.
                  </li>
                  <li>
                    When the last page of information is complete click
                    <strong> Submit </strong>
                    to create a new LSR.
                  </li>
                  <li>
                    Click <strong> Cancel</strong> at any time during this
                    process to stop creating the new LSR.
                    <em>
                      Note: All changes will be lost if you choose to cancel
                      your LSR submission.
                    </em>
                  </li>
                </ol>
              </section>

              <section id='update-ls-request'>
                <h3>Updating an LS request </h3>
                <p>To update an existing LSR follow these steps:</p>
                <ol>
                  <li>
                    Click on the <em>three dots</em> on the far right end of the
                    LSR as it appears in the LSR Table.
                  </li>
                  {/* Infographic of the three dots */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='three-dots-lsr-table'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/three-dots-lsr-table.png')",
                    }}></div>
                  <li>
                    Click on <strong> Update LS Request</strong>.
                  </li>
                  <li>
                    Update the relevant information fields by clicking on a
                    field and inputting the new information.
                  </li>
                  <li>
                    Click <strong> Next</strong> on the top right corner to find
                    additional information fields.
                  </li>
                  <li>
                    Click <strong> Save</strong> to preserve your updates, or
                    <strong> Cancel </strong>
                    to revert the LS request back to the last saved information.
                  </li>
                </ol>
              </section>

              <section id='sub-ls'>
                <h3>Sub LS Request</h3>
                <p>
                  Creating a sub LSR is an option only available to Central
                  Staff to assist in the drafting process. You may create a sub
                  LSR when the initial LSR will attach to multiple bill drafts
                  to track each draft and to preserve sponsorship rights.
                  However, this feature should be used only with supervisor
                  approval.
                </p>
                <p>To create a Sub LSR:</p>
                <ol>
                  <li>
                    Click on the <em>three dots</em> on the far right end of the
                    LSR as it appears in the LSR Table
                  </li>
                  <li>
                    Click on <b>Create Sub LS</b>.
                  </li>
                  <li>
                    The pop up containing information fields to create a new LSR
                    will appear.
                  </li>
                  <li>
                    Complete the information fields as comprehensively as
                    possible. Note: An information field with an asterisk must
                    be completed in order to proceed.
                  </li>
                  <li>
                    When all information fields on a page are complete click
                    <strong> Next</strong> in the top right corner of the pop
                    up.
                  </li>
                  <li>
                    When the last page of information is complete click
                    <strong> Submit</strong> to create a new sub LSR.
                  </li>
                  <li>
                    Click <strong> Cancel</strong> at any time to stop creating
                    the new sub LSR. Note: All changes will be lost if you
                    choose to cancel creating your sub LSR.
                  </li>
                </ol>
              </section>

              <section id='ls-request-filters'>
                <h3>Searching LS Requests</h3>
                <p>There are two ways to search for an LSR:</p>
                <ol>
                  <li>Using filters, and</li>
                  <li>Using the search bar</li>
                </ol>
                <h4>Filters</h4>
                <p>
                  Applying filters to one or more columns will narrow the LSRs
                  listed in the LSR Table to LSRs that match specific criteria.
                </p>
                <p>To search using filters:</p>
                <ol>
                  <li>
                    Click on the <strong> Filter</strong> dropdown on the top
                    left side of the page (next to <strong> Columns</strong>)
                  </li>
                  <li>Enter information into any of the fields</li>
                  <li>
                    Click <strong> APPLY FILTER(S)</strong>
                  </li>
                </ol>
              </section>

              <section id='ls-request-search-bar'>
                <h4>Search bar</h4>
                <p>
                  The search bar located above the LSR Table can be used just as
                  any other conventional search bar, by entering a word or
                  phrase to be searched and clicking the magnifying glass or
                  hitting the return key. Search for any terms (excluding the
                  symbols listed below) to return LSRs that contain those terms
                  in any location within the LSR.
                </p>
                <p>
                  In addition, the search bar can operate on Boolean logic,
                  using guidance as described below:
                </p>
              </section>
              <section>
                <div className={`container ${styles['sub-list-container']}`}>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        AND: <code>&amp;</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Using the <code>&amp;</code> symbol to show results that
                        are related to both terms. For example, typing
                        <em> transportation &amp; bridges </em> will show
                        results that contain both the words{' '}
                        <em>transportation</em> and
                        <em> bridges </em>.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        AND/OR: <code>|</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Using the <code> |</code> symbol to show results related
                        to both terms or each term individually. For example,
                        <em>
                          {' '}
                          transportation <code> |</code> bridges
                        </em>
                        will show results that contain <em> transportation</em>
                        alone, <em> bridges</em> alone, or both
                        <em> transportation</em> and <em>bridges</em>.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        NOT: <code>!</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Using the <code> !</code> symbol will exclude a word,
                        phrase, or parenthetical from the search. For example,
                        <em> !transportation</em> will show results that do not
                        contain any reference to <em> transportation</em>. Using
                        the <strong>&amp;</strong> symbol and the
                        <strong>!</strong> symbol will show results that contain
                        some terms but not others. For example,
                        <em> transportation &amp; !bridges</em> will show
                        results that contain
                        <em> transportation</em> but not <em> bridges</em>.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        Parentheses: <code>( )</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Putting a search term between parentheses ensures that
                        it is prioritized before any commands outside of the
                        parentheticals. Use them as you would use parentheses in
                        a mathematical expression. For example,
                        <em>tolls &amp; (bridges | tunnels)</em> as a search
                        query will show LSRs that contain the terms
                        <em>bridges</em> AND/OR <em>tunnels</em> as well as the
                        word
                        <em> tolls</em>. In other words, <em>tolls</em> AND
                        <em>bridges</em> as well as <em>tolls</em> AND
                        <em> tunnels</em>.
                      </p>
                    </div>
                  </div>
                  <div className='row'>
                    <div
                      className={`col-sm-12 col-md-4 ${styles['sub-section-heading-column']}`}>
                      <h4>
                        Quotations: <code>" "</code>
                      </h4>
                    </div>
                    <div className='col-sm-12 col-md-8'>
                      <p>
                        Putting a search term between quotation marks ensures
                        the results contain the term exactly as entered, spacing
                        included. For example, searching for
                        <em>“for hire vehicle”</em> within quotes will only
                        return LSRs in which the exact term
                        <em>“for hire vehicle”</em> is found, in that order.
                        Searching for <em>for hire vehicle</em> without
                        quotations will return LSRs that contain the words
                        <em> for</em>, <em>hire</em>, or <em> vehicle</em>,
                        regardless of these words’ order or location within the
                        LSR.
                      </p>
                    </div>
                  </div>
                </div>
              </section>

              <section id='export-ls-request'>
                <h3>Export to Excel</h3>
                <p>To export the LSR Table to an Excel spreadsheet:</p>
                <ol>
                  <li>
                    Click the <strong> Export</strong> dropdown on the top right
                    side of the page
                  </li>
                  <li>Choose one of the following:</li>
                  <ol type='a'>
                    <li>
                      Click <strong> Full Log</strong> to email a spreadsheet
                      containing the full log to the email associated with your
                      user account. Note: Due to the size of the list, this may
                      take a few minutes
                    </li>
                    <li>
                      Click <strong> Current View</strong> to download the LSRs
                      that are the result of the currently applied search
                      filters. Note: This download appears as a pop-up, not by
                      email
                    </li>
                  </ol>
                </ol>
                <p>
                  Whether you export the full log or the current view, all
                  columns in the LSR Table will be downloaded.
                </p>
              </section>

              <section id='viewing-dups'>
                <h3>Duplicate Check Functionality</h3>
                <p>
                  IWOMM’s duplicate checking features are available only to
                  Central Staff to assist in the drafting process. This section
                  covers how to link duplicate LSRs on IWOMM, how to view the
                  duplicates linked to a particular LSR, and how to perform
                  IWOMM’s Automated Search to find duplicate LSRs. (Instructions
                  for performing a full, manual duplicate search are beyond the
                  scope of this user manual.)
                </p>
                <h4>Viewing Previously Identified Duplicates</h4>
                <p>To view previously identified duplicates: </p>
                <ol>
                  <li>
                    Click on the <em>three dots</em> on the far right end of the
                    LSR as it appears in the LSR Table
                  </li>
                  <li>
                    Click <strong>Run Duplicate Checker</strong>
                  </li>
                  <li>
                    Any previously identified duplicates will appear in a listed
                    format. You may click on an LSR to enlarge it and view all
                    details. If no LSRs appear, no duplicates have been
                    identified
                  </li>
                </ol>
              </section>

              <section id='assign-dups'>
                <h4>Assigning Duplicates</h4>
                <p>You can link overlapping and related LSRs as follows:</p>
                <ol>
                  <li>
                    Click on the <em> three dots</em> on the far right end of
                    the LSR as it appears in the LSR Table
                  </li>
                  <li>
                    Click <strong> Run Duplicate Checker</strong>
                  </li>
                  <li>
                    Click <strong> Manual Assignment</strong>
                  </li>
                  <li>
                    Enter the LS number of the LSR that is a duplicate and click
                    the magnifying glass
                  </li>
                  <li>
                    Click <strong> Overlapping LSR</strong>,
                    <strong> Related LSR</strong> or
                    <strong> Not a Duplicate</strong> as appropriate
                  </li>
                  <li>
                    Before exiting the LSR, click
                    <strong> Review All Duplicates</strong> and either use the
                    <strong> Submit Duplicates</strong> dropdown arrow to click
                    <strong> Save Progress</strong>, or click
                    <strong> Submit Duplicates</strong> to update the LSR
                  </li>
                </ol>
              </section>

              <section id='dup-auto-search'>
                <h4>Automated Search</h4>
                <p>
                  IWOMM can perform an “Automated Search” to identify possible
                  duplicates of an LSR. Although this feature can help make
                  duplicate checks easier,
                  <em>
                    {'  '} it is not a substitute for a user’s manual duplicate
                    search
                  </em>
                  . IWOMM’s Automated Search will return false positives and
                  will miss duplicates due to variations in wording and other
                  human nuances. Automated Search returns must be confirmed as
                  described below, and
                  <em>
                    {'  '} no duplicate search is complete without a manual
                    search
                  </em>
                  .
                </p>
                <p>To use the Automated Search feature:</p>
                <ol>
                  <li>
                    Click on the <em> three dots</em> on the far right end of
                    the LSR as it appears in the LSR Table
                  </li>
                  <li>
                    Click <strong> Run Duplicate Checker</strong>
                  </li>
                  <li>
                    Click <strong> Automated Search</strong>
                  </li>
                  <li>
                    A list of LSRs will appear that are tagged as
                    <strong> Unassigned</strong> under the heading
                    <strong> Duplicate Type</strong>
                  </li>
                  <li>
                    Click on an <strong> Unassigned</strong> LSR to enlarge it
                    and view all details
                  </li>
                  <li>
                    Review the LSR details and click
                    <strong> Overlap Duplicate</strong>,
                    <strong> Related LSR</strong> or
                    <strong> Not a Duplicate</strong> as appropriate
                  </li>
                  <li>
                    Before exiting the LSR, click
                    <strong> Review All Duplicates</strong> and either use the
                    <strong> Submit Duplicates</strong> dropdown arrow to click
                    <strong> Save Progress</strong>, or click
                    <strong> Submit Duplicates</strong> to update the LSR
                  </li>
                </ol>
              </section>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='about-report-tracker' className='mb-3'>
                <h2>Operational Requirements</h2>
                <p>
                  To visit the Operational Requirements application click on
                  <strong> Operational Requirements</strong> in the left sidebar
                  or click on a report highlighted on your Dashboard page.
                </p>
                <p>
                  Operational Requirements are reports and milestones, usually
                  required by local law. In practice, it is often the mayor and
                  mayoral agencies that must complete such reports/milestones.
                  Once a report is submitted, IWOMM will automatically request
                  the report from the agency required to submit it. IWOMM also
                  sends reminders to specified users (1) when a report's due
                  date is approaching, (2) when a report has been submitted, and
                  (3) when a report is past due. Milestones are to be monitored
                  internally, with no automated communication with any agencies.
                </p>
                <p>
                  This section covers how to find and track reports, which are
                  stored and can be viewed through this application.
                </p>
              </section>

              <section className='mb-4' id='report-tracking-faqs'>
                <h3>Operational Requirements FAQs</h3>
                <div
                  className='accordion accordion-flush'
                  id='ReportTrackerFAQ'>
                  <div className='accordion-item'>
                    <div
                      className='accordion-header'
                      id='flush-faqReportTrackerHeaderOne'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-faqReportTrackerCollapseOne'
                        aria-expanded='false'
                        aria-controls='flush-faqReportTrackerCollapseOne'>
                        What is an Operational Requirement?
                      </button>
                    </div>
                    <div
                      id='flush-faqReportTrackerCollapseOne'
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-faqReportTrackerHeaderOne'
                      data-bs-parent='#ReportTrackerFAQ'>
                      <div className='accordion-body'>
                        Once a report/milestone is entered into the Operational
                        Requirements application, the Tracker monitors the
                        report by sending email notifications when a new report
                        is due and stores the report upon submission.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item'>
                    <div
                      className='accordion-header'
                      id='flush-faqReportTrackerHeaderTwo'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-faqReportTrackerCollapseTwo'
                        aria-expanded='false'
                        aria-controls='flush-faqReportTrackerCollapseTwo'>
                        Who receives notifications about a report?
                      </button>
                    </div>
                    <div
                      id='flush-faqReportTrackerCollapseTwo'
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-faqReportTrackerHeaderTwo'
                      data-bs-parent='#ReportTrackerFAQ'>
                      <div className='accordion-body'>
                        Any Council staff listed as an
                        <strong> Assigned Contact</strong> in the Report Tracker
                        will receive email notifications about the status of the
                        report. Additionally, the
                        <strong> Agency Contact</strong> listed in the Report
                        Tracker will receive email notifications about the
                        agency’s obligation to submit the report.
                      </div>
                    </div>
                  </div>
                  <div className='accordion-item'>
                    <div
                      className='accordion-header'
                      id='flush-faqReportTrackerHeaderThree'>
                      <button
                        className='accordion-button collapsed'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#flush-faqReportTrackerCollapseThree'
                        aria-expanded='false'
                        aria-controls='flush-faqReportTrackerCollapseThree'>
                        Do Agency Contacts have access to IWOMM?
                      </button>
                    </div>
                    <div
                      id='flush-faqReportTrackerCollapseThree'
                      className='accordion-collapse collapse'
                      aria-labelledby='flush-faqReportTrackerHeaderThree'
                      data-bs-parent='#ReportTrackerFAQ'>
                      <div className='accordion-body'>
                        The Agency Contact will have an IWOMM user account with
                        limited access to the Operational Requirements
                        application to submit reports to the council.
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section id='report-tracker-details'>
                <h3>Finding a Report</h3>
                <p>There are two ways to find a report:</p>
                <ol>
                  <li>Using Calendar View, and</li>
                  <li>Using List View</li>
                </ol>
              </section>

              <section id='find-report-calendar-view'>
                <h6>Calendar View</h6>
                <p>
                  The default view is Calendar View. This view shows all reports
                  due on a given date.
                </p>
                <p>To view a report:</p>
                <ol>
                  <li>
                    Click the date the report is due. Note: You can click the
                    date on the top left corner of the calendar to jump to
                    another month and year, or toggle between the preceding and
                    subsequent months by clicking the arrows. Return to today’s
                    calendar by clicking <strong> Today</strong> on the top
                    right corner of the calendar.
                  </li>
                  <li>
                    Click the report to open the <strong> Report Detail</strong>
                  </li>
                  <li>
                    Scroll down to Report and click the file to download the
                    report Note: If there is no file shown under
                    <strong> Report</strong>, the report has not been uploaded
                    to the system
                  </li>
                  {/* Infographic of a Report */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='download-report-detail'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/download-report-detail.png')",
                    }}></div>
                </ol>
              </section>
              <section id='find-report-list-view'>
                <h6>List View</h6>
                <p>
                  Click the tab that says <strong> List View</strong> on the
                  right side of the page to view a list of reports with
                  additional searching and sorting features.
                </p>
                {/* Infographic of List View */}
                <div
                  className={styles['user-manual-screen-captures']}
                  id='list-view-tab'
                  style={{
                    backgroundImage:
                      "url('../img/User manual/list-view-tab.png')",
                  }}></div>
                <p>To find reports in this view:</p>
                <ol>
                  <li>
                    Enter terms into the search bar that match the report’s
                    details (or click the
                    <strong> Search by</strong> dropdown to the left of the
                    search bar to narrow your search field to <em> Agency</em>,
                    <em> Division</em>, <em> Committee</em> and/or
                    <em> Report Title</em>) and then click the magnifying glass
                    or hit the return key
                  </li>
                  <li>
                    Sort the search results by clicking the
                    <strong> Sort by</strong> dropdown below the search bar
                  </li>
                  <li>
                    Click a report to open the <strong> Report Detail</strong>
                  </li>
                  <li>
                    Scroll down to <strong> Report</strong> and click the file
                    to download the report. Note: If there is no file shown
                    under
                    <strong> Report</strong>, the report has not been uploaded
                    to the system
                  </li>
                </ol>
              </section>

              <section id='find-report-filters'>
                <h6>Filters</h6>
                <p>
                  In either Calendar View or List View, click the
                  <strong> Filters</strong> dropdown to view reports by status.
                  Select one or more of the following options:
                </p>
                <ul>
                  <li>
                    <strong> Reports Completed</strong> - The report has been
                    submitted and no further reports are due in the future
                  </li>
                  <li>
                    <strong> Reports Past Due</strong> - The report is past due
                  </li>
                  <li>
                    <strong> Reports Received</strong> - A report due in the
                    future has already been received
                  </li>
                  <li>
                    <strong> Reports Upcoming</strong> - A report that has yet
                    to be submitted is expected in the future
                  </li>
                </ul>
              </section>

              <section id='create-report-tracker'>
                <h3>Creating a Operational Requirement</h3>
                <p>
                  IWOMM is already populated with Operational Requirements for
                  known reports, and milestones. Before creating a new
                  Operational Requirement, search for the Operational
                  Requirement as described in the section of this manual titled
                  Finding a Report to ensure that it does not already exist. To
                  update an existing Operational Requirement, inclusive of
                  adding yourself as an <strong> Assigned Contact</strong>, view
                  the section of this manual titled
                  <strong> Updating an Operational Requirement.</strong>
                </p>
                <p>
                  After you have confirmed a Operational Requirement does not
                  exist, follow these steps to input a new report to be tracked:
                </p>
                <ol>
                  <li>
                    Click <strong> Create Operational Requirement</strong>
                  </li>
                  <li>
                    Fill in the information requested
                    <br />
                    Note: The “Unit” and “Period” fields assist with tracking
                    recurring reports. For example, quarterly reports will be
                    entered as:
                    <br />
                    Unit: Month
                    <br />
                    Period: 3<br />
                    If a report is only required once or doesn’t have a regular
                    due date, select Unit: “Once” or “As needed” as appropriate
                    (these options don’t require an associated “Period”)
                  </li>
                  <li>
                    When all information fields on a page are complete, click
                    <strong> Next</strong> on the top right corner of the pop up
                    <br /> Note: All information fields with an asterisk must be
                    completed in order to proceed
                  </li>
                  <li>
                    When the last page of information is complete, click
                    <strong> Submit</strong> to create a new Operational
                    Requirement
                  </li>
                  <li>
                    Click <strong> Cancel</strong> at any time to stop creating
                    the new Operational Requirement <br />
                    Note: All changes will be lost if you cancel creating a new
                    Operational Requirement
                  </li>
                </ol>
              </section>

              <section id='update-report-tracker'>
                <h3>Updating a Operational Requirement</h3>
                <p>To update details in a Operational Requirement:</p>
                <ol>
                  <li>
                    Search for the report as described in the section of this
                    manual titled <strong> Finding a Report</strong>
                  </li>
                  <li>Click on the report</li>
                  <li>
                    Click <strong> Update Op Req</strong>
                  </li>
                  <li>Update the relevant information</li>
                  <li>
                    Click <strong> Next</strong> on the top right corner to find
                    additional information fields
                  </li>
                  <li>
                    Click <strong> Save</strong> to preserve your updates, or
                  </li>
                  <li>
                    Click <strong> Cancel</strong> to revert the Operational
                    Requirement back to the last saved information and
                    <strong> X (exit)</strong> the Operational Requirement
                  </li>
                </ol>
              </section>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='about-clex'>
                <h2>CLEX</h2>
                <h3>Introduction</h3>
                <p>
                  The Council Legal Exchange (CLEX, pronounced “see-lex”), is a
                  crowd-sourced, confidential repository of legal analysis that
                  relates to the New York City Council’s legislative, finance
                  and land use authority. The objective of CLEX is to provide a
                  collection of articles that are openly editable and may be
                  generated, maintained, and revised in real time in response to
                  ongoing developments. While CLEX should serve as a helpful
                  tool when familiarizing with the Council’s legal authority in
                  a particular area, it is not a substitute for independent
                  research or confirmation on individualized issues.
                </p>
                <p>
                  For privilege and confidentiality purposes, CLEX may only be
                  accessed by select divisions of Central Staff with relevant
                  drafting authority, and shared information may only be updated
                  by attorneys in those divisions. Attorneys in the Legislative,
                  Finance and Land Use Divisions, as well as the Office of the
                  General Counsel, can add new articles and revise existing
                  articles as the need arises.
                </p>
                <p>
                  CLEX articles can be navigated using three primary methods:
                  (1) Articles are categorized hierarchically by subject matter,
                  (2) Articles can be linked to each other through a system of
                  user-generated tags, and (3) Users can search all Articles for
                  keywords via a search bar.
                </p>
                <p>These features and others are described more fully below.</p>
              </section>

              <section id='clex-standards'>
                <h3>Standards</h3>
                <h6>Standards for Legal Research with CLEX</h6>
                <p>
                  CLEX should be viewed as a starting point for your legal
                  research and should not be considered a substitute for
                  independent research. Additionally, although efforts will be
                  made to ensure the accuracy of the information contained in
                  CLEX, be sure to verify CLEX content before using it in a
                  legal analysis.
                </p>
                <h6>Standards for Content Added to CLEX</h6>
                <p>CLEX content should be:</p>
                <ol>
                  <li>
                    Relevant to the work of the Council, particularly with
                    respect to the Council’s legal authority related to
                    legislation, finance and land use.
                  </li>
                  <li>
                    Accurate and verifiable, as demonstrated with links to
                    reliable sources.
                  </li>
                  <li>
                    Drafted by attorneys or by staff working at the direction
                    and under the supervision of an attorney.
                  </li>
                  <li>Professionally and neutrally presented.</li>
                </ol>
                <h6>Review by Supervisors</h6>
                <p>
                  Newly added material is flagged to stand out until it has been
                  reviewed by a supervisor, but the primary responsibility for
                  ensuring that content is accurate rests with the drafter.
                </p>
              </section>

              <section id='clex-homepage'>
                <h3>Homepage</h3>
                <p>
                  The CLEX homepage displays the Council’s disclaimer message
                  and use policy, which users should review carefully. All
                  information on CLEX is privileged and confidential and may not
                  be shared outside the Legislative, Finance and Land Use
                  Divisions, or the Office of the General Counsel. Any review,
                  dissemination, distribution, or copying of the information
                  contained on this website that is outside the scope of the use
                  policy is prohibited.
                </p>
                <p>
                  Click <strong> Accept</strong> to view the CLEX homepage. From
                  this homepage, you can browse Topics and Articles in a few
                  different ways. Clicking a letter of the alphabet lets you see
                  the Topics that start with that letter (click “
                  <strong> F</strong>” for “First Amendment,” “Finance,” etc.).
                  Below that are Articles that were most recently edited.
                </p>
                <p>
                  The search bar allows you to choose the scope of a search from
                  within the dropdown menu to the left of the search bar. The
                  user can choose various search filters such as Topics, Article
                  Titles, Article Descriptions, Article Content, Committees, and
                  Tags.
                </p>
                <p>
                  The full path to a Topic or Article will be displayed at the
                  top of the webpage to assist you in navigating CLEX.
                </p>
              </section>

              <section id='clex-search'>
                <h3>Topics</h3>
                <p>
                  Topics (which can be thought of as folders) may contain other
                  Topics or Articles within them. A Main Topic may contain other
                  Topics, just as a folder may contain other folders. (For
                  example, the Main Topic “Constitutional Law” contains other
                  Topics such as “First Amendment,” “Second Amendment,” and so
                  on.) The Main Topics are the Topics that exist at the top of
                  the CLEX hierarchy, and all other Topics and Articles live
                  within these folders.
                </p>
              </section>

              <section id='clex-about-articles'>
                <h3>Articles</h3>
                <p>
                  Articles are the content of CLEX. Articles have citations that
                  include authors, editors and reviewers. Articles may only be
                  created and edited by attorneys or by others acting at the
                  direction and under the supervision of an attorney. The
                  content of an Article must be primarily legal in nature.
                </p>
                <p>
                  Below are explanations of the available fields when creating a
                  CLEX Article.
                </p>
                <div className='row'>
                  <div
                    className={`col-4 ${styles['sub-section-heading-column']}`}>
                    <h6>Topics</h6>
                  </div>
                  <div className='col-8'>
                    <p>
                      Topics are the folders in which this Article will live.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div
                    className={`col-4 ${styles['sub-section-heading-column']}`}>
                    <h6>Title</h6>
                  </div>
                  <div className='col-8'>
                    <p>
                      The title is a brief description of the Article’s content
                      and should be the same as the Memo Regarding field.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div
                    className={`col-4 ${styles['sub-section-heading-column']}`}>
                    <h6>Committees</h6>
                  </div>
                  <div className='col-8'>
                    <p>
                      Council Committees that may have oversight over this
                      subject area.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div
                    className={`col-4 ${styles['sub-section-heading-column']}`}>
                    <h6>Tags</h6>
                  </div>
                  <div className='col-8'>
                    <p>
                      Tags can be used to group Articles together outside of the
                      Topics hierarchy. An Article can have more than one tag
                      connecting it to a keyword or another Article.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div
                    className={`col-4 ${styles['sub-section-heading-column']}`}>
                    <h6>Introduction</h6>
                  </div>
                  <div className='col-8'>
                    <p>
                      The Introduction is a summary description of the Article
                      that will be seen in previews.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div
                    className={`col-4 ${styles['sub-section-heading-column']}`}>
                    <h6>Sections</h6>
                  </div>
                  <div className='col-8'>
                    <p>
                      Sections contain the content of an Article. Sections are
                      used to generate the Article’s table of contents. By
                      default, an Article contains pre-loaded Sections titled
                      Analysis, Key Cases, Key Statutes/Regulations, Key
                      Legislative History, Related Legal Topics and Additional
                      Background, but these can be altered if needed. These
                      sections can be deleted and their positions changed to
                      accommodate the best structure for the individual Article.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div
                    className={`col-4 ${styles['sub-section-heading-column']}`}>
                    <h6>Subsections</h6>
                  </div>
                  <div className='col-8'>
                    <p>
                      You can create a subsection within each section and can go
                      down four levels of specificity. The levels are titled:
                      Section &gt; Subsection &gt; Division &gt; Subdivision.
                      All units of text must have a parent unit with content.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div
                    className={`col-4 ${styles['sub-section-heading-column']}`}>
                    <h6>Links</h6>
                  </div>
                  <div className='col-8'>
                    <p>
                      You can link text by highlighting the text to be linked
                      and then clicking the link icon from the text editor menu
                      and typing in the link URL.
                    </p>
                  </div>
                </div>
                <div className='row'>
                  <div
                    className={`col-4 ${styles['sub-section-heading-column']}`}>
                    <h6>Footnotes</h6>
                  </div>
                  <div className='col-8'>
                    <p>
                      You can add footnotes by clicking in the location where
                      the footnote will appear, then clicking the footnote
                      button and typing the footnote content. It will be added
                      to the bottom of the section. The number and ordering of
                      the footnotes is handled automatically and will update
                      according to other footnotes in the section that are added
                      or deleted.
                    </p>
                  </div>
                </div>
              </section>
              <section id='clex-add-article'>
                <h3>Creating an Article</h3>
                <p>To create a new Article:</p>
                <ol>
                  <li>
                    From the CLEX homepage, click on the
                    <strong> plus sign</strong> in the circle with a plus sign
                    on the bottom-right of the page. From any other page, click
                    <strong> Create New Article</strong> on the sidebar.
                  </li>
                  {/* Infographic of plus sign */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='clex-create-article'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/clex-create-article.png')",
                    }}></div>
                  <li>
                    Choose a Topic in which to place the new Article. You can
                    place an Article in more than one Topic.
                  </li>
                  <li>
                    Add a coherent and comprehensive <strong> Title</strong>.
                  </li>
                  <li>
                    Select the committee(s) that may be associated with the
                    Article. If no committee options are relevant, select
                    <strong> Unassigned</strong>.
                  </li>
                  <li>
                    Choose or create <strong> Tags</strong> that will link the
                    Article to others with these keywords you have selected.
                  </li>
                  <li>
                    Add a succinct <strong> Introduction</strong>.
                  </li>
                  <li>
                    Complete the <strong> Sections</strong> of the Article.
                  </li>
                  <li>
                    Upload for review by selecting <strong> Submit</strong>.
                  </li>
                </ol>
              </section>

              <section id='clex-edit-article'>
                <h3>Editing an Article</h3>
                <p>
                  The accuracy of CLEX depends on edits made by its users. If
                  you encounter a dubious assertion or a point of law you think
                  may be out of date, verify and correct the issue. Your
                  colleagues will be grateful, and the Council’s ability to give
                  sound legal advice will be enhanced.
                </p>
                <p>To edit a CLEX Article:</p>
                <ol>
                  <li>Visit the Article that needs editing.</li>
                  <li>
                    Once in the Article, click on the <strong> Edits </strong>
                    dropdown menu next to the Article's title.
                  </li>
                  <li>
                    Click <strong> Edit Article</strong>. Allow a second for the
                    Topic title to populate the selection.
                    <br />
                    {/* Indent */}
                    <p style={{ marginLeft: '20px', marginBottom: '0px' }}>
                      If the Article is locked, that means there are edits to
                      this Article waiting for approval. CLEX Articles are
                      automatically locked when they are being edited. Locking
                      the Article prevents two individuals from editing the same
                      content simultaneously in order to avoid confusion. The
                      lock is lifted when a supervisor approves the edits.
                    </p>
                  </li>
                  <li>
                    Click on <strong> Edit Article</strong> once again to
                    display all editable fields.
                  </li>
                  <li>
                    Navigate the Article by clicking on the banners and dropdown
                    menus to view all of the Article’s content and information.
                    For example, click on “Section 1: Analysis” in the banner to
                    display the text included in that field.
                  </li>
                  <li>
                    After all edits are complete, select
                    <strong> Preview</strong> at the bottom of the page to view
                    a finalized view of your changes before edits are submitted
                    for review.
                  </li>
                </ol>
                <p>
                  Comments can be added at the bottom of the page as a way to
                  communicate with readers and editors of each Article.
                </p>
              </section>

              <section id='clex-review-system'>
                <h3>Review System</h3>
                <p>
                  A change to an Article in CLEX is not immediately saved upon
                  submission. After submitting a change, you are redirected to a
                  review page where all of your changes (additions and removals)
                  appear on the left as highlighted text. Any text that has been
                  removed is represented with a strikethrough. You can make
                  further changes to the Article in the form on the right side.
                  To save those edits, click on <strong> Save Draft</strong> at
                  the bottom of the form.
                </p>
                <p>
                  The reviewer and the drafter can send messages to each other
                  by putting text in the message input field above the
                  <strong> Save Draft</strong> button and clicking the Save
                  Draft button to send. At the bottom you will see a
                  <strong> Discard Changes</strong> button that deletes the
                  change submissions that have been made if you are displeased
                  with them. If you are a supervisor, you will see everything a
                  drafter sees and will also have an
                  <strong> Publish Review</strong> button to approve all of the
                  changes in the Article.
                </p>
                <p>
                  Only one person can make edits to an Article at a time. If an
                  Article is being edited, the Article will temporarily be
                  locked for further editing but can still be viewed by other
                  users. The same goes for Articles awaiting review. No
                  additional reviews can be made while an Article is in review.
                </p>
              </section>

              <section id='article-history-create-topic'>
                <h3>Article History</h3>
                <p>
                  Each Article includes a link to a page called Article History.
                  On this page you can see all of the changes (additions and
                  removals) that have been made to an Article as highlighted
                  text. Any text that has been removed is represented with a
                  strikethrough. Click on a revision in the scroll box to see
                  the difference between the current version of the Article and
                  previous versions. If you want to change an Article back to a
                  previous version, you can click Revert and it will change
                  back, while also creating a new instance in the history to
                  save the proposed edits. All changes are logged under the
                  username of the person who made the change.
                </p>
                <h6>Create a Topic</h6>
                <p>
                  When creating a Topic, be mindful of where it is created and
                  try to create it within the most logical Main Topic. Topics
                  can be linked to multiple parent Topics to facilitate
                  navigation through CLEX.
                </p>
                <p>To create a new Topic from the CLEX homepage:</p>
                <ol>
                  <li>
                    Click on <strong> Create New Topic</strong> at the top-right
                    of CLEX homepage. Alternatively, you can also create a new
                    Topic by clicking on the plus icon on the sidebar within a
                    Topic or Article.
                  </li>
                  <li>
                    Select which Main Topic best fits the
                    <strong> Parent Topic</strong> field.
                  </li>
                  <li>Add the title of the newly created Topic.</li>
                  <li>
                    Lastly, link any other related Topics by selecting from the
                    dropdown list.
                  </li>
                </ol>
              </section>

              <section id='article-history-edit-topic'>
                <h6>Edit a Topic</h6>
                <p>To edit a Topic’s title or its linked Topics:</p>
                <ol>
                  <li>
                    Visit the Topic that requires editing. It can be found using
                    the search function located on the CLEX homepage or by
                    selecting a Topic using the Topic Index, also found on the
                    homepage. Alternatively, you can also create a new Topic by
                    clicking on the <em>edit icon</em> on the sidebar within a
                    Topic or Article.
                  </li>
                  <li>
                    From the Topic page, click on
                    <strong> Edit this Topic</strong> found on the top-right.
                  </li>
                  <li>
                    Confirm the Topic choice by clicking on
                    <strong> Edit Topic</strong>, and allow a second for the
                    Topic title to populate the selection.
                  </li>
                </ol>
              </section>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='staff-directory'>
                <h2>Staff Directory</h2>
                <p>
                  Visit the Staff Directory to search and view Council Members’
                  and staff members’ contact information. Use the search bar to
                  find an individual by name. Clicking on a name will show that
                  person’s contact information and affiliated committees.
                </p>
              </section>
            </div>
          </div>
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col'>
              <section id='tech-support'>
                <h2>Technical Support</h2>
                <ol>
                  <li>
                    To request support from the Web Development team, including
                    to submit notice of a bug in the system, click on the
                    <strong> My Account</strong> dropdown on the top right of
                    the page and click <strong> Help &amp; Feedback</strong> to
                    submit a request for technical support. Complete the
                    prompts, click <strong> Send</strong>, and someone from the
                    Web Development Team will respond to your request.
                  </li>
                  {/* Infographic of My Account button in dropdown */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='my-account-dropdown'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/my-account-dropdown.png')",
                    }}></div>
                  {/* Infographic of Help and Feedback button in dropdown */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='help-feedback-dropdown'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/help-feedback.png')",
                    }}></div>
                  <li>
                    Click on the <strong> My Account</strong> dropdown on the
                    top right of the page and click{' '}
                    <strong> User Manual</strong> to access this User Manual.
                  </li>
                  {/* Infographic of User manual button in dropdown */}
                  <div
                    className={styles['user-manual-screen-captures']}
                    id='user-manual-dropdown'
                    style={{
                      backgroundImage:
                        "url('../img/User manual/user-manual.png')",
                    }}></div>
                </ol>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CSUserManual;
