import React, { useState, useEffect, useRef } from 'react';
import styles from '../../styles/ls-request/LsRequestsList.module.scss';
import { connect } from 'react-redux';
import {
  activateLsRequest,
  clickedLsRequest,
  openOrCloseLsRequestServicePopup,
  storeHighlightedLsRequests,
  updateTargetLsRequest,
} from '../../actions/lsRequestAction';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import { formatDateTime, isConfidential } from '../../utils/helper';
import File from '../library/File';
import parse from 'html-react-parser';
import TableHeader from '../library/TableHeader';
import ToolTip from '../library/ToolTip';
import DuplicatesTooltip from './DuplicatesTooltip';
import {
  activeStatuses,
  adminRoles,
  fourthTierRoles,
  rolesUnderCouncilMembers,
  superAdminRoles,
} from '../../services/constants';
import { ThreeDotsVertical } from '../../services/SvgLibrary';
import CellEditModal from './CellEditModal';
import ColumnResizer from 'react-table-column-resizer';
import { LsFields } from '../../services/LsFields';

const LsRequestsTable = props => {
  const tableContainerRef = useRef(null);
  const tableRef = useRef(null);
  const [lsRequests, setLsRequests] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorImage, setErrorImage] = useState('');
  const [altTag, setAltTag] = useState('');
  const [editModalType, setEditModalType] = useState('');
  const [editModalValue, setEditModalValue] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isExpansionModalOpen, setIsExpansionModalOpen] = useState(false);
  const [filesToHighlight, setFilesToHighlight] = useState({});
  const [isFile, setIsFile] = useState(false);
  const {
    currentSearchType,
    dupArray,
    duplicateCheckResults,
    invalidSearch,
    isLoading,
    loadMoreLsRequests,
    lsRequests: lsRequestsProps,
    onChangeToUpdate,
    activateLsRequest,
    clickedLsRequest,
    columns,
    currentDupsearchLsNum,
    getFileAndDownload,
    id,
    markNotDuplicate,
    markOverlapping,
    markRelated,
    nextApi,
    onChangeCreateSubLS,
    onChangeToBillHistory,
    onGetLsRequest,
    onOpenOrCloseSidebar,
    onSortByHeader,
    openOrCloseLsRequestServicePopup,
    overlapDuplicates,
    prevApi,
    relatedDuplicates,
    searchTerms,
    sortedBy,
    userProfile,
    optionsChanged
  } = props;

  const nonChangableFields = LsFields.filter(
    field => field.changable === false
  );
  const findTitle = field => LsFields.find((lsField) => lsField.field === field)

  useEffect(() => {
    function scrollBarDetect(e) {
      const scrollPosition = e.target.scrollHeight - e.target.scrollTop;
      if (
        scrollPosition < 1500 &&
        currentSearchType !== 'Automated' &&
        !isLoading
      ) {
        loadMoreLsRequests();
      }
    }

    const tableContainerNode = tableContainerRef.current;
    if (tableContainerNode) {
      tableContainerRef.current.addEventListener(
        'scroll',
        scrollBarDetect,
        false
      );
    }

    return () => {
      if (tableContainerNode) {
        tableContainerNode.removeEventListener(
          'scroll',
          scrollBarDetect,
          false
        );
      }
    };
  }, [isLoading, currentSearchType, loadMoreLsRequests]);

  // Set error messages on change to loading, search, and/or ls request
  useEffect(() => {
    if (!isLoading && invalidSearch) {
      setErrorMessage('Invalid syntax found in search term(s)');
      setErrorImage('/../img/warning.svg');
      setAltTag('Invalid syntax warning');
    } else if (!isLoading && lsRequestsProps.length === 0) {
      setErrorMessage('No valid result');
      setErrorImage('/../img/no-results-found.svg');
      setAltTag('No Valid Results');
    } else {
      setErrorMessage('');
      setErrorImage('');
      setAltTag('');
    }
  }, [isLoading, invalidSearch, lsRequestsProps]);

  /**
   * useEffect hook for when currentSearchType changes, update lsRequests to be displayed as well
   */
  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const prevSearchTerms = usePrevious(searchTerms);
  const prevSortedBy = usePrevious(sortedBy);

  useEffect(() => {
    let updatedLsRequests = [];
    if (currentSearchType === 'Automated' && duplicateCheckResults) {
      updatedLsRequests = duplicateCheckResults;
    } else if (currentSearchType === 'Review') {
      updatedLsRequests = dupArray;
    } else {
      updatedLsRequests = lsRequestsProps;
    }
    
    const isSortedByChanged = prevSortedBy !== sortedBy;
    const isNewSearch = optionsChanged || prevSearchTerms !== searchTerms || searchTerms === undefined || isSortedByChanged;

    if (isNewSearch) {
      let highlightedLsRequests = updatedLsRequests;
      if (props.highlights && Object.keys(props.highlights).length > 0) {
        highlightedLsRequests = applyHighlights(updatedLsRequests, props.highlights);
      }
  
      setLsRequests(highlightedLsRequests);
  
      const highlightedFiles = {};
      if (props.highlights.file_hits) {
        for (let ls_id in props.highlights.file_hits) {
          const filesArray = props.highlights.file_hits[ls_id];
          filesArray.forEach(fileName => {
            highlightedFiles[fileName] = true;
          });
        }
      }
      setFilesToHighlight(highlightedFiles);
    } else {
      const existingIds = new Set(lsRequests.map(ls => ls.id));
      const newLsRequests = updatedLsRequests.filter(ls => !existingIds.has(ls.id));
  
      let highlightedNewLsRequests = newLsRequests;
      if (props.highlights && Object.keys(props.highlights).length > 0) {
        highlightedNewLsRequests = applyHighlights(newLsRequests, props.highlights);
      }
  
      let combinedLsRequests = [];
      if (currentSearchType === 'Automated' && duplicateCheckResults) {
        combinedLsRequests = duplicateCheckResults;
      } else if (currentSearchType === 'Review') {
        combinedLsRequests = dupArray;
      } else {
        combinedLsRequests = [...lsRequests, ...highlightedNewLsRequests];
      }
  
      setLsRequests(combinedLsRequests);
  
      const highlightedFiles = { ...filesToHighlight };
      if (props.highlights.file_hits) {
        for (let ls_id in props.highlights.file_hits) {
          const filesArray = props.highlights.file_hits[ls_id];
          filesArray.forEach(fileName => {
            highlightedFiles[fileName] = true;
          });
        }
      }
      setFilesToHighlight(highlightedFiles);
    }
  }, [
    currentSearchType,
    duplicateCheckResults,
    lsRequestsProps,
    dupArray,
    props.highlights,
    searchTerms,
  ]);
  

  // Open update popup window
  function onOpenUpdate(ls) {
    onChangeToUpdate();
    onGetLsRequest(ls);
    document.body.style.overflow = 'hidden';
  }

  /* 
  function onOpenSubLS () {
    onChangeCreateSubLS();
    openOrCloseLsRequestServicePopup(true);
    onGetLsRequest();
    document.body.style.overflow = "hidden";
  }

  // Open bill history popup
  function onOpenBillHistory () {
    onChangeToBillHistory();
    openOrCloseLsRequestServicePopup(true);
    onGetLsRequest();
    document.body.style.overflow = "hidden";
  }
 */
  function onOpenDuplicateCheck(id) {
    window.open(
      `${window.location.origin}/lsRequestList/LsDupSearch/${id.toString()}`
    );
  }

  function onOpenSidebar(ls) {
    onOpenOrCloseSidebar(true);
    clickedLsRequest(ls, 'clicked');
    document.body.style.overflow = 'hidden';
  }

  function requestActivation(ls) {
    const userInput = window.confirm(
      'An activation request will be submitted and reviewed internally.\nYou will be notified upon activation of any LS Request.\n\nDo you want to proceed?'
    );
    if (userInput) {
      const toast = {
        showToast: true,
        toastTitle: 'LSR Activation Requested',
        toastBody: `Activation Request for LS Request #${ls.ls_number} was successful.`,
        toastApp: '/img/Navbar/lsr.png',
        toastAppAlt: 'LSR Request Icon',
      };
      localStorage.setItem('toastInfo', JSON.stringify(toast));
      activateLsRequest(ls.ls_number);
    }
  }

  const lsRowColor = ls_num => {
    if (overlapDuplicates.includes(ls_num)) {
      return '#FBD9D3';
    } else if (relatedDuplicates.includes(ls_num)) {
      return '#FFFCC9';
    }
  };

  // Function to truncate the middle of the file name.
  const truncateMiddle = (str, beginning = 15, ending = 8) => {
    if (str.length <= beginning + ending) return str;
    const start = str.substring(0, beginning);
    const end = str.substring(str.length - ending);
    return `${start}...${end}`;
  };

  const openModal = (
    e,
    ls,
    editModalType,
    editModalValue,
    isFile = false
  ) => {
    const cmEditFields = [
      'attachment_descriptions',
      'contact_person',
      'cm_attachment',
      'matters',
      'make_first_prime_public',
    ];
    const staffEditFields = [
      'attachment_descriptions',
      'bill_matrix',
      'bill',
      'cm_attachment',
      'cm_notes',
      'committee',
      'confidential_comments',
      'contact_person',
      'coversheet',
      'division',
      'first_in_time',
      'first_prime',
      'hearing_report',
      'hearing_summary',
      'legal_memo',
      'ls_type',
      'make_first_prime_public',
      'plain_language_summary',
      'prev_sess_ls_number',
      'reintroduction',
      'operational_requirement',
      'resource_letter',
      'staff',
      'status',
    ];
    // Do nothing if any of these conditionas are true when right clicking on a cell
    if (
      // If CM-related only allow edit of waiver if not previously checked/True
      (editModalType === 'make_first_prime_public' &&
        editModalValue === true &&
        rolesUnderCouncilMembers.includes(userProfile.role)) ||
      // If CM-related and field is NOT in CM editable field
      (rolesUnderCouncilMembers.includes(userProfile.role) &&
        !cmEditFields.includes(editModalType)) ||
      // If NOT CM-related and field is NOT in staff editable field
      (!rolesUnderCouncilMembers.includes(userProfile.role) &&
        !staffEditFields.includes(editModalType)) ||
      // If LS belongs to a former CM and is "withdrawn"
      ls.status === 'Withdrawn - Former CM'
    ) {
      // trigger Expansion Modal
       e?.preventDefault();
      setEditModalType(editModalType);
      setEditModalValue(editModalValue);
      setIsExpansionModalOpen(true);
      setIsEditModalOpen(false);
    } else {
      //trigger Edit Modal instead
    e?.preventDefault();
    setIsFile(isFile);
    clickedLsRequest(ls, 'clicked');
    setEditModalType(editModalType);
    setEditModalValue(editModalValue);
    setIsEditModalOpen(true);
    setIsExpansionModalOpen(false)
    }
  };

  const closeEditModal = () => {
    setEditModalType('');
    setEditModalValue('');
    setIsEditModalOpen(false);
    setIsExpansionModalOpen(false);
  };

  function applyHighlights(lsRequests, highlights) {
    const updatedLsRequests = lsRequests.map(ls => ({ ...ls }));
  
    const nestedSubFields = {
      'associated_report_tracking': ['discrepancies', 'title'],
      'bill': ['file_name', 'text'],
      'bill_matrix': ['file_name', 'text'],
      'cm_attachment': ['file_name', 'text'],
      'committee': ['committee_name'],
      'first_prime': ['full_name'],  // sponsor
      'staff': ['full_name'],
      'submitted_by': ['full_name'],
      'contact_person': ['full_name'],
      'coversheet': ['file_name', 'text'],
      'hearing_report': ['file_name', 'text'],
      'hearing_summary': ['file_name', 'text'],
      'legal_memo': ['file_name', 'text'],
      'local_law': ['link', 'law_number'],
      'plain_language_summary': ['file_name', 'text'],
      'overlapping_ls': ['ls_number'],
      'resource_letter': ['file_name', 'text'],
      'related_ls': ['ls_number'],
    };
  
    Object.keys(highlights).forEach(key => {
      if (nestedSubFields.hasOwnProperty(key)) {
        const field = key;
        const lsFieldHighlights = highlights[field]; 
        Object.keys(lsFieldHighlights).forEach(lsId => {
          const highlightedSnippets = lsFieldHighlights[lsId];
          const ls = updatedLsRequests.find(ls => ls.id.toString() === lsId);
          if (ls) {
            const subFields = nestedSubFields[field];
            const originalValue = ls[field];
            if (Array.isArray(originalValue)) {
              const updatedArray = originalValue.map(item => {
                let updatedItem = { ...item };
                subFields.forEach(subField => {
                  if (typeof item[subField] === 'string') {
                    const originalText = item[subField];
                    const updatedText = applyHighlightsToText(originalText, highlightedSnippets);
                    updatedItem[subField] = updatedText;
                  }
                });
                return updatedItem;
              });
              ls[field] = updatedArray;
            } else if (typeof originalValue === 'object' && originalValue !== null) {
              let updatedValue = { ...originalValue };
              subFields.forEach(subField => {
                if (typeof originalValue[subField] === 'string') {
                  const originalText = originalValue[subField];
                  const updatedText = applyHighlightsToText(originalText, highlightedSnippets);
                  updatedValue[subField] = updatedText;
                }
              });
              ls[field] = updatedValue;
            }
          }
        });
      } else {
        const lsId = key;
        const ls = updatedLsRequests.find(ls => ls.id.toString() === lsId);
        if (ls) {
          const highlightFields = highlights[lsId];
          Object.keys(highlightFields).forEach(field => {
            const highlightedSnippets = highlightFields[field];
  
            if (nestedSubFields.hasOwnProperty(field)) {
              const subFields = nestedSubFields[field];
              const originalValue = ls[field];
              if (Array.isArray(originalValue)) {
                const updatedArray = originalValue.map(item => {
                  let updatedItem = { ...item };
                  subFields.forEach(subField => {
                    if (typeof item[subField] === 'string') {
                      const originalText = item[subField];
                      const updatedText = applyHighlightsToText(originalText, highlightedSnippets);
                      updatedItem[subField] = updatedText;
                    }
                  });
                  return updatedItem;
                });
                ls[field] = updatedArray;
              } else if (typeof originalValue === 'object' && originalValue !== null) {
                let updatedValue = { ...originalValue };
                subFields.forEach(subField => {
                  if (typeof originalValue[subField] === 'string') {
                    const originalText = originalValue[subField];
                    const updatedText = applyHighlightsToText(originalText, highlightedSnippets);
                    updatedValue[subField] = updatedText;
                  }
                });
                ls[field] = updatedValue;
              }
            } else {
              if (typeof ls[field] === 'string') {
                const originalText = ls[field];
                const updatedText = applyHighlightsToText(originalText, highlightedSnippets);
                ls[field] = updatedText;
              }
            }
          });
        }
      }
    });
  
    return updatedLsRequests;
  }

  function applyHighlightsToText(originalText, highlightedSnippets) {
    let resultText = originalText;
  
    const highlightedTerms = new Set();
  
    highlightedSnippets.forEach(snippet => {
      const regex = /<strong className=['"]highlight['"]>(.*?)<\/strong>/g;
      let match;
      while ((match = regex.exec(snippet)) !== null) {
        const term = match[1];
        highlightedTerms.add(term);
      }
    });
  
    highlightedTerms.forEach(term => {
      const termRegex = new RegExp(`\\b${escapeRegExp(term)}\\b`, 'gi');
  
      resultText = resultText.replace(termRegex, match => `<strong class='highlight'>${match}</strong>`);
    });
  
    return resultText;
  }
  
  function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  }

  /** Table Headers */
  // Display the table header
  const tableHeaders = columns.map((column, idx) => {
    if (
      !(
        rolesUnderCouncilMembers.includes(userProfile.role) &&
        column.field === 'confidential_comments'
      )
    ) {
      return (
        (column.show || column.field === 'ls_number') && (
          <React.Fragment key={idx}>
            <TableHeader
              sortColumn={() => {
                onSortByHeader(
                  column.field === 'days_past_60'
                    ? 'last_activated'
                    : column.field
                );
              }}
              title={column.title}
              field={sortedBy}
              showSort={sortedBy.includes(
                column.field === 'days_past_60'
                  ? 'last_activated'
                  : column.field
              )}
              key={idx}
              description={column.description}
              toolTipId={idx}
            />
            <ColumnResizer className={styles['resizer']} />
          </React.Fragment>
        )
      );
    }
    return null;
  });
  /** End Table Headers */

  /** Table Content (Rows) */
  // Display table rows -> lsRequests state mapped
  const tableRows = lsRequests.map((ls, idx) => {
    // If the ls number is the one currently being reviewed, exclude from table
    if (ls.ls_number !== currentDupsearchLsNum) {
      // All other ls requests
      let rowClassName =
        ls.status === 'Withdrawn - Former CM' ? `${styles['archived-ls']}` : '';
      if (overlapDuplicates && overlapDuplicates.includes(ls.ls_number)) {
        rowClassName += ' table-tr-overlapping';
      } else if (
        relatedDuplicates &&
        relatedDuplicates.includes(ls.ls_number)
      ) {
        rowClassName += ' table-tr-related';
      } else {
        rowClassName += ' table-tr';
      }

      return (
        <tr
          className={rowClassName}
          key={idx}
          style={
            window.location.pathname.includes('LsDupSearch')
              ? { backgroundColor: lsRowColor(ls.ls_number) }
              : {}
          }>
          {columns.map((column, idx1) => {
            let cell;
            let field = column.field;
            let value = ls[field] || '';

            if (field === 'ls_number') {
              cell = (
                <td
                  key={idx1}
                  className={`${styles['z-index']}`}
                  onClick={() => onOpenSidebar(ls)}>
                  {value}
                </td>
              );
            } else if (column.show) {
              if (field === 'date_received') {
                cell = (
                  <td
                    key={idx1}
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e => {
                      openModal(e, ls, field, formatDateTime(parse(value)));
                    }}>
                    {formatDateTime(ls.date_received)}
                    <i
                      className={`${styles['is-hidden']} fa-solid fa-up-right-and-down-left-from-center`}
                      onClick={e => {
                        e.stopPropagation();
                        openModal(e,ls,field,formatDateTime(parse(value)));
                      }}></i>
                  </td>
                );
              } else if (column.type === 'file') {
                const files = value;
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    className={`${styles['td-relative']}`}
                    key={idx1}
                    onContextMenu={e => openModal(e, ls, field, value = files.map((file, index) => {
                      const hightlighted = filesToHighlight.hasOwnProperty(file.file_name);
                      return (
                        <File
                          id={`lsr_${field}-${idx}-${idx1}-${index}`}
                          file={file}
                          fileName={!isEditModalOpen && !isExpansionModalOpen ?
                            truncateMiddle(file['file_name']) : null}
                          getFileAndDownload={getFileAndDownload}
                          key={index}
                          highlighted={hightlighted}
                        />
                      )}), true)}>
                    {files.map((file, index) => {
                      const hightlighted = filesToHighlight.hasOwnProperty(file.file_name);
                      return (
                        <File
                          id={`lsr_${field}-${idx}-${idx1}-${index}`}
                          file={file}
                          fileName={!isEditModalOpen && !isExpansionModalOpen ?
                            truncateMiddle(file['file_name']) : null}
                          getFileAndDownload={getFileAndDownload}
                          key={index}
                          highlighted={hightlighted}
                        />
                      )})}
                   { ls.status === "Withdrawn - Former CM" ? value.length > 0 && ( 
                    <i 
                      className={`${styles['is-hidden']} fa-solid fa-up-right-and-down-left-from-center`}
                      onClick={e => {
                        e.stopPropagation();
                        openModal(e, ls, field, value = files.map((file, index) => {
                          const hightlighted = filesToHighlight.hasOwnProperty(file.file_name);
                          return (
                            <File
                              id={`lsr_${field}-${idx}-${idx1}-${index}`}
                              file={file}
                              fileName={!isEditModalOpen && !isExpansionModalOpen ?
                                truncateMiddle(file['file_name']) : null}
                              getFileAndDownload={getFileAndDownload}
                              key={index}
                              highlighted={hightlighted}
                            />
                          )}), true);
                      }}></i> ) :  (<i
                      className={`${styles['is-hidden']} fa-solid fa-pen-to-square`}
                      onClick={e => {
                        e.stopPropagation();
                        openModal(e, ls, field, value.id, true);
                      }}></i> )}
                  </td>
                );
              } else if (field === 'committee') {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e => openModal(e, ls, field, parse(value.committee_name))}
                    key={idx1}
                    className={`${styles['td-relative']}`}>
                    <i
                      className={`${styles['is-hidden']} fa-solid ${ls.status !== 'Withdrawn - Former CM' ? 'fa-pen-to-square' : 'fa-up-right-and-down-left-from-center'}`}
                      onClick={e => {
                        e.stopPropagation();
                        openModal(e, ls, field, parse(value.committee_name));
                      }}></i>
                    {parse(value.committee_name)}
                  </td>
                );
              } else if (field === 'associated_report_tracking') {
                cell = (
                  <td
                    className={`${styles['td-relative']}`}
                    onClick={() => onOpenSidebar(ls)}
                    key={idx1}
                    onContextMenu={e => {
                      value?.length > 0
                      ? (
                          openModal( e, ls, field, parse(value.map(report => report.title).join(', ')))
                      ) : null;
                    }}
                    >
                    {parse(
                      value ? value.map(report => report.title).join(', ') : ''
                    )}
                    {value?.length > 0 && (
                        <i
                        className={`${styles['is-hidden']} fa-solid fa-up-right-and-down-left-from-center`}
                        onClick={e => {
                        e.stopPropagation();
                        openModal(e, ls, field, parse(value.map(report => report.title).join(', ')));
                        }}></i>
                    )}
                  </td>
                );
              } else if (
                ['first_prime', 'staff', 'submitted_by'].includes(field)
              ) {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    key={idx1}
                    onContextMenu={e => openModal(e, ls, field, parse(value.full_name))}>
                    {parse(value.full_name)}
                    {field === 'submitted_by' ? (
                      <i
                      className={`fa-solid fa-up-right-and-down-left-from-center ${styles['is-hidden']}`}
                      onClick={e => {
                        e.stopPropagation();
                        openModal(e, ls, field, parse(value.full_name));  
                      }}></i>
                    ) : (
                      <i
                      className={`fa-solid ${ls.status !== 'Withdrawn - Former CM' ? 'fa-pen-to-square' : 'fa-up-right-and-down-left-from-center'} ${styles['is-hidden']}`}
                      onClick={e => {
                        e.stopPropagation();
                        openModal(e, ls, field, parse(value.full_name));
                      }}></i>
                    )}
                  </td>
                );
              } else if (field === 'contact_person') {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    key={idx1}
                    onContextMenu={e => openModal(e, ls, field,  parse(value.full_name + '\n' + value.phone + '\n' + value.email))}
                    id={`${ls.ls_number}-${field}`}>
                    <pre className={styles['pre']}>
                      {parse(value.full_name)}
                      <br />
                      {parse(value.phone)}
                      <br />
                      {parse(value.email)}
                    </pre>
                    <i
                      className={`fa-solid ${styles['is-hidden']} ${ls.status !== 'Withdrawn - Former CM' ? 'fa-pen-to-square' : 'fa-up-right-and-down-left-from-center'}`}
                      onClick={e => {
                        e.stopPropagation();
                        openModal(e, ls, field, parse(value.full_name + '\n' + value.phone + '\n' + value.email));
                      }}></i>
                  </td>
                );
              } else if (
                [
                  'attachment_descriptions',
                  'background',
                  'background_existing_laws',
                  'background_inspiration',
                  'cm_notes',
                  'confidential_comments',
                  'description_legislative_solution',
                  'description_problem',
                  'description_related_discussions',
                  'division',
                  'first_in_time',
                  'ls_type',
                  'status',
                ].includes(field)
              ) {
                if (field === 'confidential_comments') {
                  value = value ? value : '';
                }
                cell = (
                  <td
                    className={`${styles['ls-' + field.replace(/_/g, '-')]} ${
                      styles['td-relative']
                    }`}
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e => {
                      openModal(e, ls, field, parse(value));
                    }}
                    key={idx1}>
                    <div>{parse(value)}</div>
                    { ls.status !== 'Withdrawn - Former CM' &&
                    [
                      'attachment_descriptions',
                      'cm_notes',
                      'confidential_comments',
                      'division',
                      'first_in_time',
                      'ls_type',
                      'status',
                    ].includes(field) ? (
                      <i
                        className={`${styles['is-hidden']} fa-solid fa-pen-to-square`}
                        onClick={e => {
                          e.stopPropagation();
                          openModal(e, ls, field, parse(value));
                        }}></i>
                    ) : parse(value)?.length > 0  ? (
                      <i
                        className={`fa-solid fa-up-right-and-down-left-from-center ${styles['is-hidden']}`}
                        onClick={e => {
                          e.stopPropagation();
                          openModal(e, ls, field, parse(value));
                        }}></i>
                    ) : null }
                  </td>
                );
              } else if (field === 'reintroduction') {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e => openModal(e, ls, field, value = !value ? "N/A" : value === false ? 'No' : "Yes")}
                    key={idx1}>
                    {value === true ? 'Yes' : value === false ? 'No' : 'N/A'}
                    <i
                      className={`${styles['is-hidden']} fa-solid ${ls.status !== 'Withdrawn - Former CM' ? 'fa-pen-to-square' : 'fa-up-right-and-down-left-from-center'} `}
                      onClick={e => {
                        e.stopPropagation();
                        openModal(e, ls, field, value = !value ? "N/A" : value === false ? 'No' : "Yes");
                      }}></i>
                  </td>
                );
              } else if (field === 'prev_sess_ls_number') {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e =>
                      openModal(e, ls, field, value = !value ? "N/A" : value)
                    }
                    key={idx1}>
                    {value ? value : 'N/A'}
                    <i
                      className={`${styles['is-hidden']} fa-solid ${ls.status !== 'Withdrawn - Former CM' ? 'fa-pen-to-square' : 'fa-up-right-and-down-left-from-center'} `}
                      onClick={e => {
                        e.stopPropagation();
                        openModal(e, ls, field, value = !value ? "N/A" : value);
                      }}></i>
                  </td>
                );
              } else if (
                [
                  'operational_requirement',
                  'make_first_prime_public',
                  'duplicate_check',
                  'activation_requested',
                ].includes(field)
              ) {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e => openModal(e, ls, field, (value = value ? (value = parse('True')) : parse('False')))}
                    key={idx1}>
                    {value ? 'True' : 'False'}
                    {[
                      'operational_requirement',
                      'make_first_prime_public',
                    ].includes(field) && ls.status !== 'Withdrawn - Former CM' ? (
                      <i
                        className={`${styles['is-hidden']} fa-solid fa-pen-to-square`}
                        onClick={e => {
                          e.stopPropagation();
                          openModal(e, ls, field, value);
                        }}></i>
                    ) : (
                      <i
                        className={`${styles['is-hidden']} fa-solid fa-up-right-and-down-left-from-center`}
                        onClick={e => { 
                          e.stopPropagation();
                          openModal(e, ls, field, (value = value ? (value = parse('True')) : parse('False')));
                        }}></i>
                    )}
                  </td>
                );
              } else if (['overlapping_ls', 'related_ls'].includes(field)) {
                let lsArray = value.map(ls => ls.ls_number);
                cell = (
                  <td
                    className={styles['ls-duplicates']}
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e => {
                      e.stopPropagation();
                      openModal(e, ls, field, value = lsArray.join(', '))
                      }}
                    key={`ls-${field}-${ls['ls_number']}`}>
                    {lsArray?.length < 1 ? null : (
                      <DuplicatesTooltip
                        index={`ls-${field}-${ls['ls_number']}`}
                        dupes={lsArray}
                        field={field.replace(/_/g, ' ').toUpperCase()}
                      />
                    )}
                    {lsArray?.length > 0 && (
                      <i
                        className={`${styles['is-hidden']} fa-solid fa-up-right-and-down-left-from-center`}
                        onClick={e => { 
                          e.stopPropagation(); 
                          openModal(e, ls, field, value = lsArray.join(', '))
                          // setIsExpansionModalOpen(true);
                          // setIsEditModalOpen(false)
                        }}></i>
                    )}
                  </td>
                );
              } else if (field === 'last_activated') {
                let sixtyText = null;
                if (value) {
                  let activated = new Date(value);
                  activated.setDate(activated.getDate() + 1);
                  let today = new Date();
                  let daysDifference = Math.ceil(
                    Math.abs(today - activated) / (1000 * 60 * 60 * 24)
                  ); // ms * s * min * hour
                  let days_60 = new Date(ls.last_activated);
                  days_60.setDate(days_60.getDate() + 60);
                  if (daysDifference <= 60) {
                    sixtyText = (
                      <>
                        <span
                          id={`sixty-day-${ls.ls_number}`}
                          className={`${styles['sixty-day']}`}
                            style= {{color: '#4A5F96',}}>
                          {`${60 - daysDifference} day${
                            60 - daysDifference === 1 ? '' : 's'
                          }`}
                          <br /> UNTIL 60 days
                        </span>
                        <ToolTip
                          target={`sixty-day-${ls.ls_number}`}
                          text={`${days_60.toISOString().split('T')[0]}`}
                        />
                      </>
                    );
                  } else {
                    sixtyText = (
                      <>
                        <span
                          className={`${styles['sixty-day']}`}
                          style={{color: '#CA4A4A'}}>
                          {`${daysDifference - 60} day${
                            daysDifference - 60 === 1 ? '' : 's'
                          }`}
                          <br />
                          PAST 60 days
                        </span>
                        <ToolTip
                          id={`sixty-day-${ls.ls_number}`}
                          target={`sixty-day-${ls.ls_number}`}
                          text={`${days_60.toISOString().split('T')[0]}`}
                        />
                      </>
                    );
                  }
                }
                cell = (
                  <td
                    className={styles['ls-duplicates']}
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={(e) =>{
                      value ?
                    (openModal(e,ls),
                    setIsEditModalOpen(false),
                    setIsExpansionModalOpen(true) ) : null
                  }}
                    key={`ls-${field}-${ls['ls_number']}`}>
                    {parse(value)}
                    {!rolesUnderCouncilMembers.includes(userProfile.role) &&
                      activeStatuses.includes(ls['status']) && (
                        <>
                          <br />
                          {sixtyText}
                        </>
                      )}
                  </td>
                );
              } else if (field === 'matters') {
                let matterArray = value.map(matter => {
                  if (matter.law_number) {
                    return matter.law_number;
                  } else {
                    return matter.intro;
                  }
                });
                cell = (
                  <td
                    className={styles['ls-duplicates']}
                    onClick={() => onOpenSidebar(ls)}
                    onContextMenu={e => { 
                      if(value?.length > 0) {
                      openModal(e, ls, field, value[0].law_number);
                    }}}
                    key={`ls-${field}-${ls['ls_number']}`}>
                    {matterArray?.length < 1 ? null : matterArray.join(', ')}
                    {value?.length > 0 && (
                      <i
                        className={`${styles['is-hidden']} fa-solid fa-up-right-and-down-left-from-center`}
                        onClick={e => {
                          e.stopPropagation();
                          openModal(e, ls, field, value[0].law_number);
                        }}></i>
                    )}
                  </td>
                );
              } else {
                cell = (
                  <td
                    onClick={() => onOpenSidebar(ls)}
                    key={idx1}
                    onContextMenu={e => openModal(e, ls, field, value.id)

                    }>
                    {parse(value)}
                  </td>
                );
              }
            }
            return (
              <React.Fragment key={idx1}>
                {cell}
                {cell && (
                  <td className={styles['resizer']}
                  />
                )}
              </React.Fragment>
            );
          })}
          <td>
            {(ls.status !== 'Withdrawn - Former CM' ||
              [...superAdminRoles, ...adminRoles, ...fourthTierRoles].includes(
                props.userProfile.role
              )) && (
              <div className='dropdown dropstart'>
                <button
                  className={`btn ${styles['column-menu']}`}
                  type='button'
                  id={`ls-options-dropdown-${ls.ls_number}`}
                  data-bs-toggle='dropdown'
                  data-bs-offset='0,11'
                  data-bs-reference='parent'
                  aria-expanded='false'>
                  <ThreeDotsVertical />
                </button>
                <ul
                  className={`dropdown-menu ${styles['dropdown-menu-adjusted']}`}
                  aria-labelledby={`ls-options-dropdown-${ls.ls_number}`}>
                  {!window.location.pathname.includes('LsDupSearch') && (
                    <>
                      {ls.status === 'Low-Priority Hold' &&
                        !ls.activation_requested && (
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() => requestActivation(ls)}>
                              Request Activation
                            </button>
                          </li>
                        )}
                      {userProfile.permissions.lsRequest_api.change_lsrequest &&
                        (ls.status !== 'Withdrawn - Former CM' || [...superAdminRoles, ...adminRoles, ...fourthTierRoles].includes(props.userProfile.role)) && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => onOpenUpdate(ls)}>
                            Update LS Request
                          </button>
                        </li>
                      )}
                      {/* Create Sub LS */}
                      {/* {
                        userProfile.permissions.lsRequest_api.add_lsrequest && [...legDivisionRoles, ...attorneyRoles].includes(userProfile.role) &&
                          <li>
                            <button className="dropdown-item" type="button" onClick={onOpenSubLS}>
                              Create Sub LS
                            </button>
                          </li>
                      } */}
                      {/* END Create Sub LS */}
                      {userProfile.permissions.lsRequest_api.view_lsrequest && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              let path = window.location.pathname.includes(
                                'myTask'
                              )
                                ? window.location.pathname
                                    .split('/')
                                    .slice(0, 3)
                                    .join('/')
                                : window.location.pathname
                                    .split('/')
                                    .slice(0, 2)
                                    .join('/');
                              window.location.href = `${path}/${ls.ls_number}/history`;
                            }}>
                            View Change History
                          </button>
                        </li>
                      )}
                      {/*
                      <li>
                        <button className="dropdown-item" type="button" onClick={onOpenBillHistory} >
                          View Bill History
                        </button>
                      </li> */}
                      {!rolesUnderCouncilMembers.includes(userProfile.role) &&
                        ls.status !== 'Withdrawn - Former CM' && (
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() => {
                                onOpenDuplicateCheck(ls.ls_number);
                              }}>
                              Duplicate Search
                            </button>
                          </li>
                        )}
                    </>
                  )}
                  {window.location.pathname.includes('LsDupSearch') && (
                    <>
                      {!overlapDuplicates.includes(ls.ls_number) && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              markOverlapping(ls);
                            }}>
                            Mark Overlapping
                          </button>
                        </li>
                      )}
                      {!relatedDuplicates.includes(ls.ls_number) && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              markRelated(ls);
                            }}>
                            Mark Related
                          </button>
                        </li>
                      )}
                      {(overlapDuplicates.includes(ls.ls_number) ||
                        relatedDuplicates.includes(ls.ls_number)) && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              markNotDuplicate(ls);
                            }}>
                            Unmark Duplicate
                          </button>
                        </li>
                      )}
                    </>
                  )}
                </ul>
              </div>
            )}
          </td>
        </tr>
      );
    }
  });
  /** End Table Content */
  return (
    <>
      {/* Render CellEditModal if isEditModalOpen is true and isExpansionModalOpen is false */}
      {isEditModalOpen && !isExpansionModalOpen && (
        <>
          <div className={`${styles['modal-background']}`} onClick={closeEditModal} />
          <CellEditModal
            cellType={editModalType}
            cellValue={editModalValue}
            closeModal={closeEditModal}
            isFile={isFile}
          />
        </>
      )}
      {isExpansionModalOpen && editModalValue?.length > 0 && (
            <>
            <div className={`${styles['modal-expansion']}`}>
              <div className={styles['update-ls-request-header-container']}>
                <p className={styles['modal-title']}>
                  {findTitle(editModalType)?.title}
                </p>
              </div>
              {/* UPDATE LSR Expansion BODY */}
              <p className={styles['modal-value']}>{editModalValue}</p>
            </div>
            <div onClick={closeEditModal} className={`${styles['modal-background']}`} />
          </>
        )}
      <div
        ref={tableContainerRef}
        id={id}
        className={styles['table-container']}>
        <table
          ref={tableRef}
          id='lsrTable'
          cellSpacing='0'
          className={`${styles['list-table']} list-table`}>
          <thead>
            <tr className='table-headers'>
              {tableHeaders}
              <th className={styles['checkbox-header']}></th>
            </tr>
          </thead>
          <tbody>{tableRows}</tbody>
        </table>
        {!isLoading && (lsRequestsProps?.length === 0 || invalidSearch) && (
          <div
            className={`w-100 text-center p-3 ${styles['no-valid-result-container']}`}>
            <img src={errorImage} alt={altTag}></img>
            <p>{errorMessage}</p>
          </div>
        )}
      </div>
      {(isLoading ||
        (window.location.pathname.includes('LsDupSearch') &&
          (duplicateCheckResults === undefined ||
            duplicateCheckResults?.length === 0))) && (
        <div
          className={`spinner-grow ${styles['spinner-animation']}`}
          role='status'>
          <span className='visually-hidden'>Loading...</span>
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    committeeList: state.userReducer.committees,
    invalidSearch: state.lsRequestsReducer.invalidSearch,
    isLoading: state.lsRequestsReducer.isLoading,
    lsRequests: state.lsRequestsReducer.lsRequests,
    selectedLs: state.lsRequestsReducer.updateTargetLs,
    nextApi: state.lsRequestsReducer.nextApi,
    prevApi: state.lsRequestsReducer.prevApi,
    userList: state.userReducer.userList,
    userProfile: state.userReducer.userProfile,
    duplicateCheckResults: state.lsRequestsReducer.duplicateCheckResults,
    searchTerms: state.lsRequestsReducer.searchTerms,
    highlights: state.lsRequestsReducer.highlights
  };
};

const mapDispatchToProps = {
  activateLsRequest,
  clickedLsRequest,
  getFileAndDownload,
  openOrCloseLsRequestServicePopup,
  storeHighlightedLsRequests,
  updateTargetLsRequest,
};

export default connect(mapStateToProps, mapDispatchToProps)(LsRequestsTable);