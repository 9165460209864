import React, { useState, useEffect } from 'react';
import styles from '../../styles/report-tracking/Calendar.module.scss';
import {
  setYear,
  addDays,
  setMonth,
  format,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  isSameMonth,
  isSameDay,
  toDate,
  addMonths,
  subMonths,
} from 'date-fns';
import { connect } from 'react-redux';
import * as actions from '../../actions/reportTrackingAction';
import ReportServicePopup from './ReportServicePopup';
import CheckboxMenu from '../library/CheckboxMenu';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import { legDivisionRoles, superAdminRoles } from '../../services/constants';
import {
  ShortLeftArrowIcon,
  ShortRightArrowIcon,
  InfoCircleFilledIcon,
  ReportStatusCircle,
  PeopleCommitteeIcon,
  ProgressBarIcon,
  ClockIcon,
  CheckmarkIcon,
  FlagFilledIcon,
  HalfCirlcleIcon,
  PlusIcon,
} from '../../services/SvgLibrary';

const Calendar = props => {
  const [changeMonthYear, setChangeMonthYear] = useState(false);
  const [checkAllCommittees, setCheckAllCommittees] = useState(false);
  const [committees, setCommittees] = useState([]);
  const [completed, setCompleted] = useState(true);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [years, setYears] = useState([]);
  const [displayCommittee, setDisplayCommittee] = useState(false);
  const [displayMilestones, setDisplayMilestones] = useState(true);
  const [displayReports, setDisplayReports] = useState(true);
  const [inputMonth, setInputMonth] = useState(format(new Date(), 'M') - 1);
  const [inputYear, setInputYear] = useState(format(new Date(), 'yyyy'));
  const [pastDue, setPastDue] = useState(true);
  const [preset, setPreset] = useState('');
  const [received, setReceived] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [upcoming, setUpcoming] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    let yearsArray = [];
    const today = new Date();
    let todayYear = today.getFullYear() + 5;
    for (let i = 2022; i <= todayYear; i++) {
      yearsArray.push(i);
    }
    setYears(yearsArray);

    const updateScreenWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', updateScreenWidth);

    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, [])

  useEffect(() => {
    if(committees.length === 0) {
      let filteredCommittees = props.allCommittees.filter(
        com => com.committee_name !== 'Unassigned'
      );
  
      setCommittees(
        filteredCommittees.map(com => {
          return { ...com, show: true };
        })
      );
      let lastPresetView = localStorage.getItem(
        'opReq-active-committee-selection'
      );
      let presetValues;
      if (lastPresetView && localStorage.getItem(lastPresetView)) {
        setPreset(lastPresetView);
        presetValues = localStorage.getItem(lastPresetView);
      } else {
        let newPreset = `opReq-committee-${props.userProfile.username}`;
        setPreset(newPreset);
        localStorage.setItem('opReq-active-committee-selection', newPreset);
        if (localStorage.getItem(newPreset)) {
          presetValues = localStorage.getItem(newPreset).split('|');
        } else {
          presetValues = props.allCommittees
            .map(com => com.committee_name)
            .join('|');
          localStorage.setItem(newPreset, presetValues);
        }
      }
  
      let savedCommittees = presetValues.split('|');
      setCommittees(
        props.allCommittees.map(com => {
          return { ...com, show: savedCommittees.includes(com.committee_name) };
        })
      );
    }


  }, [props.allCommittees, props.userProfile.username]);

  useEffect(() => {
    if (preset) {
      let committeeToSave = committees.reduce((acc, com) => {
        if (com.show) {
          acc.push(com.committee_name);
        }
        return acc;
      }, []);
      localStorage.setItem(preset, committeeToSave.join('|'));

      let checkAll = committees.every(com => com.show);
      if (checkAllCommittees !== checkAll) {
        setCheckAllCommittees(checkAll);
      }
    }
  }, [committees, preset, checkAllCommittees]);

  const onSelectTime = e => {
    e.preventDefault();
    let newDate = setMonth(currentMonth, inputMonth);
    newDate = setYear(newDate, inputYear);
    setCurrentMonth(newDate);
    setChangeMonthYear(false);
    let month = parseInt(inputMonth) + 1;
    if (month > 12) {
      month = 1;
    }
    props.getReportsDueCurrentMonthAndYear(month, inputYear);
  };

  const onCheckOrUncheckAllCheckbox = () => {
    setCheckAllCommittees(!checkAllCommittees);
    setCommittees(
      props.allCommittees.map(com => ({
        ...com,
        show: !checkAllCommittees,
      }))
    );
  };

  const toggleCommittee = title => {
    setCommittees(prevCommittees =>
      prevCommittees.map(com =>
        com.committee_name === title ? { ...com, show: !com.show } : com
      )
    );
  };

  const togglePreset = presetOption => {
    localStorage.setItem('opReq-active-committee-selection', presetOption);
    if (localStorage.getItem(presetOption)) {
      let savedCommittees = localStorage.getItem(presetOption).split('|');
      setCommittees(prevCommittees =>
        prevCommittees.map(com => {
          return { ...com, show: savedCommittees.includes(com.committee_name) };
        })
      );
      setPreset(presetOption);
    } else {
      const defaultView = props.allCommittees.map(com => {
        return { ...com, show: true };
      });
      setCheckAllCommittees(false);
      setCommittees(defaultView);
      setPreset(presetOption);
      let comsToSave = defaultView.reduce((acc, com) => {
        if (com.show) {
          acc.push(com.committee_name);
        }
        return acc;
      }, []);
      localStorage.setItem(presetOption, comsToSave.join('|'));
    }
  };

  const renderHeader = () => {
    const dateFormat = 'MMMM yyyy';
    const dateString = format(currentMonth, dateFormat);
    const dateArray = dateString.split(' ');
    const month = dateArray[0];
    const year = dateArray[1];
    let statusFilter = [];
    let typeFilter = [];
    if (displayReports) typeFilter.push('report');
    if (displayMilestones) typeFilter.push('milestone');
    if (pastDue) statusFilter.push('past_due');
    if (received) statusFilter.push('received');
    if (upcoming) statusFilter.push('upcoming');
    if (completed) statusFilter.push('completed');

    return (
      <div className={styles['calendarHeader']}>
        <div className='d-flex align-items-center'>
          {!changeMonthYear && (
            <>
              <button
                outline='true'
                className={`btn ${styles['calendar-view-toggle']}`}
                onClick={() => updateCalendar()}>
                Today
              </button>
              <ShortLeftArrowIcon
                classNameProp={styles['arrow-month-selection']}
                onClickFunction={() => updateCalendar('previous')}
              />
              <ShortRightArrowIcon
                classNameProp={styles['arrow-month-selection']}
                onClickFunction={() => updateCalendar('next')}
              />
              <div
                className={styles['current-month-container']}
                onClick={() => setChangeMonthYear(true)}>
                <span className={styles['current-month-wrapper']}>{month}</span>
                &nbsp;
                <span className={styles['current-year-wrapper']}>{year}</span>
              </div>
            </>
          )}
          {changeMonthYear && (
            <div className={styles['change-monthYear-container']}>
              <select
                className={`btn ${styles['select-box']}`}
                value={inputMonth}
                onChange={e => setInputMonth(e.target.value)}>
                {months.map((m, idx) => (
                  <option value={idx} key={'m'+idx}>
                    {m}
                  </option>
                ))}
              </select>
              <select
                className={`btn ${styles['select-box']}`}
                value={inputYear}
                onChange={e => setInputYear(e.target.value)}>
                {years.map((y, idx) => (
                  <option value={y} key={'y'+idx}>
                    {y}
                  </option>
                ))}
              </select>
              <button
                className={`btn ${styles['submit']}`}
                onClick={onSelectTime}>
                GO
              </button>
              <button
                className={`btn ${styles['close-monthYear-input-fields']}`}
                onClick={() => {
                  setChangeMonthYear(false);
                }}>
                Cancel
              </button>
            </div>
          )}
        </div>
        <div>
          <button
            className={`btn ${screenWidth > 1070 && `dropdown-toggle`} ${
              styles['dropdown-filter-toggle']
            }`}
            onClick={() => {
              setDisplayCommittee(!displayCommittee);
            }}
            id='PopoverMenu'
            type='button'>
            <PeopleCommitteeIcon width='20' height='20' classNameProp='pe-1' />
            {screenWidth > 1070 && (
              <>
                Committees
                <span
                  className='badge'
                  style={{ color: '#002164', backgroundColor: '#c0d1f3' }}>
                  {committees.filter(com => com.show).length}
                </span>
              </>
            )}
          </button>
          {displayCommittee && (
            <CheckboxMenu
              componentType='popover'
              checkboxes={committees}
              columnNumber={3}
              preset={preset}
              toggleCheckbox={toggleCommittee}
              togglePreset={togglePreset}
              checkAll={checkAllCommittees}
              isOpen={displayCommittee}
              toggleCheckboxMenu={() => setDisplayCommittee(!displayCommittee)}
              toggleAllCheckboxes={onCheckOrUncheckAllCheckbox}
              params='committee_name'
              presetNameBase='opReq-committee'
            />
          )}
          <button
            className={`btn ${screenWidth > 1070 && `dropdown-toggle`} ${
              styles['dropdown-filter-toggle']
            }`}
            id='PopoverCheckbox'
            type='button'>
            <ProgressBarIcon classNameProp='pe-1' />
            {screenWidth > 1070 && (
              <>
                Status
                <span
                  className='badge'
                  style={{ color: '#002164', backgroundColor: '#c0d1f3' }}>
                  {statusFilter.length}
                </span>
              </>
            )}
          </button>
          { props.userProfile.permissions.reportTracking.add_report &&
            <button
              className={`btn ${
                [
                  ...superAdminRoles,
                  ...legDivisionRoles,
                  'Finance Attorney',
                  'Land Use Attorney',
                ].includes(props.userProfile.role)
                  ? `${styles['create-report-button']}`
                  : `${styles['non-leg-staff']} ${styles['create-report-button']}`
              }`}
              onClick={() => {
                props.changeReportTrackingDisplay('create');
                props.changeReportTrackingPopupWindow(true);
              }}>
              <PlusIcon width='14' height='14' /> New
            </button>
          }
        </div>
        <UncontrolledPopover
          innerClassName={styles['popover-container']}
          className={styles['popover-outer-container']}
          trigger='legacy'
          placement='bottom'
          target='PopoverCheckbox'>
          <PopoverBody className={styles['filter-dropdown-wrapper']}>
            <h6 className='dropdown-header pb-0'>Type</h6>
            <label
              className={`${styles['color-filter-container']} ${styles['completed']}`}>
              Report
              <input
                type='checkbox'
                checked={displayReports}
                onChange={e => {
                  setDisplayReports(!displayReports);
                }}
              />
              <span
                className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
              <InfoCircleFilledIcon
                classNameProp={styles['filter-popover-user-guide']}
                idProp='report'
              />
            </label>
            <label
              className={`${styles['color-filter-container']} ${styles['completed']}`}>
              Milestone
              <input
                type='checkbox'
                checked={displayMilestones}
                onChange={e => {
                  setDisplayMilestones(!displayMilestones);
                }}
              />
              <span
                className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
              <InfoCircleFilledIcon
                classNameProp={styles['filter-popover-user-guide']}
                idProp='milestone'
              />
            </label>
            <UncontrolledPopover
              fade={false}
              trigger='hover'
              placement='bottom'
              target='report'>
              <PopoverBody>
                <div>
                  <b>Reports:</b> Reports are usually files mandated often on a
                  periodic basis by local law.
                </div>
              </PopoverBody>
            </UncontrolledPopover>
            <UncontrolledPopover
              fade={false}
              trigger='hover'
              placement='bottom'
              target='milestone'>
              <PopoverBody>
                <div>
                  <b>Milestones:</b> Milestones are one time goals that are to
                  be met.
                </div>
              </PopoverBody>
            </UncontrolledPopover>
            <hr className='dropdown-divider' />
            <h6 className='dropdown-header pb-0'>Progress</h6>
            <label
              className={`${styles['color-filter-container']} ${styles['completed']}`}>
              <CheckmarkIcon
                width='30px'
                height='20px'
                classNameProp={styles['status-icons']}
              />
              Completed
              <input
                type='checkbox'
                checked={completed}
                onChange={e => {
                  setCompleted(!completed);
                }}
              />
              <span
                className={`${styles['color-filter-checkmark']} ${styles['completed']}`}></span>
              <InfoCircleFilledIcon
                classNameProp={styles['filter-popover-user-guide']}
                idProp='completed'
              />
            </label>
            <label
              className={`${styles['color-filter-container']} ${styles['received']}`}>
              <HalfCirlcleIcon
                width='30px'
                height='20px'
                classNameProp={styles['status-icons']}
              />
              Received
              <input
                type='checkbox'
                checked={received}
                onChange={e => {
                  setReceived(!received);
                }}
              />
              <span
                className={`${styles['color-filter-checkmark']} ${styles['received']}`}></span>
              <InfoCircleFilledIcon
                classNameProp={styles['filter-popover-user-guide']}
                idProp='received'
              />
            </label>
            <label
              className={`${styles['color-filter-container']} ${styles['upcoming']}`}>
              <FlagFilledIcon
                width='30px'
                height='20px'
                classNameProp={styles['status-icons']}
              />
              Upcoming
              <input
                type='checkbox'
                checked={upcoming}
                onChange={e => setUpcoming(!upcoming)}
              />
              <span
                className={`${styles['color-filter-checkmark']} ${styles['upcoming']}`}></span>
              <InfoCircleFilledIcon
                classNameProp={styles['filter-popover-user-guide']}
                idProp='upcoming'
              />
            </label>
            <label
              className={`${styles['color-filter-container']} ${styles['past-due']}`}>
              <ClockIcon
                width='30px'
                height='20px'
                classNameProp={styles['status-icons']}
              />
              Past Due
              <input
                type='checkbox'
                checked={pastDue}
                onChange={e => {
                  setPastDue(!pastDue);
                }}
              />
              <span
                className={`${styles['color-filter-checkmark']} ${styles['past-due']}`}></span>
              <InfoCircleFilledIcon
                classNameProp={styles['filter-popover-user-guide']}
                idProp='past-due'
              />
            </label>

            <UncontrolledPopover
              fade={false}
              trigger='hover'
              placement='bottom'
              target='completed'>
              <PopoverBody>
                <div>
                  <b>Completed: </b>Milestone completed.
                </div>
              </PopoverBody>
            </UncontrolledPopover>

            <UncontrolledPopover
              fade={false}
              trigger='hover'
              placement='bottom'
              target='received'>
              <PopoverBody>
                <div>
                  <b>Received: </b>A report has been received for an upcoming
                  due date.
                </div>
              </PopoverBody>
            </UncontrolledPopover>

            <UncontrolledPopover
              fade={false}
              trigger='hover'
              placement='bottom'
              target='upcoming'>
              <PopoverBody>
                <div>
                  <b>Upcoming: </b>A report or milestone is due for the upcoming
                  due date.
                </div>
              </PopoverBody>
            </UncontrolledPopover>

            <UncontrolledPopover
              fade={false}
              trigger='hover'
              placement='bottom'
              target='past-due'>
              <PopoverBody>
                <div>
                  <b>Past due: </b>A report or milestone was not submitted for a
                  due date in the past.
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </PopoverBody>
        </UncontrolledPopover>
      </div>
    );
  };

  const renderDays = () => {
    const days = [];
    let startDate = startOfWeek(currentMonth);
    for (let i = 0; i < 7; i++) {
      days.push(
        <div className={styles['daysCol']} key={i}>
          {format(addDays(startDate, i), 'eee')}
        </div>
      );
    }
    return <div className={styles['daysRows']}>{days}</div>;
  };

  const renderOpReqsForTheDay = (opReqsDueForTheDay, day) => {
    const currentDate = currentMonth;
    currentDate.setDate(day);
    let pastDueOpReqs = opReqsDueForTheDay.filter(
      report => report[0] === 'past_due'
    );
    let completedOpReqs = opReqsDueForTheDay.filter(
      report => report[0] === 'completed'
    );
    let upcomingOpReqs = opReqsDueForTheDay.filter(
      report => report[0] === 'upcoming'
    );
    let receivedOpReqs = opReqsDueForTheDay.filter(
      report => report[0] === 'received'
    );

    let totalOpReqs = opReqsDueForTheDay.length;
    let opTitles = opReqsDueForTheDay.map((op)=>{
      let colorValue = op[0]
      let titleText = op[1].title
      return (<div className={`${styles[colorValue]} ${styles["report-title-display"]}`} key={'op'+ titleText}>{titleText}</div>)
    })

    return (
      <>
        <div className={styles['report-calendar-day-container']}>
          {pastDueOpReqs.length > 0 && pastDue && totalOpReqs > 3 && (
            <ReportStatusCircle
              classNameProp={styles['report-tracking-past-due']}
            />
          )}
          {upcomingOpReqs.length > 0 && upcoming && totalOpReqs > 3 &&(
            <ReportStatusCircle
              classNameProp={styles['report-tracking-upcoming']}
            />
          )}
          {receivedOpReqs.length > 0 && received && totalOpReqs > 3 &&(
            <ReportStatusCircle
              classNameProp={styles['report-tracking-received']}
            />
          )}
          {completedOpReqs.length > 0 && completed && totalOpReqs > 3 && (
            <ReportStatusCircle
              classNameProp={styles['report-tracking-completed']}
            />
          )}
          {totalOpReqs > 0 && (
            <>
              <p className={styles['total-reports-num']}>
                {totalOpReqs > 3 && totalOpReqs}
              </p>
              {
                totalOpReqs <= 3 &&
                opTitles
              }
              { totalOpReqs > 3 && 
                <p>Trackers</p>
              }
            </>
          )}
        </div>
      </>
    );
  };

  const renderCells = () => {
    // Get the start/end date of this the current month;
    // May start from end/start of last/next month
    // Get the start/end date of each week
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);
    const dateFormat = 'd';
    const rows = [];
    let days = [];
    let day = startDate;
    let formattedDate = '';
    const { dueReports } = props;

    // Only activate the day within this month
    // Only select the day is selected
    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const numOfDueOpReqs = dueReports[formattedDate].length;
        let typedOpReqs = dueReports[formattedDate];
        if (displayReports && !displayMilestones) {
          typedOpReqs = dueReports[formattedDate].filter(
            opReq => opReq[1].tracker_type === 'report'
          );
        } else if (!displayReports && displayMilestones) {
          typedOpReqs = dueReports[formattedDate].filter(
            opReq => opReq[1].tracker_type === 'milestone'
          );
        } else if (!displayReports && !displayMilestones) {
          typedOpReqs = [];
        }

        let filteredStatuses = [];
        if (pastDue) {
          filteredStatuses.push('past_due');
        }
        if (completed) {
          filteredStatuses.push('completed');
        }
        if (received) {
          filteredStatuses.push('received');
        }
        if (upcoming) {
          filteredStatuses.push('upcoming');
        }
        let filteredOpReqsForTheDay = typedOpReqs.filter(opReq =>
          filteredStatuses.includes(opReq[0])
        );

        let selectedCommittees = committees
          .filter(com => com.show)
          .map(c => c.committee_name);
        filteredOpReqsForTheDay = filteredOpReqsForTheDay.filter(opReqs => {
          let opReqCommittees = opReqs[1].committees;
          for (let i = 0; opReqCommittees.length > i; i++) {
            if (
              selectedCommittees.includes(opReqCommittees[i].committee_name)
            ) {
              return true;
            }
          }
          return false;
        });

        days.push(
          <div
            style={
              props.dueReports[formattedDate].length > 0
                ? { cursor: 'pointer' }
                : {}
            }
            className={
              !isSameMonth(day, monthStart)
                ? styles['colCellDisabled']
                : isSameDay(day, selectedDate)
                ? styles['colCellSelected']
                : styles['colCell']
            }
            key={'d'+day}
            onClick={() =>
              onDateClick(toDate(cloneDay), filteredOpReqsForTheDay)
            }>
            <span className={styles['cellNumber']}>{formattedDate}</span>
            <span className={styles['bg']}>{formattedDate}</span>
            {day >= monthStart &&
              day <= monthEnd &&
              numOfDueOpReqs > 0 &&
              renderOpReqsForTheDay(filteredOpReqsForTheDay, formattedDate)}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className={styles['temp-row']} key={day}>
          {days}
        </div>
      );
      days = [];
    }

    return <div className={styles['row-container']}>{rows}</div>;
  };

  const onDateClick = (day, filteredOpReqs) => {
    if (filteredOpReqs.length > 0) {
      setSelectedDate(day);
      filteredOpReqs = filteredOpReqs.map(opReq => {
        let newOp = opReq[1];
        newOp['op_status'] = opReq[0];
        return newOp;
      });
      props.selectTargetDayForReports(filteredOpReqs);
    }
  };

  const updateCalendar = (action = 'today') => {
    let newCurrentMonth;
    switch (action) {
      case 'previous':
        newCurrentMonth = subMonths(currentMonth, 1);
        break;
      case 'next':
        newCurrentMonth = addMonths(currentMonth, 1);
        break;
      default:
        newCurrentMonth = new Date();
        break;
    }
    props.getReportsDueCurrentMonthAndYear(
      newCurrentMonth.getMonth() + 1,
      newCurrentMonth.getFullYear()
    );
    setCurrentMonth(newCurrentMonth);
    setInputMonth(newCurrentMonth.getMonth());
    setInputYear(newCurrentMonth.getFullYear());
  };

  return (
    <>
      {!props.isLoading && (
        <div className={`${styles['report-filters']}`}>
          {renderHeader()}
          {renderDays()}
          <div>{renderCells()}</div>
        </div>
      )}
      {props.displayMode === 'calendar' && (
        <ReportServicePopup
          params={props.params}
          currentMode='calendar'
          selectedDay={selectedDate}
          switched={props.switched}
        />
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    allCommittees: state.userReducer.committees.filter(
      com => com.committee_name !== 'Unassigned'
    ),
    dueReports: state.reportTrackingReducer.dueReports,
    isLoading: state.reportTrackingReducer.isLoading,
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps, actions)(Calendar);
