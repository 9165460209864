import React, { useState, useEffect } from 'react';
import styles from '../../styles/ls-request/UpdateLsRequestPopup.module.scss';
import { connect } from 'react-redux';
import { getFileAndDownload } from '../../actions/fileDownloadAction';
import {
  deleteFile,
  updateTargetLsRequest,
} from '../../actions/lsRequestAction';
import Modal from 'react-modal';
import parse from 'html-react-parser';
import Prompt from '../library/Prompt';
import { LsFields } from '../../services/LsFields';
import UserSelect from '../user/UserSelect';
import File from '../library/File';
import TagsDisplay from '../report-tracking/TagsDisplay';
import ToolTip from '../library/ToolTip';
import {
  formatDate,
  formatDateTime,
  acceptedFileTypes,
} from '../../utils/helper';
import {
  adminRoles,
  cmNonEditFields,
  fourthTierRoles,
  nonLegDivisionRoles,
  rolesUnderCouncilMembers,
  superAdminRoles,
} from '../../services/constants';
import {
  RightArrowTableHeaderIcon,
  ShortLeftArrowIcon,
  ExclamationPointDiamondIcon,
} from '../../services/SvgLibrary';

const numOfFieldsPerColumn = 7;
const numOfFieldsPerPart = numOfFieldsPerColumn * 2;

const UpdateLsRequestPopup = props => {
  /*  States  */
  const [curtLs, setCurtLs] = useState({
    attachment_descriptions: props.updateTargetLs.attachment_descriptions,
    background_existing_laws: props.updateTargetLs.background_existing_laws,
    background_inspiration: props.updateTargetLs.background_inspiration,
    bill_matrix: props.updateTargetLs.bill_matrix,
    bill: props.updateTargetLs.bill,
    cm_attachment: props.updateTargetLs.cm_attachment,
    cm_notes: props.updateTargetLs.cm_notes,
    committee: props.updateTargetLs.committee
      ? props.updateTargetLs.committee.committee_name
      : '',
    confidential_comments: props.updateTargetLs.confidential_comments,
    contact_person: props.updateTargetLs.contact_person
      ? props.updateTargetLs.contact_person.full_name
      : '',
    contact_person_focus: false,
    coversheet: props.updateTargetLs.coversheet,
    created_at: props.updateTargetLs.created_at,
    date_received: props.updateTargetLs.date_received,
    description_legislative_solution:
      props.updateTargetLs.description_legislative_solution,
    description_problem: props.updateTargetLs.description_problem,
    description_related_discussions:
      props.updateTargetLs.description_related_discussions,
    division: props.updateTargetLs.division,
    first_in_time: props.updateTargetLs.first_in_time,
    first_prime: props.updateTargetLs.first_prime
      ? props.updateTargetLs.first_prime.full_name
      : '',
    first_prime_focus: false,
    hearing_report: props.updateTargetLs.hearing_report,
    hearing_summary: props.updateTargetLs.hearing_summary,
    last_activated: props.updateTargetLs.last_activated,
    legal_memo: props.updateTargetLs.legal_memo,
    ls_number: props.updateTargetLs.ls_number,
    ls_type: props.updateTargetLs.ls_type,
    make_first_prime_public: props.updateTargetLs.make_first_prime_public,
    matters: props.updateTargetLs.matters,
    plain_language_summary: props.updateTargetLs.plain_language_summary,
    reintroduction:
      props.updateTargetLs.reintroduction === '' ||
      props.updateTargetLs.reintroduction === null
        ? 'None'
        : props.updateTargetLs.reintroduction,
    operational_requirement: props.updateTargetLs.operational_requirement,
    resource_letter: props.updateTargetLs.resource_letter,
    staff: props.updateTargetLs.staff
      ? props.updateTargetLs.staff.full_name
      : '',
    staff_focus: false,
    status: props.updateTargetLs.status,
    submitted_by: props.updateTargetLs.submitted_by
      ? props.updateTargetLs.submitted_by.full_name
      : '',
  });
  const [errorField, setErrorField] = useState({});
  const [errorMessage, setErrorMessage] = useState([]);
  const [promptOpen, setPromptOpen] = useState(false);
  const [reviewPromptOpen, setReviewPromptOpen] = useState(false);
  // const [errors, setErrors] = useState([])
  // const [file_error_message, setFile_error_message] = useState("")
  // const [file_limit_exceeded, setFile_limit_exceeded] = useState("")
  const [inspirationType, setInspirationType] = useState('Select Type');
  // const [lsFieldList, setLsFieldList] = useState(JSON.parse(JSON.stringify(LsFields.filter(field => !(nonLegDivisionRoles.includes(props.userProfile.role) && field.viewability === "staffOnly") && field.show_update))))
  const lsFieldList = JSON.parse(
    JSON.stringify(
      LsFields.filter(
        field =>
          !(
            nonLegDivisionRoles.includes(props.userProfile.role) &&
            field.viewability === 'staffOnly'
          ) && field.show_update
      )
    )
  );
  const [newFields, setNewFields] = useState({
    bill_matrix: [],
    bill: [],
    cm_attachment: [],
    coversheet: [],
    hearing_report: [],
    hearing_summary: [],
    legal_memo: [],
    plain_language_summary: [],
    resource_letter: [],
  });
  const permision_required_statuses = [
    'Recommending Withdrawal',
    'Specific Additional Information Requested',
    'Staff Hold',
    'Resource Letter',
    'Low-Priority Hold',
  ];
  /* End states */

  /**
   * useEffect hook for component mount
   */
  useEffect(() => {
    Modal.setAppElement('body');
    const { updateTargetLs } = props;
    let inspiration = updateTargetLs.background_inspiration;
    if (inspiration) {
      if (
        inspiration.includes('Hearing') ||
        inspiration.includes('Constituent complaint') ||
        inspiration.includes('News or law review article') ||
        inspiration.includes('Other')
      ) {
        let split = inspiration.split(':')[0];
        split = split.substring(split.lastIndexOf(' ') + 1);
        const backend_inspiration_field = LsFields.find(
          item => item.field === 'background_inspiration'
        );
        const inspirationTypes = Object.keys(backend_inspiration_field.help);
        setInspirationType(inspirationTypes.find(key => key.includes(split)));
      } else {
        setInspirationType('');
      }
    }
  }, []);
  /** End component mount useEffect hook */

  const scrollToTheTop = () => {
    document
      .getElementsByClassName(styles['update-ls-request-body'])[0]
      .scrollTo(0, 0);
  };

  const legistarLinkValidation = link => {
    let valid = true;
    if (
      /^(http|https):\/\/legistar\.council\.nyc\.gov\/LegislationDetail\.aspx\?/im.test(
        link
      )
    ) {
      let legistar_link = new URL(link);
      if (
        !legistar_link.searchParams.get('ID') ||
        !legistar_link.searchParams.get('GUID')
      ) {
        valid = false;
      }
    } else {
      valid = false;
    }
    return valid;
  }

  const checkValidation = pageOrAll => {
    let correctInput = true;
    let lsArr = lsFieldList;

    const users = props.userList.map(user => user.full_name);
    let errors = {};
    let errorArray = [];

    for (let i = 0; i < lsArr.length; i++) {
      if (lsArr[i].changable) {
        let value = curtLs[lsArr[i].field];
        if (
          lsArr[i].required &&
          (value === '' || (Array.isArray(value) && value.length === 0))
        ) {
          correctInput = false;
          errorArray.push(lsArr[i].title);
          errors[lsArr[i].field] = 'Required fields (*) cannot be empty!';
        } else if (lsArr[i].field === 'matters') {
          value.forEach(ll=>{
            if(ll['link']) {
              let valid = legistarLinkValidation(ll['link'])
              if(!valid) {
                errorArray.push(lsArr[i].title);
                correctInput = false;
                errors[lsArr[i].field] = "URL must be the full legislation link from Legistar (e.g., 'https://legistar.council.nyc.gov/LegislationDetail.aspx?…')";;
              }
            }
          })
        } else if (lsArr[i].field === 'status') {
          let oldLsStatus = props.updateTargetLs['status'];
          let matters = curtLs['matters'];
          let sentCheck = false;

          if (
            oldLsStatus !== 'Sent to CM' &&
            (value === 'Sent to CM' || curtLs['new_status'] === 'Sent to CM') &&
            !reviewPromptOpen
          ) {
            setReviewPromptOpen(true);
            sentCheck = true;
            correctInput = false;
          }
          if (
            props.updateTargetLs['new_status'] &&
            oldLsStatus !== curtLs['status']
          ) {
            if (
              props.updateTargetLs['new_status_requestor'].id !==
              props.userProfile.id
            ) {
              errorArray.push(lsArr[i].title);
              correctInput = false;
              console.log('status request',props.updateTargetLs['new_status_requestor'])
              errors[lsArr[i].field] =
                'There is already a request for changing the status pending approval';
            }
          } else if (
            oldLsStatus !== 'Enacted' &&
            value === 'Enacted' &&
            matters.length === 0
          ) {
            errorArray.push(lsArr[i].title);
            correctInput = false;
            errors[lsArr[i].field] =
              'To change status to Enacted a legistar URL is required';
          } else if (
            oldLsStatus !== curtLs['status'] &&
            permision_required_statuses.includes(curtLs['status'])
          ) {
            // Check to see that if the status was changed to one that requires approval
            let justUnassigned = false;
            for (let i = 0; props.userProfile.division.length > i; i++) {
              if (
                props.userProfile.division[i]['division_name'] !== 'Unassigned'
              ) {
                justUnassigned = true;
              }
            }
            if (!justUnassigned) {
              errorArray.push(lsArr[i].title);
              correctInput = false;
              errors[lsArr[i].field] =
                'To change to this status you cannot be an Unassigned Division';
            } else {
              if (
                !props.userProfile.permissions.lsRequest_api
                  .approve_status_change
              ) {
                if (!promptOpen && !pageOrAll) {
                  correctInput = false;
                  setPromptOpen(true);
                }
              }
            }
          }
        } else {
          if (errorField[lsArr[i].field]) {
            errors[lsArr[i].field] = '';
          }
        }
      }
    }
    setErrorField(errors);
    setErrorMessage(errorArray);
    return correctInput;
  };

  const displayAllErrorFields = () => {
    if (errorMessage.length > 1) {
      let quoteErrors = errorMessage.slice(0, -1);
      quoteErrors = quoteErrors.map(error => {
        return (
          <>
            "<strong>{error}</strong>",
          </>
        );
      });
      return (
        <>
          {quoteErrors}and "<strong>{errorMessage.slice(-1)}</strong>"
        </>
      );
    } else {
      return (
        <>
          "<strong>{errorMessage[0]}</strong>"
        </>
      );
    }
  };

  const onDeleteAttachment = (e, ls, field, id) => {
    props.deleteFile(ls, id, field);
    setCurtLs({
      ...curtLs,
      [field]: curtLs[field].filter(item => item.id !== id),
      uploadDeleted: true,
    });
    e.stopPropagation();
  };

  const onDeleteNewAttachment = (e, field, id) => {
    setNewFields({
      ...newFields,
      [field]: newFields[field].filter((f, index) => index !== id),
    });
    e.stopPropagation();
  };

  // Add the selected Local Law
  const onAddSelectedLocalLaw = selectedLocalLaw => {
    console.log('selected', selectedLocalLaw)
    const localLaws = curtLs['matters'];
    let hasDuplicate = false;
    for (let i = 0; i < localLaws.length; i++) {
      let check = false;
      if (localLaws[i]['link']) {
        check = selectedLocalLaw['intro'].includes(localLaws[i]['link']);
      } else {
        check =
          localLaws[i]['intro'].includes(selectedLocalLaw['intro']) ||
          selectedLocalLaw['intro'].includes(localLaws[i]['intro']);
      }
      if (check) {
        hasDuplicate = true;
        return false;
      }
    }
    if (!hasDuplicate) {
      localLaws.push(selectedLocalLaw);
    }
    setCurtLs({ ...curtLs, matters: localLaws });
    return true;
  };
  
  // Delete the selected Local Law
  const onDeleteTargetLocalLaw = targetIdx => {
    const localLaw = curtLs['matters'];
    let newLocalLaws = [];
    for (let i = 0; i < localLaw.length; i++) {
      if (i !== targetIdx) {
        newLocalLaws.push(localLaw[i]);
      }
    }
    setCurtLs({ ...curtLs, matters: newLocalLaws });
  };

  // Send out HTTP request to update the info
  const onUpdate = () => {
    let validation = checkValidation(false);
    if (validation) {
      let { allUserList, committeeList, updateTargetLsRequest } = props;
      let lsRequest = Object.assign({}, curtLs, {
        background_inspiration: inspirationType,
        bill_matrix: newFields['bill_matrix'],
        bill: newFields['bill'],
        cm_attachment: newFields['cm_attachment'],
        committee: curtLs.committee
          ? committeeList.find(
              committee => committee.committee_name === curtLs.committee
            ).id
          : null,
        contact_person: allUserList.find(
          cmStaffer => cmStaffer.full_name === curtLs.contact_person
        ).id,
        coversheet: newFields['coversheet'],
        first_prime: allUserList.find(
          user => user.full_name === curtLs.first_prime
        ).id,
        hearing_report: newFields['hearing_report'],
        hearing_summary: newFields['hearing_summary'],
        legal_memo: newFields['legal_memo'],
        plain_language_summary: newFields['plain_language_summary'],
        resource_letter: newFields['resource_letter'],
        staff: allUserList.find(staff => staff.full_name === curtLs.staff).id,
        status: curtLs['status'],
      });
      setErrorMessage([]);
      updateTargetLsRequest(lsRequest);
    }
    scrollToTheTop();
  };

  const generateForm = lsRequestFields => {
    const isCMRelated = rolesUnderCouncilMembers.includes(
      props.userProfile.role
    );
    return lsRequestFields.map((ls, idx) => {
      let userType;
      if (ls.field === 'contact_person') {
        userType = 'cm_staff';
      } else if (ls.field === 'first_prime') {
        userType = 'council members';
      } else {
        userType = 'staff';
      }
      let cmCantEdit = cmNonEditFields.includes(ls.field) && isCMRelated;
      let formattedDate = '';
      if (ls.type === 'date' && curtLs[ls.field]) {
        formattedDate = curtLs[ls.field].match('^[0-9]{4}-[0-9]{2}-[0-9]{2}$')
          ? formatDate(curtLs[ls.field])
          : formatDateTime(curtLs[ls.field]);
      }
      if (typeof curtLs[ls.field] === "string") curtLs[ls.field] = curtLs[ls.field].replace(/<\s*strong[^>]*\s*>|<\s*\/\s*strong\s*>/gi, '');
      //Overwrite the committee options with the data from the backend
      if (ls.field === 'committee')
        ls.options = [
          'Select a Committee',
          ...props.committeeList.map(committee => committee.committee_name),
        ];
      const title = ls.required ? `${ls.title}*` : ls.title;
      return (
        <div className='mb-3' key={idx} style={{ position: 'relative' }}>
          {![
            'file',
            'custom',
            'textarea',
            'date',
            'checkbox',
            'hideUpdate',
            'multi-select',
            'select',
          ].includes(ls.type) &&
            curtLs[ls.field] !== undefined &&
            ls.field !== 'prev_sess_ls_number' && (
              <>
                <label className={`form-label ${styles['input-form-title']}`}>
                  {title}
                  {ls.viewability === 'staffOnly' ? (
                    <span className={styles['not-visible-to-cm']}>
                      &nbsp;(Not visible to CMs)
                    </span>
                  ) : (
                    ''
                  )}
                </label>
                {ls.help && (
                  <ToolTip
                    id={ls.field}
                    target={ls.field}
                    text={
                      ls.help && typeof ls.help === 'object'
                        ? ls.help[inspirationType]
                        : ls.help
                    }
                  />
                )}
                <input
                  className='form-control form-control-lg field-size'
                  type={ls.type}
                  value={curtLs[ls.field]}
                  key={ls.title}
                  onChange={e => {
                    setCurtLs({ ...curtLs, [ls.field]: e.target.value });
                  }}
                  placeholder={
                    typeof ls.help === 'object'
                      ? ls.help[inspirationType]
                      : ls.help
                  }
                  disabled={!ls.changable || cmCantEdit}>
                  {ls.options &&
                    ls.options.map((op, idx1) => (
                      <option key={idx1}>{op}</option>
                    ))}
                </input>
                {errorField[ls.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[ls.field]}
                  </p>
                )}
              </>
            )}
          {ls.field === 'prev_sess_ls_number' && (
            <>
              <label className={`form-label ${styles['input-form-title']}`}>
                {title}
                {ls.viewability === 'staffOnly' ? (
                  <span className={styles['not-visible-to-cm']}>
                    &nbsp;(Not visible to CMs)
                  </span>
                ) : (
                  ''
                )}
              </label>
              {ls.help && (
                <ToolTip
                  id={ls.field}
                  target={ls.field}
                  text={
                    ls.help && typeof ls.help === 'object'
                      ? ls.help[inspirationType]
                      : ls.help
                  }
                />
              )}
              <input
                className='form-control form-control-lg field-size'
                type={ls.type}
                value={curtLs[ls.field]}
                key={ls.title}
                onChange={e => {
                  setCurtLs({ ...curtLs, [ls.field]: e.target.value });
                }}
                placeholder={
                  typeof ls.help === 'object'
                    ? ls.help[inspirationType]
                    : ls.help
                }
                disabled={!ls.changable || cmCantEdit}
              />
              {errorField[ls.field] && (
                <p className={styles['warning-message']}>
                  {errorField[ls.field]}
                </p>
              )}
            </>
          )}
          {ls.type === 'textarea' && curtLs[ls.field] !== undefined && (
            <>
              <label className={`form-label ${styles['input-form-title']}`}>
                {title}
                {ls.viewability === 'staffOnly' ? (
                  <span className={styles['not-visible-to-cm']}>
                    &nbsp;(Not visible to CMs)
                  </span>
                ) : (
                  ''
                )}
              </label>
              <textarea
                className='form-control form-control-lg field-size'
                rows='6'
                value={parse(curtLs[ls.field])}
                onChange={e => {
                  setCurtLs({ ...curtLs, [ls.field]: e.target.value });
                }}
                placeholder={
                  typeof ls.help === 'object'
                    ? ls.help[inspirationType]
                    : ls.help
                }
                disabled={!ls.changable || cmCantEdit}
              />
            </>
          )}
          {ls.type === 'select' && curtLs[ls.field] !== undefined && (
            <>
              <label className={`form-label ${styles['input-form-title']}`}>
                {title}
                {ls.viewability === 'staffOnly' ? (
                  <span className={styles['not-visible-to-cm']}>
                    &nbsp;(Not visible to CMs)
                  </span>
                ) : (
                  ''
                )}
              </label>
              {ls.help && (
                <ToolTip
                  id={ls.field}
                  target={ls.field}
                  text={
                    ls.help && typeof ls.help === 'object'
                      ? ls.help[inspirationType]
                      : ls.help
                  }
                />
              )}
              <select
                className='form-control form-control-lg field-size'
                type={ls.type}
                value={curtLs[ls.field]}
                key={ls.title}
                onChange={e => {
                  if (ls.field === 'operational_requirement') {
                    setCurtLs({
                      ...curtLs,
                      [ls.field]: e.target.value === 'true',
                    });
                  } else if (ls.field === 'reintroduction') {
                    setCurtLs({
                      ...curtLs,
                      [ls.field]:
                        e.target.value === 'true'
                          ? true
                          : e.target.value === 'false'
                          ? false
                          : e.target.value,
                    });
                  } else {
                    setCurtLs({ ...curtLs, [ls.field]: e.target.value });
                  }
                }}
                placeholder={
                  typeof ls.help === 'object'
                    ? ls.help[inspirationType]
                    : ls.help
                }
                disabled={!ls.changable || cmCantEdit}>
                {ls.options &&
                  ls.options.map((op, idx1) => {
                    if (
                      [
                        'Select a Committee',
                        'Select a Division',
                        'Select an LSR Type',
                        'Select a Status',
                        'Select one',
                      ].includes(op)
                    ) {
                      return (
                        <option key={idx1} value={null} hidden>
                          {op}
                        </option>
                      );
                    }
                    if (ls.field === 'operational_requirement') {
                      return (
                        <option key={idx1} value={op === 'Yes'}>
                          {op}
                        </option>
                      );
                    }
                    if (ls.field === 'reintroduction') {
                      return (
                        <option
                          key={idx1}
                          value={
                            op === 'N/A' ? 'None' : op === 'Yes' ? true : false
                          }>
                          {op}
                        </option>
                      );
                    }
                    if (
                      ls.field === 'status' &&
                      op === 'Withdrawn - Former CM' &&
                      ![
                        ...superAdminRoles,
                        ...adminRoles,
                        ...fourthTierRoles,
                      ].includes(props.userProfile.role)
                    ) {
                      return (
                        <option key={idx1} disabled>
                          {op}
                        </option>
                      );
                    }
                    return <option key={idx1}>{op}</option>;
                  })}
              </select>
              {errorField[ls.field] && (
                <p className={styles['warning-message']}>
                  {errorField[ls.field]}
                </p>
              )}
            </>
          )}
          {
            // Only for "Matters"
            ls.type === 'multi-select' && curtLs[ls.field] !== undefined && (
              <div className={styles['checkbox-display']}>
                <label className={`form-label ${styles['input-form-title']}`}>
                  {title}
                </label>
                {ls.help && (
                  <ToolTip id={ls.field} target={ls.field} text={ls.help} />
                )}
                {
                  ls.field === 'matters' && (
                    <TagsDisplay
                        reference={React.createRef()}
                        currentList={curtLs[ls.field]}
                        warningMessage='No matters have been selected.'
                        param={'intro'}
                        dropdownList={[]}
                        disabled={!ls.changable || cmCantEdit}
                        placeholder={ls.help}
                        noList={true}
                        onAddTarget={selected=>onAddSelectedLocalLaw(selected)}
                        onDeleteTarget={selected=>onDeleteTargetLocalLaw(selected)}
                      />
                  )
                }
                {errorField[ls.field] && (
                  <p className={styles['warning-message']}>
                    {errorField[ls.field]}
                  </p>
                )}
              </div>
            )
          }
          {ls.type === 'checkbox' && curtLs[ls.field] !== undefined && (
            <div className='form-check'>
              <input
                type='checkbox'
                className={`form-check-input ${styles['checkbox']}`}
                checked={curtLs[ls.field]}
                key={ls.title}
                onChange={e => {
                  setCurtLs({ ...curtLs, [ls.field]: !curtLs[ls.field] });
                }}
                disabled={
                  ls.field === 'make_first_prime_public'
                    ? isCMRelated && props.updateTargetLs[ls.field]
                    : !ls.changable || cmCantEdit
                }></input>
              <label className={`form-confirm-label ${styles['ls-title']}`}>
                {title}&nbsp;
                {ls.viewability === 'staffOnly' ? (
                  <span className={styles['not-visible-to-cm']}>
                    &nbsp;(Not visible to CMs)
                  </span>
                ) : (
                  ''
                )}
                {ls.field === 'make_first_prime_public' && (
                  <>
                    <br />
                    <small>
                      <sup>*</sup> Checking the box permits sharing your
                      sponsorship and LSR information with other Council Members
                      who have a similar LSR for the purpose of facilitating
                      co-sponsorship.
                    </small>
                  </>
                )}
              </label>
              {errorField[ls.field] && (
                <p className={styles['warning-message']}>
                  {errorField[ls.field]}
                </p>
              )}
            </div>
          )}

          {ls.type === 'date' && curtLs[ls.field] !== undefined && (
            <>
              <label className={`form-label ${styles['ls-title']}`}>
                {title}
                {ls.viewability === 'staffOnly' ? (
                  <span className={styles['not-visible-to-cm']}>
                    &nbsp;(Not visible to CMs)
                  </span>
                ) : (
                  ''
                )}
              </label>
              <input
                className='form-control form-control-lg field-size'
                type='text'
                value={formattedDate}
                key={ls.title}
                onChange={e => {
                  setCurtLs({ ...curtLs, [ls.field]: e.target.value });
                }}
                disabled={!ls.changable || cmCantEdit}>
                {ls.options &&
                  ls.options.map((op, idx1) => (
                    <option key={idx1}>{op}</option>
                  ))}
              </input>
              {errorField[ls.field] && (
                <p className={styles['warning-message']}>
                  {errorField[ls.field]}
                </p>
              )}
            </>
          )}
          {ls.type === 'custom' && curtLs[ls.field] !== undefined && (
            <>
              <label className={`form-label ${styles['ls-title']}`}>
                {title}
                {ls.viewability === 'staffOnly' ? (
                  <span className={styles['not-visible-to-cm']}>
                    &nbsp;(Not visible to CMs)
                  </span>
                ) : (
                  ''
                )}
              </label>
              <input
                className='form-control form-control-lg field-size'
                type='text'
                value={curtLs[ls.field]}
                key={ls.title}
                onChange={(e) => {setCurtLs({...curtLs, [ls.field]: e.target.value })}}
                onFocus={() => {
                  setCurtLs({ ...curtLs, [`${ls.field}_focus`]: true });
                }}
                onBlur={(e) => { e.target.value = '' }}
                disabled={!ls.changable || cmCantEdit}
              />
              <UserSelect
                search={curtLs[ls.field]}
                typeOfUsers={userType}
                handleClick={e => {
                  if (ls.field === 'first_prime') {
                    setCurtLs({
                      ...curtLs,
                      [ls.field]: e.target.innerText,
                      contact_person: '',
                      [`${ls.field}_focus`]: false,
                    });
                  } else {
                    setCurtLs({
                      ...curtLs,
                      [ls.field]: e.target.innerText,
                      [`${ls.field}_focus`]: false,
                    });
                  }
                }}
                sponsor={
                  curtLs['first_prime']
                    ? props.allUserList.find(
                        user => user.full_name === curtLs['first_prime']
                      )?.full_name
                    : ''
                }
                focused={curtLs[`${ls.field}_focus`]}
              />
              {errorField[ls.field] && (
                <p className={styles['warning-message']}>
                  {errorField[ls.field]}
                </p>
              )}
            </>
          )}
          {ls.type === 'file' && curtLs[ls.field] !== undefined && (
            <>
              <label className={`form-label ${styles['ls-title']}`}>
                {title}
                {ls.viewability === 'staffOnly' ? (
                  <span className={styles['not-visible-to-cm']}>
                    &nbsp;(Not visible to CMs)
                  </span>
                ) : (
                  ''
                )}
              </label>
              <input
                className='form-control form-control-lg field-size'
                type='file'
                accept={acceptedFileTypes}
                key={ls.title}
                onChange={e => {
                  let error_messages = [];
                  for (let i = 0; i < e.target.files.length; i++) {
                    // If the file size is more than 250MB do not input
                    if (e.target.files[i].size > 250000000) {
                      error_messages.push(
                        e.target.files[i].name + ' is too large'
                      );
                    }
                  }
                  if (error_messages.length) {
                    e.target.value = null;
                    // setFile_limit_exceeded(ls.field);
                    // setFile_error_message(error_messages.join(', '));
                  } else {
                    let files = e.target.files;

                    // let error = [];
                    for (let i = 0; i < files.length; i++) {
                      files[i].file_name = files[i].name;
                      // error.push( files[i].name + 'is too large');
                      let updatedField = [...newFields[ls.field], files[i]];
                      setNewFields({ ...newFields, [ls.field]: updatedField });
                    }
                  }
                }}
                multiple
                disabled={!ls.changable || cmCantEdit}></input>
              <br />
              {curtLs[ls.field].map((file, index) => (
                <File
                  id={`lsr_old_${ls.field}-${index}`}
                  file={file}
                  getFileAndDownload={props.getFileAndDownload}
                  key={index}
                  onDelete={e =>
                    onDeleteAttachment(e, curtLs, ls.field, file.id)
                  }
                  deletePermission={
                    props.userProfile.permissions.lsRequest_api
                      .change_lsrequest && !isCMRelated
                  }
                />
              ))}
              {newFields[ls.field] &&
                newFields[ls.field].map((file, index) => (
                  <File
                    id={`lsr_new_${ls.field}-${index}`}
                    newUpload={true}
                    file={{ file: file, ...file }}
                    getFileAndDownload={props.getFileAndDownload}
                    key={index}
                    onDelete={e => onDeleteNewAttachment(e, ls.field, index)}
                    deletePermission={true}
                  />
                ))}
              {errorField[ls.field] && (
                <p className={styles['warning-message']}>
                  {errorField[ls.field]}
                </p>
              )}
              <br />
            </>
          )}
        </div>
      );
    });
  };

  let filteredFields = lsFieldList.filter(
    lsr =>
      !['overlapping_ls', 'related_ls', 'duplicate_check'].includes(lsr.field)
  );
  let splitField = Math.floor(filteredFields.length / 2);
  const firstColumn = filteredFields.slice(0, splitField);
  const secondColumn = filteredFields.slice(splitField);

  return (
    <>
      {/* UPDATE LSR HEADER */}
      <div
        className={`d-flex justify-content-between align-items-center p-3 border-bottom fs-5 fw-bolder
        ${
          props.updateTargetLsStatus === 'success'
            ? `${styles['update-ls-request-header-container']} ${styles['sucess']}`
            : styles['update-ls-request-header-container']
        }`}>
        <span>Update LS Request</span>
        <button
          type='button'
          className='btn-close p-2 rounded-3'
          onClick={props.onClose}
          aria-label='Close'></button>
      </div>
      {/* END UPDATE LSR HEADER */}

      {/* UPDATE LSR FORM BODY */}
      <div
        className={`d-flex flex-column align-items-center overflow-auto p-4
         ${
           props.updateTargetLsStatus === 'success'
             ? `${styles['update-ls-request-body']} ${styles['success']}`
             : `${styles['update-ls-request-body']}`
         }`}>
        {/* FAIL MESSAGE */}
        {props.updateTargetLsStatus === 'fail' && (
          <div
            className={`alert alert-danger ${styles['success-create-message']}`}
            role='alert'>
            <p>{props.errorMessage}</p>
          </div>
        )}
        {/* END FAIL MESSAGE */}

        {/* ERROR MESSAGE */}
        {errorMessage.length > 0 && (
          <div
            className={`alert alert-danger ${styles['error-message']}`}
            role='alert'>
            <div>
              <ExclamationPointDiamondIcon />
            </div>
            <div>
              {errorMessage.length < 4 && (
                <>
                  There was an error in the following field(s):
                  {displayAllErrorFields()}.
                </>
              )}
              {errorMessage.length >= 4 && (
                <>
                  Errors were found in
                  <strong style={{ letterSpacing: '1px' }}>
                    {errorMessage.length}
                  </strong>
                  fields.
                </>
              )}
            </div>
          </div>
        )}
        {/* END ERROR MESSAGE */}

        {/* UPDATE LSR SUCCESS MESSAGE */}
        {props.updateTargetLsStatus === 'success' && (
          <div className={styles['success-message-container']}>
            <div
              className={`alert alert-success ${styles['success-create-message']}`}
              role='alert'>
              <p>
                LSR #<b>{curtLs.ls_number}</b> has been updated!
              </p>
              <p>&nbsp;Page will refresh shortly.</p>
            </div>
          </div>
        )}
        {/* END UPDATE LSR SUCCESS MESSAGE */}

        <form className='row w-100 p-0'>
          <div className='col'>{generateForm(firstColumn)}</div>
          <div className='col'>{generateForm(secondColumn)}</div>
        </form>
      </div>
      {/* END UPDATE LSR FORM BODY */}
      {/* UPDATE LSR FORM FOOTER */}
      <Prompt
        message={
          <span>
            Are you sure you want to request changing to this status? The change
            will be applied once it is approved.
          </span>
        }
        isOpen={promptOpen}
        setIsOpen={setPromptOpen}
        onConfirmation={e => {
          onUpdate();
          setPromptOpen(false);
        }}
      />

      <Prompt
        message={
          <span>
            Status is being set to "Sent to CM". Are you sure there{' '}
            <strong style={{textDecoration: "underline"}}>
            {curtLs['operational_requirement'] ? "IS " : "IS NOT"}
            </strong>{' '}
            an operational requirement associated with this LSR?
          </span>
        }
        isOpen={reviewPromptOpen}
        setIsOpen={setReviewPromptOpen}
        onConfirmation={e => {
          onUpdate();
          setReviewPromptOpen(false);
        }}
      />

      <div className='d-flex align-items-center justify-content-end border-top p-3'>
        {props.updateTargetLsStatus !== 'success' && (
          <button className='cancel-button-text' onClick={props.onClose}>
            Cancel
          </button>
        )}
        <button
          className='submit-button'
          disabled={
            props.updateTargetLsStatus === 'working...' ||
            props.updateTargetLsStatus === 'success'
          }
          onClick={onUpdate}>
          {props.updateTargetLsStatus === 'working...' && (
            <span
              className='spinner-border spinner-border-sm'
              role='status'
              aria-hidden='true'></span>
          )}{' '}
          Save
        </button>
      </div>
      {/* END UPDATE LSR FORM FOOTER */}
      {props.updateTargetLsStatus !== 'success' && (
        <div className={styles['update-pop-up-container']}></div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    allUserList: state.userReducer.allUserList,
    userProfile: state.userReducer.userProfile,
    userList: state.userReducer.userList,
    committeeList: state.userReducer.committees,
    updateTargetLs: state.lsRequestsReducer.updateTargetLs,
    updateTargetLsStatus: state.lsRequestsReducer.updateTargetLsStatus,
    errorMessage: state.lsRequestsReducer.errorMessage,
  };
};

const mapDispatchToProps = {
  updateTargetLsRequest,
  getFileAndDownload,
  deleteFile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateLsRequestPopup);
