import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Prompt from './Prompt';
import styles from '../../styles/library/file.module.scss';
import { UncontrolledTooltip } from 'reactstrap';
import { TrashIcon, FileDownloadIcon } from '../../services/SvgLibrary';

const File = props => {
  const { file, getFileAndDownload, newUpload, highlighted, fileName} = props;
  const [isOpen, setIsOpen] = useState(false);
  //TODO find out what downloadStatus was suppose to do

  return (
    <div>
      <div id={`file-${props.id}`} className={`${styles['file']}${highlighted ? ` ${styles['highlighted']}` : ''}` }>
        <div className={styles['file-name-wrapper']}>
          <FileDownloadIcon
            classNameProp='download-icon'
            idProp={`file-image_${props.id}`} 
            onClickFunction={() => {
              if (!newUpload) getFileAndDownload(file.file);
            }}
            alt='download'
          />
          {fileName &&
            <UncontrolledTooltip
              placement='top'
              autohide={false}
              target={`file-${props.id}`}>
              {file['file_name']}
            </UncontrolledTooltip> 
          }
          <span
            id={`file-name-${props.id}`}
            style={{ cursor: newUpload ? 'default' : 'pointer' }}
            onClick={() => {
              if (!newUpload) getFileAndDownload(file.file);
            }}>
           {fileName ? fileName : file['file_name']}
          </span>
        </div>
        {props.onDelete && props.deletePermission && (
          <TrashIcon
            classNameProp={styles['trash']}
            onClickFunction={() => setIsOpen(true)}
          />
        )}
        <Prompt
          message='Are you sure you want to delete this attachment? This action cannot be undone.'
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          onConfirmation={e => {
            props.onDelete(e, file.id);
            setIsOpen(false);
          }}
        />
        {props.downloadStatus === false && (
          <span className={styles['warning-message']}>
            The target file is no longer available
          </span>
        )}
      </div>
      <Prompt
        message='Are you sure you want to delete this attachment? This action cannot be undone.'
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        onConfirmation={e => {
          props.onDelete(e, file.id);
          setIsOpen(false);
        }}
      />
      {props.downloadStatus === false && (
        <span className={styles['warning-message']}>
          The target file is no longer available
        </span>
      )}
    </div>
  );
};
File.propTypes = {
  file: PropTypes.shape({
    file: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    file_name: PropTypes.string.isRequired,
    timestamp: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  getFileAndDownload: PropTypes.func,
  deletePermission: PropTypes.bool,
  onDelete: PropTypes.func,
  downloadStatus: PropTypes.bool,
};
export default File;
