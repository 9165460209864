import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { connect } from 'react-redux';
import { flattenObject } from '../../../../utils/helper';
import { updateUserPreferences } from '../../../../actions/userAction';
import { userPreferenceLabels } from '../../../../services/constants';
import styles from '../../../../styles/user/UserProfile.module.scss';
import CheckboxMenu from '../../../library/CheckboxMenu';
import {ToolTipIcon} from '../../../../services/SvgLibrary';

const UserprofileEmailNotifPrefWidget = props => {
  const {
      updateUserPreferences,
      userProfile,
  } = props;

  // flattens the nested User Preference states for local use
  // from this => preferenceState:{[application]: {[category]: {[item]: {value: bool, visible: bool }}}}
  // to this => preferenceState: {[`${application}.${category}.${item}.value`]: bool}
  const [newReportCommittees, setNewReportCommittees] = useState([]);
  const [trackerReminderCommittees, setTrackerReminderCommittees] = useState([]);
  const [checkAllNewReportCommittees, setCheckAllNewReportCommittees] = useState(false);
  const [checkAllTrackerReminderCommittees, setCheckAllTrackerReminderCommittees] = useState(false);
  const flattenedUserPreferenceStates = useMemo(
    () => flattenObject(userProfile.preferences),
    [userProfile.preferences]
  );

  const [userPreferenceToggleStates, setUserPreferenceToggleStates] = useState(
    flattenedUserPreferenceStates
  );
  
  const [toastText, setToastText] = useState('');
  const [toastVisible, setToastVisible] = useState(false);

  useEffect(()=>{
    //On initialization turn on the show values of the existing committees
    setNewReportCommittees(
      convertCommitteesData(userProfile.preferences['emails']['operational_requirements']['new_report_uploaded']['committees'])
    );
    setTrackerReminderCommittees(
      convertCommitteesData(userProfile.preferences['emails']['operational_requirements']['send_tracker_reminders']['committees'])
    );
  }, [])

  useEffect(() => {
    if (toastVisible) {
      setTimeout(() => {
        setToastVisible(false);
      }, 3000); // autohide after 3 seconds
    }
  }, [toastVisible]);

  const toggleUserPreferenceSwitch = (application, subCatergory, item, dateCategory='') => {
    setUserPreferenceToggleStates(prevState => {
      let stateName = `${application}.${subCatergory}.${item}`;
      if(dateCategory) {
        stateName += `.${dateCategory}`;
      }
      const toggledState = !prevState[stateName];
      return {
        ...prevState,
        [stateName]: toggledState,
      };
    });
  };

  const sendUpdatedUserPreferences = () => {
    let updatedPreferences = userProfile.preferences;
    let application;
    let subCategory;
    let item;
    let arr;
    for (const key in userPreferenceToggleStates) {
      arr = key.split('.');
      application = arr[0];
      subCategory = arr[1];
      item = arr[2];
      // Save the committees as ids in preferences
      if(item === 'new_report_uploaded') {
        let showNewRepComs = newReportCommittees.filter((com)=>com.show);
        updatedPreferences[application][subCategory][item].committees = showNewRepComs.map((com)=>com.id);
        updatedPreferences[application][subCategory][item].value = userPreferenceToggleStates[key];
      } else if(item === 'send_tracker_reminders') {
        let showTrackRemComs = trackerReminderCommittees.filter((com)=>com.show);
        if(arr.length === 3) {
          updatedPreferences[application][subCategory][item].committees = showTrackRemComs.map((com)=>com.id);
          updatedPreferences[application][subCategory][item].value = userPreferenceToggleStates[key];
        } else {
          let dateCategory = arr[3];
          updatedPreferences[application][subCategory][item][dateCategory].value =
            userPreferenceToggleStates[key];
        }
      } else {
        updatedPreferences[application][subCategory][item].value =
          userPreferenceToggleStates[key];
      }

    }
    updateUserPreferences(
      userProfile.id,
      updatedPreferences,
      setToastText,
      setToastVisible
    );
  };

  const toggleNewReportCommittee = title => {
    setNewReportCommittees(prevCommittees =>
      prevCommittees.map(com =>
        com.committee_name === title ? { ...com, show: !com.show } : com
      )
    );
  };

  const toggleTrackerReminderCommittee = title => {
    setTrackerReminderCommittees(prevCommittees =>
      prevCommittees.map(com =>
        com.committee_name === title ? { ...com, show: !com.show } : com
      )
    );
  };
  const onCheckOrUncheckAllNewReportCheckbox = () => {
    setCheckAllNewReportCommittees(!checkAllNewReportCommittees);
    setNewReportCommittees(
      props.allCommittees.map(com => ({
        ...com,
        show: !checkAllNewReportCommittees,
      }))
    );
  };
  const onCheckOrUncheckAllTrackerReminderCheckbox = () => {
    setCheckAllTrackerReminderCommittees(!checkAllTrackerReminderCommittees);
    setTrackerReminderCommittees(
      props.allCommittees.map(com => ({
        ...com,
        show: !checkAllTrackerReminderCommittees,
      }))
    );
  };

  const convertCommitteesData = (committees) => {
    return props.allCommittees.map((com)=>{
      //For each committee convert to show value
      let show = false;
      if(committees.includes(com.id)) {
        show = true;
      }
      return {...com, show: show}
    })
  }
  
  return (
      <>
        <div
          className={`card border-0 rounded-3 ${styles['user-profile-pref-container']}`}>
          <div className='card-body'>
            <h2 className='mb-0'>User Preferences</h2>
            <table id={styles['profile-table']}>
              <tbody>
                {Object.entries(userPreferenceToggleStates).length &&
                  // Start of Preferences by Application
                  Object.entries(userPreferenceLabels).map(
                    ([appKey, appValue], id) => (
                      <Fragment key={`${appKey}${id}`}>
                        <tr key={appKey}>
                          <td>
                            <h4 className='mb-3 fs-5'>
                              {userPreferenceLabels[appKey].title}
                            </h4>
                            {Object.entries(
                              userPreferenceLabels[appKey].items
                            ).map(([categoryKey, categoryValue]) => {
                              const categoryIsVisible =
                                userProfile.preferences[appKey][categoryKey]
                                  .visible;
                              const accordianId = userPreferenceLabels[appKey].title.replace(' ', '_') + categoryValue.title.replace(' ', '_')
                              return (
                                categoryIsVisible && (
                                  // Where the Preference categories for Applications are rendered
                                  <div
                                    key={categoryKey}
                                    id={accordianId}
                                    className='accordion flex-column flex-xl-row gap-2 gap-xl-5 mb-4 border-bottom pb-3'>
                                    <h5 data-bs-toggle="collapse" data-bs-target={`#collapse${accordianId}`} aria-expanded="false" aria-controls={`collapse${accordianId}`} type='button'
                                      className='fw-bold fs-6 p-2 accordion-button collapsed'>
                                      {categoryValue.title}
                                    </h5>
                                    <ul
                                      style={{ listStyleType: 'none' }}
                                      className='ps-2 flex-fill'>
                                      {Object.entries(categoryValue.items).map(
                                        ([switchKey, switchLabel]) => {
                                          const switchIsVisible =
                                            userProfile.preferences[appKey][
                                              categoryKey
                                            ][switchKey].visible;
                                          // Where the preference toggle switches are rendered
                                          return (
                                            switchIsVisible && (
                                              <li id={`collapse${accordianId}`} className={`accordion-collapse collapse ${styles['preference-border']}`}data-bs-parent={`#${accordianId}`}
                                                 key={switchKey}>
                                                <div
                                                 className='d-flex justify-content-between'>
                                                  <label
                                                    className={`form-check-label me-2 ${styles['label-max-width']}`}
                                                    htmlFor={`${switchKey}-${switchLabel.heading}-switch`}>

                                                    <div className='p-2'>
                                                      {switchLabel.heading}
                                                      <span
                                                        data-bs-toggle='tooltip' data-bs-placement='top' title={switchLabel.description}
                                                      >
                                                        <ToolTipIcon
                                                          classNameProp={styles['user-guide-field-icon']}
                                                        />
                                                      </span>
                                                    </div>
                                                  </label>
                                                  <div className='form-check form-switch'>
                                                    <input
                                                      className={`form-check-input ${styles['user-email-pref-toggle']}`}
                                                      type='checkbox'
                                                      role='switch'
                                                      id={`${switchKey}-${switchLabel.heading}-switch`}
                                                      checked={
                                                        userPreferenceToggleStates[
                                                          `${appKey}.${categoryKey}.${switchKey}`
                                                        ]
                                                      }
                                                      onChange={() => {
                                                        toggleUserPreferenceSwitch(
                                                          appKey,
                                                          categoryKey,
                                                          switchKey
                                                        )
                                                        //If the accordion is open when the switch is turned off close it
                                                        if(switchKey === 'new_report_uploaded') {
                                                          let newRepAccordion = document.getElementById("collapseNewReport");

                                                          if (newRepAccordion.classList.contains('show') && userPreferenceToggleStates[`${appKey}.${categoryKey}.${switchKey}`]) {
                                                            let newRepAccordionButton = document.getElementById("NewReportCommittees")
                                                            newRepAccordion.classList.remove('show')
                                                            newRepAccordionButton.classList.add('collapsed')
                                                          }
                                                        } else if(switchKey === 'send_tracker_reminders') {
                                                          let trackReminderAccordion = document.getElementById("collapseTrackerReminder");

                                                          if (trackReminderAccordion.classList.contains('show') && userPreferenceToggleStates[`${appKey}.${categoryKey}.${switchKey}`]) {
                                                            let trackReminderAccordionButton = document.getElementById("TrackerReminderCommittees")
                                                            trackReminderAccordion.classList.remove('show')
                                                            trackReminderAccordionButton.classList.add('collapsed')

                                                          }
                                                        }
                                                      }
                                                    }
                                                    />
                                                  </div>
                                                </div>
                                                {switchKey === 'new_report_uploaded' &&
                                                  <div className='accordion' id='NewReportAccordion'>
                                                    <div className={`accordion-header ${styles['accordian-style']}`} id='headingNewReport'>
                                                      
                                                      <button id='NewReportCommittees' className='accordion-button collapsed p-2' type='button' disabled={!userPreferenceToggleStates[
                                                          `${appKey}.${categoryKey}.${switchKey}`
                                                        ]}
                                                      data-bs-toggle='collapse' data-bs-target='#collapseNewReport' aria-expanded='false' aria-controls='collapseNewReport'>
                                                        Select Committees
                                                      </button>
                                                    </div>
                                                    <div id='collapseNewReport' aria-labelledby='headingNewReport' className='accordion-collapse collapse' data-bs-parent='#NewReportAccordion'>
                                                      <CheckboxMenu
                                                        idValue='NewReportCommittees'
                                                        componentType='div'
                                                        checkboxes={newReportCommittees}
                                                        columnNumber={2}
                                                        toggleCheckbox={toggleNewReportCommittee}
                                                        checkAll={checkAllNewReportCommittees}
                                                        toggleAllCheckboxes={onCheckOrUncheckAllNewReportCheckbox}
                                                        params='committee_name'
                                                      />
                                                    </div>
                                                  </div>
                                                }
                                                {switchKey === 'send_tracker_reminders' &&
                                                <>
                                                  {
                                                    Object.entries(switchLabel.items).map(
                                                      ([dateKey, dateLabel]) => {
                                                        if(!['value', 'visible', 'committees'].includes(dateKey)) {
                                                          return (
                                                            <div key={dateKey}
                                                              className='d-flex justify-content-between'>
                                                              <label
                                                                className={`form-check-label me-2 ${styles['label-max-width']}`}
                                                                htmlFor={`${dateKey}-${dateLabel.heading}-switch`}>
                                                                <div className={`p-2 ${styles['sub-label']}`}>
                                                                  {dateLabel.heading}
                                                                  <span data-bs-toggle='tooltip' data-bs-placement='top' title={dateLabel.description}>
                                                                    <ToolTipIcon classNameProp={styles['user-guide-field-icon']}/>
                                                                  </span>
                                                                </div>
                                                              </label>
                                                              <div className='form-check form-switch'>
                                                                <input
                                                                  className={`form-check-input ${styles['user-email-pref-toggle']}`}
                                                                  type='checkbox'
                                                                  role='switch'
                                                                  id={`${dateKey}-${dateLabel.heading}-switch`}
                                                                  checked={
                                                                    userPreferenceToggleStates[
                                                                      `${appKey}.${categoryKey}.${switchKey}.${dateKey}`
                                                                    ]
                                                                  }
                                                                  disabled={!userPreferenceToggleStates[`${appKey}.${categoryKey}.send_tracker_reminders`]}
                                                                  onChange={() => {
                                                                    toggleUserPreferenceSwitch(
                                                                      appKey,
                                                                      categoryKey,
                                                                      switchKey,
                                                                      dateKey
                                                                    )
                                                                  }
                                                                }
                                                                />
                                                              </div>
                                                            </div>
                                                          )
                                                        }
                                                    })
                                                  }
                                                  <div className='accordion' id='TrackerReminderAccordion'>
                                                    <div className={`accordion-header ${styles['accordian-style']}`} id='headingTrackerReminder'>

                                                      <button id='TrackerReminderCommittees' className='accordion-button collapsed p-1' type='button' data-bs-toggle='collapse' disabled={!userPreferenceToggleStates[
                                                          `${appKey}.${categoryKey}.${switchKey}`
                                                        ]} data-bs-target='#collapseTrackerReminder' aria-expanded='false' aria-controls='collapseTrackerReminder'
                                                      >
                                                        Select Committees
                                                      </button>
                                                    </div>
                                                    <div id='collapseTrackerReminder' aria-labelledby='headingTrackerReminder' className='accordion-collapse collapse' data-bs-parent='#TrackerReminderAccordion'>
                                                      <CheckboxMenu
                                                        idValue='TrackerReminderCommittees'
                                                        componentType='div'
                                                        columnNumber={2}
                                                        checkboxes={trackerReminderCommittees}
                                                        toggleCheckbox={toggleTrackerReminderCommittee}
                                                        checkAll={checkAllTrackerReminderCommittees}
                                                        toggleAllCheckboxes={onCheckOrUncheckAllTrackerReminderCheckbox}
                                                        params='committee_name'
                                                      />
                                                    </div>
                                                  </div>
                                                </>
                                                }
                                              </li>
                                            )
                                          );
                                        }
                                      )}
                                    </ul>
                                  </div>
                                )
                              );
                            })}
                          </td>
                        </tr>
                        {id < Object.keys(userPreferenceLabels).length - 1 && (
                          <tr>
                            <td colSpan='100%'>
                              <hr
                                style={{
                                  height: '0.09375rem',
                                  backgroundColor: 'black',
                                  color: 'black',
                                  border: 'none',
                                  opacity: '35%',
                                }}
                              />
                            </td>
                          </tr>
                        )}
                      </Fragment>
                    )
                  )}
                <tr>
                  <td className='text-end'>
                    <button
                      className='submit-button'
                      onClick={sendUpdatedUserPreferences}>
                      Save
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        {/* toast for User Preferences */}
        {toastVisible && (
          <div className='toast-container position-fixed bottom-0 end-0 p-3'>
            <div className='toast show'>
              <div className='toast-header'>
                <strong className='me-auto'>IWOMM</strong>
                <button
                  type='button'
                  className='btn-close'
                  onClick={() => setToastVisible(false)}></button>
              </div>
              <div className='toast-body'>{toastText}</div>
            </div>
          </div>
        )}
      </>
    );
};
const mapDispatchToProps = {
  updateUserPreferences,
};
const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    allCommittees: state.userReducer.committees.filter(
      com => com.committee_name !== 'Unassigned'
    ),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserprofileEmailNotifPrefWidget);