import React, { useState, useEffect, useRef  } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/report-tracking/CreateNewReportDdl.module.scss';
import ReportFields from '../../services/ReportFields';
import * as actions from '../../actions/reportTrackingAction';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';
import TagsDisplay from './TagsDisplay';
import {
  ToolTipIcon,
  ShortLeftArrowIcon,
  ExclamationPointDiamondIcon,
  LargeCheckMarkIcon,
} from '../../services/SvgLibrary';

function CreateNewReportDdl(props) {
  const [state, setState] = useState({
      opReq: {
        agency_contact: [],
        assigned_contact: [],
        attachment: [],
        committees: [],
        completed: undefined,
        current_report_past_due: undefined,
        division: '',
        end_date: '',
        frequency_quantity: 0,
        frequency_unit: '',
        general_notes: '',
        last_received: '',
        local_law: [],
        legacy_local_law: [],
        ls_requests: [],
        next_due: '',
        notes_on_due_date: '',
        path: '',
        start_date: '',
        submitting_agency: [],
        title: '',
        tracker_type: '',
      },
      end_date_required: false,
      errorField: {},
      errorsPresent: false,
      file_error_meassage: '',
      file_limit_exceeded: '',
      page: 0,
      refs: {},
  })

  const refs = useRef({});

  useEffect(() => {
    // create the reference for each of the field in the map
      registerRefs();
      if (props.savedProgress) {
        setState(prevState => ({ ...prevState, opReq: props.savedProgress }));
      }
      return () => {
        props.saveProgress(state.opReq);
      }}, [])

  const scrollToTheTop = (next) => {
    if ((next && state.page !== 0) || (!next && state.page !== 1)) {
      document
        .getElementsByClassName(styles['report-fields'])[0]
        .scrollTo(0, 0);
    }
  }

  // arr.reduce((accumulator, current)) to generate an object of refs
  const registerRefs = () => {
    let fields = ReportFields;
    if (state.opReq.tracker_type) {
      fields = ReportFields.filter(field => {
        return (
          field.tracker === 'Both' ||
          field.tracker === state.opReq.tracker_type
        );
      });
    }
    const refs = fields.reduce((acc, current) => {
      const ref = React.createRef();
      acc[current.field] = ref;
      return acc;
    }, {});
    setState(prevState =>
      ({
        ...prevState,
        refs: refs 
      }));
  };

  const onSelectTrackerType = e => {
    scrollToTheTop(true);
    setState(prevState => ({
      ...prevState,
      opReq: { ...state.opReq, ['tracker_type']: e.target.value },
      page: prevState.page + 1,
      errorsPresent: false 
    }));
  };

  // Validate input fields
  const checkFieldValidation = (fields) => {
    let isValid = true;
    let errors = {};
    let isOnce = state.opReq['frequency_unit'] === 'Once';
    let isMilestone = state.opReq.tracker_type === 'Milestone';
    let start,
      next,
      end = false;

    //Date Error Validation start <= next <= end
    if (!isOnce && !isMilestone) {
      start = state.opReq['start_date'] <= state.opReq['next_due'];
      next = start;
      end = state.opReq['end_date'] ? true : false;

      if (end) {
        if (
          start &&
          state.opReq['end_date'] < state.opReq['start_date']
        ) {
          start = false;
          end = false;
        }
        if (
          next &&
          state.opReq['end_date'] < state.opReq['next_due']
        ) {
          next = false;
          end = false;
        }
      }
    }

    for (let i = 0; i < fields.length; i++) {
      const curt = fields[i];
      //If the current field is required and if it is empty or an empty array
      if (
        curt.required &&
        (state.opReq[curt.field] === 'None' ||
          (typeof state.opReq[curt.field] !== 'boolean' &&
            !state.opReq[curt.field]) ||
          (Array.isArray(state.opReq[curt.field]) &&
            state.opReq[curt.field].length < 1))
      ) {
        let nonPeriodUnits = ['Once', 'As Needed'].includes(
          state.opReq['frequency_unit']
        );
        let removedRequired =
          (isOnce && curt.field === 'next_due') ||
          (isMilestone &&
            ['frequency_unit', 'frequency_quantity', 'next_due'].includes(
              curt.field
            ));
        if (
          !(curt.field === 'frequency_quantity' && nonPeriodUnits) &&
          !removedRequired
        ) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message': 'Required fields (*) cannot be empty!',
          };
          isValid = false;
        }
      }
      if (['local_law', 'ls_requests', 'legacy_local_law'].includes(curt.field)) {
        if (
          state.opReq.local_law.length === 0 &&
          state.opReq.ls_requests.length === 0 &&
          state.opReq.legacy_local_law.length === 0
        ) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message':
              'At least one local law (Legistar link), legacy local law number or LSR number must be submitted.',
          };
          isValid = false;
        }

        for (let i = 0; i < state.opReq['local_law'].length; i++) {
          let ll = state.opReq['local_law'][i];
          if (!ll['link']) {
            if (
              /^(http|https):\/\/legistar\.council\.nyc\.gov\/LegislationDetail\.aspx\?/im.test(
                ll['law_number']
              )
            ) {
              let legistar_link = new URL(ll['law_number']);
              if (
                !legistar_link.searchParams.get('ID') ||
                !legistar_link.searchParams.get('GUID')
              ) {
                isValid = false;
              }
            } else {
              errors['local_law'] = {
                'is_invalid': true,
                'error_message': 'Invalid legistar legistar link input.',
              };
              isValid = false;
            }
          }
        }
      }
      if (!isOnce && !isMilestone) {
        if (curt.field === 'start_date' && !start) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message':
              'The sxtart date is later than the next due and/or the end date.',
          };
          isValid = false;
        } else if (curt.field === 'next_due' && !next) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message':
              'The next due date is earlier than the start date and/or later than the end date.',
          };
          isValid = false;
        } else if (
          curt.field === 'end_date' &&
          state.opReq[curt.field] &&
          !end
        ) {
          errors[curt.field] = {
            'is_invalid': true,
            'error_message':
              'The end date is earlier than the start date and/or later than the next due date.',
          };
          isValid = false;
        }
      }
    }
    setState(prevState => ({ 
      ...prevState,
      errorField: errors, 
      errorsPresent: !isValid
    }));
    return isValid;
  }

  // On click submit for sending out the request
  const onSubmit = e => {
    e.preventDefault();
    let ReportFieldsFiltered = [];
    if (state.opReq.frequency_unit === 'Once') {
      setState(prevState => ({
        ...prevState,
        opReq: { ...state.opReq, next_due: state.opReq.start_date },
      }));
      state.opReq['next_due'] = state.opReq['start_date'];
    }
    if (ReportFields) {
      ReportFieldsFiltered = ReportFields.filter(field => {
        return ![
          'last_received',
          'completed',
          'current_report_past_due',
        ].includes(field.field);
      });
    }
    let isValid = checkFieldValidation(ReportFieldsFiltered);
    if (isValid) {
      props.creatingNewReportDeadline(state.opReq);
    }
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state
  const onDeleteTargetCommittee = targetIdx => {
    let committees = state.opReq['committees'];
    committees.splice(targetIdx, 1);
    setState(prevState => ({
      ...prevState,
      opReq: { ...state.opReq, committees: committees } 
    }));
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedCommittee = selectedCommittee => {
    let hasDuplicate = false;
    const committees = state.opReq['committees'];
    for (let i = 0; i < committees.length; i++) {
      if (committees[i].committee_name === selectedCommittee.committee_name) {
        hasDuplicate = true;
        break;
      }
    }
    if (!hasDuplicate) {
      committees.push(selectedCommittee);
    }

    setState(prevState => ({ ...prevState, opReq: { ...state.opReq, committees: committees } }));
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state
  const onDeleteTargetContact = targetIdx => {
    let contacts = state.opReq['assigned_contact'];
    contacts.splice(targetIdx, 1);
    setState(prevState => ({
      ...prevState,
      opReq: { ...state.opReq, assigned_contact: contacts },
    }));
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedContact = selectedContact => {
    const contacts = state.opReq['assigned_contact'];
    let hasDuplicate = false;
    for (let i = 0; i < contacts.length; i++) {
      if (
        `${contacts[i].first_name} ${contacts[i].last_name}` ===
        selectedContact.full_name
      ) {
        hasDuplicate = true;
        break;
      }
    }

    if (!hasDuplicate) {
      contacts.push(selectedContact);
    }

    setState(prevState => ({
      ...prevState,
      opReq: { ...state.opReq, assigned_contact: contacts },
    }));
  };

  const onDeleteTargetAgencyContact = targetIdx => {
    let agencyContacts = state.opReq['agency_contact'];
    agencyContacts.splice(targetIdx, 1);
    setState(prevState => ({
      ...prevState,
      opReq: { ...state.opReq, agency_contact: agencyContacts },
    }));
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedAgencyContact = selectedAgencyContact => {
    const agencyContacts = state.opReq['agency_contact'];
    let hasDuplicate = false;
    for (let i = 0; i < agencyContacts.length; i++) {
      if (agencyContacts[i].full_name === selectedAgencyContact.full_name) {
        hasDuplicate = true;
        break;
      }
    }

    if (!hasDuplicate) {
      agencyContacts.push(selectedAgencyContact);
    }

    setState(prevState => ({
      ...prevState,
      opReq: { ...state.opReq, agency_contact: agencyContacts },
    }));
  };

  // If the end user click on the tag of a committee
  // Delete that committee from the current state
  const onDeleteTargetAgency = targetIdx => {
    let agencies = state.opReq['submitting_agency'];
    agencies.splice(targetIdx, 1);
    setState(prevState => ({
      ...prevState,
      opReq: { ...state.opReq, submitting_agency: agencies },
    }));
  };

  // Add the selected committee from the dropdown to the state
  const onAddSelectedAgency = selectedAgency => {
    const agencies = state.opReq['submitting_agency'];
    let hasDuplicate = false;
    for (let i = 0; i < agencies.length; i++) {
      if (agencies[i].name === selectedAgency.name) {
        hasDuplicate = true;
        break;
      }
    }

    if (!hasDuplicate) {
      agencies.push(selectedAgency);
    }

    setState(prevState => ({
      ...prevState,
      opReq: { ...state.opReq, submitting_agency: agencies },
    }));
  };

  const onDeleteNewAttachment = (e, id, field) => {
    setState(prevState => {
      prevState.opReq[field].splice(id, 1);
      return {
        opReq: { ...state.opReq, [field]: prevState.opReq[field] },
      };
    });
    e.stopPropagation();
  };

  // Add the selected LSR to report
  const onAddSelectedLocalLaw = selectedLocalLaw => {
    const localLaws = state.opReq['local_law'];
    let hasDuplicate = false;
    for (let i = 0; i < localLaws.length; i++) {
      let check =
        localLaws[i]['law_number'].includes(selectedLocalLaw['law_number']) ||
        selectedLocalLaw['law_number'].includes(localLaws[i]['law_number']);
      if (check) {
        hasDuplicate = true;
        return false;
      }
    }
    if (!hasDuplicate) {
      localLaws.push(selectedLocalLaw);
    }
    setState(prevState => ({ ...prevState, opReq: { ...state.opReq, local_law: localLaws } }));
    return true;
  };

  // Delete the selected LSR to report
  const onDeleteTargetLocalLaw = targetIdx => {
    const localLaws = state.opReq['local_law'];
    let newLocalLaws = [];
    for (let i = 0; i < localLaws.length; i++) {
      if (i !== targetIdx) {
        newLocalLaws.push(localLaws[i]);
      }
    }
    setState(prevState => ({ ...prevState, opReq: { ...state.opReq, local_law: newLocalLaws } }));
  };

  // Add the selected Local Law
  const onAddSelectedLegacyLocalLaw = selectedLocalLaw => {
    setState(prevState => {
      const legacyLocalLaws = prevState.opReq['legacy_local_law'];
      let hasDuplicate = false;
      for (let i = 0; i < legacyLocalLaws.length; i++) {
        let check = false;
        if (legacyLocalLaws[i]['law_number']) {
          check = legacyLocalLaws[i]['law_number'] === selectedLocalLaw['law_number']
        }
        if (check) {
          hasDuplicate = true;
          return prevState; // No need to update state if duplicate is found
        }
      }
      if (!hasDuplicate) {
        return {
          ...prevState,
          opReq: {
            ...prevState.opReq,
            legacy_local_law: [...legacyLocalLaws, selectedLocalLaw],
          },
        };
      }
      return prevState; // Return the previous state if no update is needed
    });
    return true;
  };

  // Delete the selected Local Law
  const onDeleteTargetLegacyLocalLaw = targetIdx => {
    setState(prevState => {
      const legacyLocalLaw = prevState.opReq['legacy_local_law'];
      const newLocalLaws = legacyLocalLaw.filter((_, index) => index !== targetIdx);

      return {
        ...prevState,
        opReq: {
          ...prevState.opReq,
          legacy_local_law: newLocalLaws,
        },
      };
    });
  };

  // Add the selected LSR to report
  const onAddSelectedLSR = selectedLSR => {
    const lsrs = state.opReq['ls_requests'];
    let hasDuplicate = false;
    for (let i = 0; i < lsrs.length; i++) {
      if (lsrs[i] === selectedLSR) {
        hasDuplicate = true;
        return false;
      }
    }
    if (!hasDuplicate) {
      lsrs.push(selectedLSR);
    }
    setState(prevState => ({ ...prevState, opReq: { ...state.opReq, ls_requests: lsrs } }));
    return true;
  };

  // Delete the selected LSR to report
  const onDeleteTargetLSR = targetIdx => {
    const lsrs = state.opReq['ls_requests'];
    let newLSRs = [];
    for (let i = 0; i < lsrs.length; i++) {
      if (i !== targetIdx) {
        newLSRs.push(lsrs[i]);
      }
    }
    setState(prevState => ({ ...prevState, opReq: { ...state.opReq, ls_requests: newLSRs } }));
  };

  const generateFields = reportFields => {
    const obj = {
      committees: {
        inputTitle: 'Committee*',
        param: 'committee_name',
        warningMessage: 'No committee has been selected',
        onAddTarget: onAddSelectedCommittee,
        onDeleteTarget: onDeleteTargetCommittee,
        dropdownList: props.committees,
      },
      agency_contact: {
        inputTitle: 'Agency Contact',
        param: 'full_name',
        warningMessage: 'No contact has been selected',
        onAddTarget: onAddSelectedAgencyContact,
        onDeleteTarget: onDeleteTargetAgencyContact,
        dropdownList: props.allAgencyUsers,
      },
      assigned_contact: {
        inputTitle: 'Assigned Contact*',
        param: 'full_name',
        warningMessage: 'No contact has been selected',
        onAddTarget: onAddSelectedContact,
        onDeleteTarget: onDeleteTargetContact,
        dropdownList: props.userList,
      },
      submitting_agency: {
        inputTitle: 'Submitting Agency*',
        param: 'name',
        warningMessage: 'No agency has been selected',
        onAddTarget: onAddSelectedAgency,
        onDeleteTarget: onDeleteTargetAgency,
        dropdownList: props.allAgencies,
      },
      local_law: {
        inputTitle: 'Local Laws',
        param: 'law_number',
        warningMessage: 'No Local Law has been selected',
        onAddTarget: onAddSelectedLocalLaw,
        onDeleteTarget: onDeleteTargetLocalLaw,
        dropdownList: [],
        help: '(e.g., https://legistar.council.nyc.gov/LegislationDetail.aspx?...)',
        noList: true,
      },
      legacy_local_law: {
        inputTitle: 'Legacy Local Laws (pre-1998)',
        param: 'law_number',
        warningMessage: 'No Legacy Local Law has been selected',
        onAddTarget: onAddSelectedLegacyLocalLaw,
        onDeleteTarget: onDeleteTargetLegacyLocalLaw,
        dropdownList: [],
        help: '(e.g., LL 72/1992)',
        noList: true,
      },
      ls_requests: {
        inputTitle: 'LS Requests',
        param: 'ls_number',
        warningMessage: 'No LSR has been selected',
        onAddTarget: onAddSelectedLSR,
        onDeleteTarget: onDeleteTargetLSR,
        dropdownList: [],
        help: '(e.g., 5667)',
        noList: true,
      },
    };

    const newDdlDisplay = reportFields.map((field, idx) => {
      if (
        [
          'committees',
          'agency_contact',
          'assigned_contact',
          'submitting_agency',
          'legacy_local_law',
          'local_law',
          'ls_requests',
        ].includes(field.field)
      ) {
        let helperText = null;
        if (['local_law', 'ls_requests', 'legacy_local_law'].includes(field.field)) {
          helperText = (
            <span style={{ 'color': 'gray' }}>
              <sup>*</sup>At LEAST one <span style={{ textDecoration: 'underline' }}>local Law (Legistar link)</span>
              , <span style={{ textDecoration: 'underline' }}>legacy local law number</span>
              , or <span style={{ textDecoration: 'underline' }}>LSR number</span> must be added.
            </span>
          );
        }
        return (
          <div className='mb-3' key={`${state.page}-${idx}`}>
            <TagsDisplay
              allowNew={field.field === 'submitting_agency'}
              className={
                !state.errorField[field.field]
                  ? styles['input-text-area']
                  : styles['input-text-area-warning']
              }
              currentList={state.opReq[field.field]}
              dropdownList={obj[field.field].dropdownList}
              inputTitle={obj[field.field].inputTitle}
              localLawHelper={helperText}
              noList={obj[field.field].noList}
              onAddTarget={obj[field.field].onAddTarget}
              onDeleteTarget={obj[field.field].onDeleteTarget}
              param={obj[field.field].param}
              placeholder={obj[field.field].help}
              reference={state.refs[field.field]}
              warningMessage={obj[field.field].warningMessage}
            />
            {state.errorField[field.field] && (
              <p className={styles['warning-message']}>
                {state.errorField[field.field]['error_message']}
              </p>
            )}
          </div>
        );
      } else if (field.type === 'select') {
        return (
          <div className='mb-3' key={`${state.page}-${idx}`}>
            <p className={styles['input-text-title']}>
              {field.title}
              {field.required && <span>*</span>}
            </p>
            {field.field === 'frequency_unit' && (
              <>
                <ToolTipIcon
                  classNameProp={styles['user-guide-field-icon']}
                  idProp='unit'
                />
                <UncontrolledPopover
                  innerClassName={styles['popover-container']}
                  className={styles['popover-outer-container']}
                  fade={false}
                  trigger='hover'
                  placement='bottom'
                  target='unit'>
                  <PopoverBody>
                    Reports will be requested by the system every Period unit.
                    <br></br>
                    <br></br>
                    The <b>Unit</b> specifies the time-unit used under the
                    <em>Period</em> field.
                    <em>
                      For example, a "Unit” of <b>Months</b>, and a “Period” of
                      <b>3</b> indicates that a report is due every 3 months.
                    </em>
                    The system accepts “Once” or “As needed” as unit options as
                    well.
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
            <select
              className={
                !state.errorField[field.field]
                  ? 'form-control'
                  : styles['input-text-area-warning']
              }
              value={
                state.opReq[field.field]
                  ? state.opReq[field.field].toString()
                  : ''
              }
              ref={state.refs[field.field]}
              onChange={e => {
                setState(prevState => ({
                  ...prevState,
                  opReq: {
                    ...state.opReq,
                    [field.field]: e.target.value,
                  },
                }));
              }}
              multiple={field.multiple}>
              <option value=''>Select {field.title}</option>
              {field.options &&
                field.options.map((op, idx1) => (
                  <option key={idx1} value={op}>
                    {op.toString()}
                  </option>
                ))}
            </select>
            {state.errorField[field.field] && (
              <p className={styles['warning-message']}>
                {state.errorField[field.field]['error_message']}
              </p>
            )}
          </div>
        );
      } else if (field.type === 'checkbox') {
        return (
          <div className='mb-3' key={`${state.page}-${idx}`}>
            <p className={styles['input-text-title']}>
              {field.title}
              {field.required && <span>*</span>}
            </p>
            <div className={styles['radio-buttons']}>
              <div className='mb-3' name={field.field}>
                <input
                  type='radio'
                  id={`yes-${field.field}`}
                  name={field.field}
                  checked={state.opReq[field.field]}
                  onChange={e => {
                    setState(prevState => ({
                      ...prevState,
                      opReq: { ...state.opReq, [field.field]: true },
                    }));
                  }}
                  className={styles['checkbox']}
                />
                <label htmlFor={`yes-${field.field}`}>Yes</label>
                <input
                  type='radio'
                  id={`no-${field.field}`}
                  name={field.field}
                  checked={
                    !state.opReq[field.field] &&
                    typeof state.opReq[field.field] !== 'undefined'
                  }
                  onChange={e => {
                    setState(prevState => ({
                      ...prevState,
                      opReq: { ...state.opReq, [field.field]: false },
                    }));
                  }}
                  className={styles['checkbox']}
                />
                <label htmlFor={`no-${field.field}`}>No</label>
                {state.errorField[field.field] && (
                  <p className={styles['warning-message']}>
                    {state.errorField[field.field]['error_message']}
                  </p>
                )}
              </div>
            </div>
          </div>
        );
      } else if (field.type !== 'file') {
        let disable = false;
        if (field.field === 'frequency_quantity') {
          disable = ['Once', 'As Needed', ''].includes(
            state.opReq['frequency_unit']
          );
        }
        let check = !(
          ['end_date', 'next_due', 'frequency_quantity'].includes(
            field.field
          ) && state.opReq['frequency_unit'] === 'Once'
        );

        return (
          <div className='mb-3' key={`${state.page}-${idx}`}>
            {check && (
              <>
                {field.field === 'end_date' && (
                  <div className={styles['end_date_question']}>
                    <label htmlFor='end_date_req'>
                      <input
                        type='checkbox'
                        id='end_date_req'
                        checked={state.end_date_required}
                        onChange={e => {
                          setState(prevState => ({
                            ...prevState,
                            end_date_required: !state.end_date_required,
                          }));
                        }}
                      />
                      Does this Operational Requirement have an end date?
                    </label>
                  </div>
                )}
                {!(
                  !state.end_date_required && field.field === 'end_date'
                ) && (
                  <>
                    <p className={styles['input-text-title']}>
                      {field.field === 'start_date' &&
                      state.opReq.tracker_type === 'Milestone'
                        ? 'Due Date'
                        : field.title}
                      {field.required && <span>*</span>}
                    </p>
                    {field.field === 'frequency_quantity' && check && (
                      <>
                        <ToolTipIcon
                          classNameProp={styles['user-guide-field-icon']}
                          idProp='period'
                        />
                        <UncontrolledPopover
                          innerClassName={styles['popover-container']}
                          className={styles['popover-outer-container']}
                          fade={false}
                          trigger='hover'
                          placement='bottom'
                          target='period'>
                          <PopoverBody>
                            Reports will be requested by the system every Period
                            unit.<br></br>
                            <br></br>
                            The <b>Unit</b> specifies the time-unit used under
                            the <em>Period</em> field.
                            <em>
                              For example, a "Unit” of <b>Months</b>, and a
                              “Period” of <b>3</b> indicates that a report is
                              due every 3 months.
                            </em>
                          </PopoverBody>
                        </UncontrolledPopover>
                      </>
                    )}
                    { field.type === 'textarea' &&
                      <textarea
                        className={`form-control ${
                            !state.errorField[field.field]
                              ? styles['input-text-area']
                              : styles['input-text-area-warning']
                          }`}
                        rows='6'
                        name={field.field}
                        value={state.opReq[field.field] || ''}
                        onChange={e => {
                          setState(prevState => ({
                            ...prevState,
                            opReq: {
                              ...prevState.opReq,
                              [field.field]: e.target.value,
                            },
                          }));
                        }}
                      />
                    }
                    {
                      field.type !== 'textarea' &&
                      <input
                        className={`form-control ${
                          !state.errorField[field.field]
                            ? styles['input-text-area']
                            : styles['input-text-area-warning']
                        }`}
                        type={field.type}
                        name={field.field}
                        value={state.opReq[field.field] || ''}
                        min={1}
                        max={31}
                        onChange={e => {
                          setState(prevState => ({
                            ...prevState,
                            opReq: {
                              ...state.opReq,
                              [field.field]: e.target.value,
                            },
                          }));
                        }}
                        disabled={
                          disable ||
                          (field.field === 'end_date' &&
                            !state.end_date_required)
                        }
                        ref={state.refs[field.field]}
                      />
                    }
                    {state.errorField[field.field] && (
                      <p className={styles['warning-message']}>
                        {state.errorField[field.field]['error_message']}
                      </p>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        );
      } else return null;
    });
    return newDdlDisplay;
  };

  let ReportFieldsFiltered = [];
  if (ReportFields) {
    ReportFieldsFiltered = ReportFields.filter(field => {
      return (
        field.create &&
        (field.tracker === 'Both' ||
          field.tracker === state.opReq.tracker_type)
      );
    });
  }
  let pageFields = [];
  if (state.page === 0) {
    pageFields = [ReportFieldsFiltered[0]];
  } else {
    pageFields = ReportFieldsFiltered.slice(1);
  }
  let halfOfFields = pageFields.length / 2;
  let firstHalfOfFields = pageFields.slice(0, halfOfFields);
  let secondHalfOfFields = pageFields.slice(halfOfFields);

  return (
    <form>
      <div className={styles['modal-header']}>
        <div className={styles['model-header-track-op-req']}>
          <span className={styles['back-button']}>
            {state.page !== 0 && (
              <div
                onClick={() => {
                  scrollToTheTop(false);
                  setState(prevState => {
                    let pageNum = prevState.page - 1;
                    return { ...prevState, page: pageNum };
                  });
                }}>
                <ShortLeftArrowIcon width='24' height='24' />
                <span>Back</span>
              </div>
            )}
          </span>
          <span className='popup-detail-title'>
            Track New Operational Requirement
          </span>
        </div>
      </div>
      {props.newReportStatus === 'fail' && (
        <div
          className={`alert alert-danger ${styles['alert-danger-banner']}`}
          role='alert'>
          <ExclamationPointDiamondIcon />
          <p>Failed to create this operational requirement</p>
        </div>
      )}
      {state.errorsPresent && (
        <div
          className={`alert alert-danger ${styles['alert-danger-banner']}`}
          role='alert'>
          <ExclamationPointDiamondIcon />
          <div>
            <p>There was an error with your form submission.</p>
            <p>Please correct the highlighted field(s) below</p>
          </div>
        </div>
      )}
      {props.isLoading === true && (
        <div className={styles['fixed-loading-container']}>
          <img
            src='/img/newLoadingAnimation.gif'
            alt='Loading animation'
            className='loading-image'
          />
          <h3>
            Loading <span className='dot1'>.</span>
            <span className='dot2'>.</span>
            <span className='dot3'>.</span>
          </h3>
        </div>
      )}
      {props.newReportStatus !== 'success' && state.page === 0 && (
        <div className={styles['tracker-type-page']}>
          <div className='mb-3' key={`${state.page}`}>
            <p className={styles['input-text-title']}>
              Select a Tracker Type
            </p>
            <div className='btn-group' role='group' aria-label='Tracker Type'>
              {['Report', 'Milestone'].map((type, idx) => {
                return (
                  <div className={styles['tracker-type-container']} key={idx}>
                    <input
                      className={styles['tracker-type-select']}
                      checked={state.opReq['tracker_type'] === type}
                      id={`${type.toLocaleLowerCase()}-radio`}
                      name='tracker_radio'
                      type='radio'
                      value={type}
                      onChange={e => onSelectTrackerType(e)}
                      autoComplete='off'
                    />
                    <label
                      className={`btn btn-outline-primary d-flex flex-column justify-content-center ${styles['tracker-type-select-label']}`}
                      htmlFor={`${type.toLocaleLowerCase()}-radio`}>
                      <span className={styles['radio-title']}>{type}</span>
                      <br />
                      <span className={styles['radio-description']}>
                        {type === 'Report'
                          ? 'Reports are usually files mandated often on a periodic basis by local law.'
                          : 'Milestones are one time goals that are to be met.'}
                      </span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>

          {state.errorField['tracker_type'] && (
            <p className={styles['warning-message']}>
              {state.errorField['tracker_type']['error_message']}
            </p>
          )}
        </div>
      )}
      {props.newReportStatus !== 'success' && state.page !== 0 && (
        <div className={styles['report-fields']}>
          <div className={styles['report-field-column']}>
            {generateFields(firstHalfOfFields)}
          </div>
          <div className={styles['report-field-column']}>
            {generateFields(secondHalfOfFields)}
          </div>
        </div>
      )}
      {props.newReportStatus !== 'success' && (
        <div className={styles['modal-footer']}>
          <button
            type='button'
            className={`submit-button ${styles['cancel-button']}`}
            onClick={() => {
              props.changeReportTrackingDisplay('list');
              props.changeReportTrackingPopupWindow(false);
            }}>
            Cancel
          </button>
          {state.page === 1 && (
            <button
              type='button'
              className={`submit-button ${styles['footer-button']}`}
              onClick={onSubmit}>
              Submit
            </button>
          )}
        </div>
      )}
      {props.newReportStatus === 'success' && (
        <div
          className={`alert alert-success ${styles['alert-danger-banner']} ${styles['success']}`}
          role='alert'>
          <LargeCheckMarkIcon />
          <div>
            <p>The report has been successfully updated!</p>
            <p>Page will refresh shortly.</p>
          </div>
        </div>
      )}
    </form>
  );
}

const mapStateToProps = state => {
  return {
    committees: state.userReducer.committees.filter(
      com => com.committee_name !== 'Unassigned'
    ),
    userList: state.userReducer.userList,
    isLoading: state.reportTrackingReducer.isLoading,
    newReportStatus: state.reportTrackingReducer.newReportStatus,
    allAgencies: state.reportTrackingReducer.allAgencies,
    allAgencyUsers: state.reportTrackingReducer.allAgencyUsers,
    savedProgress: state.reportTrackingReducer.savedProgress,
  };
};

export default connect(mapStateToProps, actions)(CreateNewReportDdl);
