import React, { useState, useEffect } from 'react';
import { CelebrationIcon, RibbonIcon } from '../services/SvgLibrary';

// Helper function to get the first Sunday in June for a given year
const getFirstSundayInJune = year => {
  const date = new Date(year, 5, 1); // June 1st (month is 0-based, so June is 5)
  // Calculate the number of days to add to reach the first Sunday
  const daysToAdd = (7 - date.getDay()) % 7;
  // Set date to the first Sunday in June
  date.setDate(date.getDate() + daysToAdd);
  return date;
};

const commemorationIcons = {
  'ribbon': RibbonIcon,
  'celebratory': CelebrationIcon
}

export const commemorations = [
  {
    month: null, // Month varies based on the lunar calendar
    day: null, // Day varies each year
    title: 'Month of Ramadan',
    resolution: '0515-2023',
    adopted: '3/16/2023',
    sponsor: 'N. Williams',
    color: '#004B87', // Dark Blue (often associated with spirituality)
    dotColors: ['#004B87', '#FFFFFF', '#FFD700'], // Dark Blue, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents spiritual and awareness
  },
  {
    month: null, // Month varies based on the lunar calendar (typically Jan or Feb)
    day: null, // Day varies each year
    title: 'Lunar New Year',
    resolution: '0331-2022',
    adopted: '2/2/2023',
    sponsor: 'C. Marte',
    color: '#FF4500', // Orange-Red (traditional color for Lunar New Year celebrations)
    dotColors: ['#FF4500', '#FFD700', '#FFFFFF'], // Orange-Red, Yellow, White dots
    icon: commemorationIcons['celebratory'], // Represents celebration
  },
  // January
  {
    month: 1,
    day: null,
    title: 'Cervical Health Awareness Month',
    resolution: '0531-2015',
    adopted: '1/22/2015',
    sponsor: 'M. Mark-Viverito',
    color: '#00A79D', // Teal
    dotColors: ['#00A79D', '#FFFFFF', '#FFD700'], // Teal, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },
  {
    month: 1,
    day: 13,
    title: 'Korean American Day',
    resolution: '0417-2014',
    adopted: '12/17/2014',
    sponsor: 'P. Koo',
    color: '#C8102E', // Red
    dotColors: ['#C8102E', '#002868', '#FFFFFF'], // Red, Blue, White (Korean flag colors)
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },
  {
    month: 1,
    day: 30,
    title: 'Fred T. Korematsu Day of Civil Liberties and the Constitution',
    resolution: '0792-2015',
    adopted: '12/19/2017',
    sponsor: 'D. Dromm',
    color: '#002868', // Blue
    dotColors: ['#002868', '#FFD700', '#FFFFFF'], // Blue, Gold, White dots
    icon: commemorationIcons['ribbon'], // Represents civil rights awareness
  },

  // February
  {
    month: 2,
    day: null,
    title: 'Black History Month',
    resolution: '0046-2002',
    adopted: '2/6/2002',
    sponsor: 'B. Perkins',
    color: '#FFD700', // Yellow background
    dotColors: ['#000000', '#FF0000', '#00FF00'], // Black, Red, Green dots for Black History Month
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },
  {
    month: 2,
    day: null,
    title: 'Dominican Heritage Month',
    resolution: '0071-2002',
    adopted: '2/27/2002',
    sponsor: 'D. Reyna',
    color: '#D32F2F', // Red
    dotColors: ['#D32F2F', '#002868', '#FFFFFF'], // Red, Blue, White (Dominican flag colors)
    icon: commemorationIcons['celebratory'], // Represents cultural heritage
  },
  {
    month: 2,
    day: 4,
    title: 'Rosa Parks Day',
    resolution: '0203-2018',
    adopted: '10/31/2018',
    sponsor: 'M. Eugene',
    color: '#555555', // Grey (neutral color for civil rights)
    dotColors: ['#555555', '#FFFFFF', '#FFD700'], // Grey, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents civil rights awareness
  },
  {
    month: 2,
    day: 15,
    title: 'Black Girl Magic Day',
    resolution: '0486-2023',
    adopted: '2/16/2023',
    sponsor: 'A. Stevens',
    color: '#FF69B4', // Hot Pink (feminine empowerment)
    dotColors: ['#FF69B4', '#FFFFFF', '#FFD700'], // Hot Pink, White, Yellow dots
    icon: commemorationIcons['celebratory'], // Represents empowerment celebration
  },
  {
    month: 2,
    day: 21,
    title: 'Mother Language Day',
    resolution: '0474-2023',
    adopted: '2/16/2023',
    sponsor: 'A. Farías',
    color: '#FFA500', // Orange (diversity and communication)
    dotColors: ['#FFA500', '#FFFFFF', '#FFD700'], // Orange, White, Yellow dots
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },

  // March
  {
    month: 3,
    day: 3,
    title: 'Delta Day (Delta Sigma Theta)',
    resolution: '0488-2023',
    adopted: '2/16/2023',
    sponsor: 'N. Williams',
    color: '#990000', // Crimson
    dotColors: ['#990000', '#FFD700', '#FFFFFF'], // Crimson, Gold, White dots
    icon: commemorationIcons['celebratory'], // Represents organizational celebration
  },
  {
    month: 3,
    day: 5,
    title: 'Three-Fifths Clause Awareness Day',
    resolution: '1000-2016',
    adopted: '5/5/2016',
    sponsor: 'A. King',
    color: '#FFD700', // Gold (justice and equality)
    dotColors: ['#FFD700', '#000000', '#FFFFFF'], // Gold, Black, White dots
    icon: commemorationIcons['ribbon'], // Represents civil rights awareness
  },

  // April
  {
    month: 4,
    day: null,
    title: 'Alcoholism and Substance Abuse Awareness Month',
    resolution: '0216-1998',
    adopted: '4/28/1998',
    sponsor: 'U. Clarke',
    color: '#FF0000', // Red Ribbon
    dotColors: ['#FF0000', '#FFFFFF', '#FFD700'], // Red, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },
  {
    month: 4,
    day: null,
    title: 'Child Abuse Prevention Month',
    resolution: '0218-1998',
    adopted: '4/28/1998',
    sponsor: 'S. DiBrienza',
    color: '#0000FF', // Blue
    dotColors: ['#0000FF', '#FFFFFF', '#FFD700'], // Blue, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents awareness
  },
  {
    month: 4,
    day: null,
    title: 'Minority Cancer Awareness Month',
    resolution: '0243-2004',
    adopted: '4/21/2004',
    sponsor: 'B. Perkins',
    color: '#9370DB', // Light Purple (cancer awareness)
    dotColors: ['#9370DB', '#FFD700', '#FFFFFF'], // Light Purple, Yellow, White dots
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },
  {
    month: 4,
    day: null,
    title: 'Autism Awareness Month',
    resolution: '0638-2015',
    adopted: '4/28/2015',
    sponsor: 'E. Ulrich',
    color: '#1E90FF', // Light Blue
    dotColors: ['#1E90FF', '#FFD700', '#FF69B4'], // Blue, Yellow, Pink dots for Autism Awareness
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },
  {
    month: 4,
    day: null,
    title: 'Organ Donation Awareness Month',
    resolution: '0648-2015',
    adopted: '4/28/2015',
    sponsor: 'C. Constantinides',
    color: '#00FF00', // Green
    dotColors: ['#00FF00', '#FFFFFF', '#FFD700'], // Green, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },
  {
    month: 4,
    day: 13,
    title: 'Borinqueneers Day',
    resolution: '0813-2023',
    adopted: '12/6/2023',
    sponsor: 'A. Farías',
    color: '#FF4500', // Orange
    dotColors: ['#FF4500', '#FFFFFF', '#FFD700'], // Orange, White, Yellow dots
    icon: commemorationIcons['celebratory'], // Represents commemorative celebration
  },
  {
    month: 4,
    day: 17,
    title: 'Giovanni da Verrazzano Day',
    resolution: '0074-2024',
    adopted: '4/11/2024',
    sponsor: 'D. Carr',
    color: '#4169E1', // Royal Blue
    dotColors: ['#4169E1', '#FFFFFF', '#FFD700'], // Royal Blue, White, Yellow dots
    icon: commemorationIcons['celebratory'], // Represents commemorative celebration
  },
  {
    month: 4,
    day: 29,
    title: 'End Jew Hatred Day',
    resolution: '0487-2023',
    adopted: '4/27/2023',
    sponsor: 'I. Vernikov',
    color: '#FFD700', // Gold (symbolic for awareness and remembrance)
    dotColors: ['#FFD700', '#000000', '#FFFFFF'], // Gold, Black, White dots
    icon: commemorationIcons['ribbon'], // Represents awareness
  },

  // May
  {
    month: 5,
    day: null,
    title: 'Asthma Awareness Month',
    resolution: '0215-1998',
    adopted: '4/28/1998',
    sponsor: 'A. Carrion',
    color: '#808080', // Grey
    dotColors: ['#808080', '#FFFFFF', '#FFD700'], // Grey, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },
  {
    month: 5,
    day: null,
    title: 'Asian Pacific American Heritage Month',
    resolution: '0283-2002',
    adopted: '4/30/2003',
    sponsor: 'J. Liu',
    color: '#FF4500', // Orange
    dotColors: ['#FF4500', '#FFFFFF', '#FFD700'], // Orange, White, Yellow dots
    icon: commemorationIcons['celebratory'], // Represents cultural heritage
  },
  {
    month: 5,
    day: null,
    title: 'Lupus Awareness Month',
    resolution: '0550-2023',
    adopted: '5/25/2023',
    sponsor: 'S. Hanif',
    color: '#9370DB', // Purple
    dotColors: ['#9370DB', '#FFD700', '#FFFFFF'], // Purple, Yellow, White dots
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },
  {
    month: 5,
    day: 4,
    title:
      'NYC Teachers, Educational Administrators and Education Support Staff Recognition Day',
    resolution: '0204-2018',
    adopted: '12/15/2021',
    sponsor: 'M. Eugene',
    color: '#4682B4', // Steel Blue (education)
    dotColors: ['#4682B4', '#FFD700', '#FFFFFF'], // Steel Blue, Yellow, White dots
    icon: commemorationIcons['celebratory'], // Represents recognition
  },
  {
    month: 5,
    day: 10,
    title: 'National Pan-Hellenic Council Day',
    resolution: '0568-2023',
    adopted: '12/6/2023',
    sponsor: 'N. Williams',
    color: '#008080', // Teal (fraternal unity)
    dotColors: ['#008080', '#FFD700', '#FFFFFF'], // Teal, Yellow, White dots
    icon: commemorationIcons['celebratory'], // Represents organizational celebration
  },
  {
    month: 5,
    day: 21,
    title: 'Christopher “Biggie Smalls” Wallace Day',
    resolution: '0623-2023',
    adopted: '6/22/2023',
    sponsor: 'C. Ossé',
    color: '#000000', // Black (hip-hop culture)
    dotColors: ['#000000', '#FFD700', '#FFFFFF'], // Black, Yellow, White dots
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },

  // June
  {
    month: 6,
    day: getFirstSundayInJune(new Date().getFullYear()).getDate(),
    title: 'Flake Legacy Day',
    resolution: '0447-2024',
    adopted: '6/20/2024',
    sponsor: 'A. Adams',
    color: '#8B4513', // SaddleBrown (symbolizing legacy and heritage)
    dotColors: ['#8B4513', '#FFD700', '#FFFFFF'], // SaddleBrown, Yellow, White dots
    icon: commemorationIcons['celebratory'], // Represents commemorative celebration
  },
  {
    month: 6,
    day: null,
    title: 'Gun Violence Awareness Month',
    resolution: '0121-2014',
    adopted: '6/11/2014',
    sponsor: 'J. Williams',
    color: '#FFA500', // Orange
    dotColors: ['#FFA500', '#FFFFFF', '#FFD700'], // Orange, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents awareness
  },
  {
    month: 6,
    day: null,
    title: 'Post-Traumatic Stress Disorder Awareness Month',
    resolution: '0747-2015',
    adopted: '6/26/2015',
    sponsor: 'E. Ulrich',
    color: '#008080', // Teal
    dotColors: ['#008080', '#FFFFFF', '#FFD700'], // Teal, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents mental health awareness
  },
  {
    month: 6,
    day: null,
    title: 'Caribbean Heritage Month',
    resolution: '0199-2022',
    adopted: '6/22/2023',
    sponsor: 'C. Hudson',
    color: '#002868', // Blue (Caribbean unity)
    dotColors: ['#002868', '#FFFFFF', '#FFD700'], // Blue, White, Yellow dots
    icon: commemorationIcons['celebratory'], // Represents cultural heritage
  },
  {
    month: 6,
    day: null,
    title: 'Fatherhood Recognition Month',
    resolution: '0645-2023',
    adopted: '6/22/2023',
    sponsor: 'K. Riley',
    color: '#4682B4', // Steel Blue (symbolic for strength and stability)
    dotColors: ['#4682B4', '#FFFFFF', '#FFD700'], // Steel Blue, White, Yellow dots
    icon: commemorationIcons['celebratory'], // Represents recognition
  },
  {
    month: 6,
    day: 6,
    title: 'D-Day Remembrance Day',
    resolution: '0157-2024',
    adopted: '6/6/2024',
    sponsor: 'J. Menin',
    color: '#4682B4', // Steel Blue (military remembrance)
    dotColors: ['#4682B4', '#FFD700', '#FFFFFF'], // Steel Blue, Yellow, White dots
    icon: commemorationIcons['ribbon'], // Represents remembrance
  },
  {
    month: 6,
    day: 8,
    title: 'Teen Mental Health Awareness Day',
    resolution: '1374-2017',
    adopted: '5/10/2017',
    sponsor: 'A. Cohen',
    color: '#32CD32', // Lime Green (often associated with mental health awareness)
    dotColors: ['#32CD32', '#FFFFFF', '#FFD700'], // Lime Green, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents mental health awareness
  },
  {
    month: 6,
    day: 19,
    title: 'Sickle Cell Awareness Day',
    resolution: '0980-2019',
    adopted: '2/27/2020',
    sponsor: 'I. Daneek Miller',
    color: '#800020', // Burgundy
    dotColors: ['#800020', '#FFD700', '#FFFFFF'], // Burgundy, Yellow, White dots
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },

  {
    month: 6,
    day: 22,
    title: 'Veterans Tribute and Advocacy Day',
    resolution: '1412-2017',
    adopted: '6/21/2017',
    sponsor: 'M. Eugene',
    color: '#000080', // Navy Blue
    dotColors: ['#000080', '#FFD700', '#FFFFFF'], // Navy Blue, Yellow, White dots
    icon: commemorationIcons['ribbon'], // Represents military appreciation
  },

  // July
  {
    month: 7,
    day: null,
    title: 'American with Disabilities Act Month',
    resolution: '0399-2002',
    adopted: '7/24/2002',
    sponsor: 'M. Lopez',
    color: '#0000FF', // Blue
    dotColors: ['#0000FF', '#FFFFFF', '#FFD700'], // Blue, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents disability awareness
  },
  {
    month: 7,
    day: 8,
    title: 'Reggie “Combat Jack” Ossé Day',
    resolution: '0624-2023',
    adopted: '6/22/2023',
    sponsor: 'C. Ossé',
    color: '#696969', // Dim Grey
    dotColors: ['#696969', '#FFD700', '#FFFFFF'], // Dim Grey, Yellow, White dots
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },

  // August
  {
    month: 8,
    day: null,
    title: 'Hip Hop Recognition Month',
    resolution: '0346-2022',
    adopted: '9/14/2023',
    sponsor: 'N. Williams',
    color: '#FF4500', // Orange (vibrancy of hip-hop)
    dotColors: ['#FF4500', '#000000', '#FFFFFF'], // Orange, Black, White dots
    icon: commemorationIcons['celebratory'], // Represents cultural recognition
  },
  {
    month: 8,
    day: 11,
    title: 'Hip Hop Celebration Day',
    resolution: '0346-2022',
    adopted: '9/14/2023',
    sponsor: 'N. Williams',
    color: '#000000', // Black
    dotColors: ['#000000', '#FFD700', '#FFFFFF'], // Black, Yellow, White dots
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },

  // September
  {
    month: 9,
    day: null,
    title: 'Blood and Bone Marrow Donor Month',
    resolution: '0445-2004',
    adopted: '9/28/2004',
    sponsor: 'J. Oddo',
    color: '#FF0000', // Red
    dotColors: ['#FF0000', '#FFFFFF', '#FFD700'], // Red, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },
  {
    month: 9,
    day: null,
    title: 'Suicide Awareness Month',
    resolution: '1229-2012',
    adopted: '9/24/2012',
    sponsor: 'S. Levin',
    color: '#FFFF00', // Yellow (prevention)
    dotColors: ['#FFFF00', '#FFFFFF', '#FF4500'], // Yellow, White, Orange dots
    icon: commemorationIcons['ribbon'], // Represents mental health awareness
  },
  {
    month: 9,
    day: null,
    title: 'Ovarian Cancer Awareness Month',
    resolution: '0380-2014',
    adopted: '9/23/2014',
    sponsor: 'R. Espinal, Jr.',
    color: '#00A79D', // Teal
    dotColors: ['#00A79D', '#FFFFFF', '#FFD700'], // Teal, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents health awareness
  },

  // October
  {
    month: 10,
    day: null,
    title: 'National Disability Employment Awareness Month',
    resolution: '0587-2004',
    adopted: '10/13/2004',
    sponsor: 'J. Addabbo, Jr.',
    color: '#800080', // Purple
    dotColors: ['#800080', '#FFFFFF', '#FFD700'], // Purple, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents disability awareness
  },
  {
    month: 10,
    day: null,
    title: 'Domestic Violence Awareness Month',
    resolution: '0429-2014',
    adopted: '10/22/2014',
    sponsor: 'V. Gibson',
    color: '#800080', // Purple
    dotColors: ['#800080', '#FFFFFF', '#FFD700'], // Purple, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents awareness
  },
  {
    month: 10,
    day: 9,
    title: 'New York City Haitian Day',
    resolution: '0687-2015',
    adopted: '6/21/2016',
    sponsor: 'M. Eugene',
    color: '#D32F2F', // Red (Haitian flag)
    dotColors: ['#D32F2F', '#002868', '#FFFFFF'], // Red, Blue, White dots (Haitian flag)
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },
  {
    month: 10,
    day: 11,
    title: 'Day of the Girl Child',
    resolution: '1649-2013',
    adopted: '12/19/2013',
    sponsor: 'G.O. Koppell',
    color: '#FF69B4', // Hot Pink (symbolic for feminine empowerment and advocacy)
    dotColors: ['#FF69B4', '#FFD700', '#FFFFFF'], // Hot Pink, Yellow, White dots
    icon: commemorationIcons['ribbon'], // Represents advocacy and awareness
  },
  {
    month: 10,
    day: 11,
    title: 'Casimir Pulaski Day',
    resolution: '0421-2018',
    adopted: '10/31/2018',
    sponsor: 'R. Holden',
    color: '#002868', // Blue (Polish flag)
    dotColors: ['#002868', '#D32F2F', '#FFFFFF'], // Blue, Red, White dots (Polish flag)
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },
  {
    month: 10,
    day: 15,
    title: 'Tadeusz Kosciuszko Day',
    resolution: '0422-2018',
    adopted: '10/31/2018',
    sponsor: 'R. Holden',
    color: '#FFFFFF', // White (Polish heritage)
    dotColors: ['#FFFFFF', '#002868', '#D32F2F'], // White, Blue, Red dots
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },

  // November
  {
    month: 11,
    day: null,
    title: 'New York City History Month',
    resolution: '0898-2007',
    adopted: '6/15/2007',
    sponsor: 'D. Recchia, Jr.',
    color: '#D2691E', // Chocolate (history and heritage)
    dotColors: ['#D2691E', '#FFFFFF', '#FFD700'], // Chocolate, White, Yellow dots
    icon: commemorationIcons['celebratory'], // Represents cultural heritage
  },
  {
    month: 11,
    day: null,
    title: 'Hip Hop History Month',
    resolution: '0346-2022',
    adopted: '9/14/2023',
    sponsor: 'N. Williams',
    color: '#FF4500', // Orange (representing the vibrancy and energy of hip-hop culture)
    dotColors: ['#FF4500', '#000000', '#FFFFFF'], // Orange, Black, White dots
    icon: commemorationIcons['celebratory'], // Represents cultural recognition
  },
  {
    month: 11,
    day: null,
    title: 'Veteran Appreciation Month',
    resolution: '0581-2023',
    adopted: '10/19/2023',
    sponsor: 'E. Dinowitz',
    color: '#000080', // Navy Blue
    dotColors: ['#000080', '#FFFFFF', '#FFD700'], // Navy Blue, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents veteran appreciation
  },
  {
    month: 11,
    day: 11,
    title: 'Polish Independence Day',
    resolution: '0420-2018',
    adopted: '10/31/2018',
    sponsor: 'R. Holden',
    color: '#D32F2F', // Red (Polish flag)
    dotColors: ['#D32F2F', '#FFFFFF', '#002868'], // Red, White, Blue dots (Polish flag)
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },
  {
    month: 11,
    day: 15,
    title: 'Ol’ Dirty Bastard Day',
    resolution: '0621-2023',
    adopted: '11/15/2023',
    sponsor: 'C. Ossé',
    color: '#000000', // Black
    dotColors: ['#000000', '#FFD700', '#FFFFFF'], // Black, Yellow, White dots
    icon: commemorationIcons['celebratory'], // Represents cultural celebration
  },

  // December
  {
    month: 12,
    day: 1,
    title: 'Rosa Parks Commemoration Day',
    resolution: '1240-2005',
    adopted: '11/30/2005',
    sponsor: 'C. Barron',
    color: '#555555', // Grey
    dotColors: ['#555555', '#FFFFFF', '#FFD700'], // Grey, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents civil rights awareness
  },
  {
    month: 12,
    day: 8,
    title: 'Sri Guru Tegh Bahadur Jee Day',
    resolution: '1872-2021',
    adopted: '12/15/2021',
    sponsor: 'P. Vallone',
    color: '#FF4500', // Orange (Sikhism)
    dotColors: ['#FF4500', '#FFFFFF', '#FFD700'], // Orange, White, Yellow dots
    icon: commemorationIcons['celebratory'], // Represents religious celebration
  },
  {
    month: 12,
    day: 10,
    title: 'Human Rights Day',
    resolution: '0586-2002',
    adopted: '12/4/2002',
    sponsor: 'B. De Blasio',
    color: '#0000FF', // Blue
    dotColors: ['#0000FF', '#FFFFFF', '#FFD700'], // Blue, White, Yellow dots
    icon: commemorationIcons['ribbon'], // Represents awareness
  },
];

// Utility function to calculate luminance and return either white or black text color based on background color brightness
export const getContrastTextColor = hexColor => {
  // Remove the hash at the start if it's there
  const hex = hexColor.replace('#', '');

  // Parse the r, g, b values
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Calculate luminance using the formula
  const luminance = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  // If luminance is greater than 186 (midpoint for a typical RGB space), return dark text, otherwise light text
  return luminance > 186 ? '#000000' : '#FFFFFF';
};

// Helper function to generate random dots
const generateRandomDots = (colorOptions, numDots = 50) => {
  const dots = [];

  for (let i = 0; i < numDots; i++) {
    // Randomly pick a color from the colorOptions array
    const randomColor =
      colorOptions[Math.floor(Math.random() * colorOptions.length)];

    // Random size between 5px and 20px
    const size = Math.floor(Math.random() * 15) + 5;

    // Random position (absolute positioning in percentage to fit in card)
    const top = Math.random() * 100; // 0 to 100% from top
    const left = Math.random() * 100; // 0 to 100% from left

    dots.push({
      backgroundColor: randomColor,
      width: `${size}px`,
      height: `${size}px`,
      top: `${top}%`,
      left: `${left}%`,
    });
  }

  return dots;
};

const CommemorationsDisplay = () => {
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [filteredCommemorations, setFilteredCommemorations] = useState(commemorations);
  const currentMonth = new Date().getMonth() + 1; // Get current month (0-based)
  const currentDay = new Date().getDate(); // Get current day

  useEffect(() => {
    // Step 1: Filter commemorations for the current month with specific dates
    const dateBasedCommemorations = commemorations.filter(
      item => item.month === currentMonth && item.day === currentDay
    );

    // Step 2: Filter month-based commemorations (without specific day)
    const monthBasedCommemorations = commemorations.filter(
      item => item.month === currentMonth && !item.day
    );

    // Step 3: Prioritize specific date commemorations if they exist for today or later
    const combinedCommemorations = dateBasedCommemorations.concat(monthBasedCommemorations)
    setFilteredCommemorations(combinedCommemorations);
    setIsAutoPlaying(combinedCommemorations.length > 0);
  }, [currentMonth, currentDay]);

  return (
    <div className='row mb-4'>
      {/* Bootstrap Carousel */}
      {filteredCommemorations.length > 0 ? (
        <div className='d-flex justify-content-center align-items-center position-relative'>
          <div className='card w-100'>
            <div
              id='commemorationCarousel'
              className='carousel slide'
              data-bs-ride={isAutoPlaying ? 'carousel' : undefined}>
              <div className='carousel-inner'>
                {filteredCommemorations.map((item, index) => {
                  const backgroundColor = item.color;
                  const dotColors = item.dotColors;
                  const dots = generateRandomDots(dotColors, 30);
                  const textColor = getContrastTextColor(backgroundColor);

                  return (
                    <div
                      key={index}
                      className={`carousel-item ${
                        index === 0 ? 'active' : ''
                      }`}>
                      <div
                        className='card-body fs-6 p-0 border-0 rounded position-relative overflow-hidden'
                        style={{
                          backgroundColor,
                          color: textColor,
                        }}>
                        {/* Render random dots */}
                        {dots.map((dot, i) => (
                          <div
                            key={i}
                            className='position-absolute'
                            style={{
                              zIndex: 0,
                              borderRadius: '50%',
                              filter: 'blur(2px)',
                              ...dot,
                            }}
                          />
                        ))}
                        <div
                          className='position-relative py-3 px-4 '
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.3)',
                            zIndex: 1,
                          }}>
                          <div style={{ position: 'relative', zIndex: 2 }}>
                            <div className='container-fluid mx-0 text-center'>
                              <div className='row align-items-start'>
                                <div className='d-flex align-items-center flex-column flex-sm-row mb-md-2'>
                                  <div className='me-md-3 mb-3 mb-md-0'>
                                    < item.icon />
                                  </div>
                                  <div className='flex-grow-1'>
                                    <h5 className='card-title fs-5 text-center text-md-start fw-bold m-0 mb-3 mb-md-0'>
                                      {item.title}
                                    </h5>
                                  </div>
                                </div>
                              </div>
                              <div className='row align-items-start'>
                                <div
                                  className='col-12 col-md text-center text-md-start'
                                  style={{ fontSize: '.9rem' }}>
                                  NYC Council Resolution {item.resolution}
                                </div>

                                <div
                                  className='col-12 col-md text-center'
                                  style={{ fontSize: '.9rem' }}>
                                  Adopted {item.adopted}
                                </div>

                                <div
                                  className='col-12 col-md text-center text-md-end'
                                  style={{ fontSize: '.9rem' }}>
                                  Sponsored by CM {item.sponsor}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* Carousel Controls */}
                        <button
                          className='carousel-control-prev'
                          type='button'
                          data-bs-target='#commemorationCarousel'
                          data-bs-slide='prev'
                          style={{ width: '30px' }}>
                          <span
                            className='carousel-control-prev-icon'
                            aria-hidden='true'></span>
                          <span className='visually-hidden'>Previous</span>
                        </button>
                        <button
                          className='carousel-control-next'
                          type='button'
                          data-bs-target='#commemorationCarousel'
                          data-bs-slide='next'
                          style={{ width: '30px' }}>
                          <span
                            className='carousel-control-next-icon'
                            aria-hidden='true'></span>
                          <span className='visually-hidden'>Next</span>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='alert alert-warning text-center'>
          No commemorations for the current month.
        </div>
      )}
    </div>
  );
};

export default CommemorationsDisplay;
