import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { url } from '../../config';
import { connect } from 'react-redux';
import {
  loadLsRequests,
  clearLsRequestArray,
  fetchOneLsr,
  checkDuplicate,
  updateTargetLsRequest,
} from '../../actions/lsRequestAction';
import { LsFields, allFileFields } from '../../services/LsFields';
import NewLsMenu from './NewLsMenu';
import styles from '../../styles/ls-request/NewLsMenu.module.scss';
import LsRequestsTable from './LsRequestsTable';
import Sidebar from 'react-sidebar';
import LsInfoDisplay from './LsInfoDisplay';
import { lsrFieldsToIdx, docFieldsToIdx, adminRoles, nonLegDivisionRoles } from '../../services/constants';
import { useParams } from 'react-router-dom';

const defaultColumns = [
  'LSR #',
  'Sponsor',
  'LSR Type',
  'Describe The Problem',
  'Inspiration For LSR',
  'Legislative Solution',
  'Status',
  'Overlapping LSRs',
  'Related LSRs',
].join(',');
const orderedFields = [...LsFields]
  .filter(col => !['new_status', 'new_status_requestor'].includes(col.field))
  .sort((a, b) => (a.table_order > b.table_order ? 1 : -1));
const make_first_prime_public_index = orderedFields.findIndex(
  item => item.field === 'make_first_prime_public'
);
orderedFields[make_first_prime_public_index].title = 'Waived Confidentiality';

const LsDupSearch = props => {
  /** Table states */
  const [sortedBy, setSortedBy] = useState('-ls_number');
  // eslint-disable-next-line
  const [curtPopup, setCurtPopup] = useState('');
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [optionsChanged, setOptionsChanged] = useState(false);
  /** Duplicate Checker States */
  const [mainLs, setMainLs] = useState(null);
  const [mainBarOpen, setMainBarOpen] = useState(false);
  const [currentSearchType, setCurrentSearchType] = useState('Automated');
  const [overlapDuplicates, setOverlapDuplicates] = useState(() => {
    return (
      JSON.parse(localStorage.getItem('dupBucket'))?.find(
        item => item.ls_number === mainLs?.ls_number
      )?.overlapDuplicates || []
    );
  });

  const [relatedDuplicates, setRelatedDuplicates] = useState(() => {
    return (
      JSON.parse(localStorage.getItem('dupBucket'))?.find(
        item => item.ls_number === mainLs?.ls_number
      )?.relatedDuplicates || []
    );
  });

  const [dupArray, setDupArray] = useState([]);

  // useParams hook instead of props.match.params:
  const { id } = useParams();

  // A flag for the filter button to be conditionally rendered depends on the parent component
  const [filterFlag, setFilterFlag] = useState(true);

  /** Column States */
  const [columnPreset, setColumnPreset] = useState(
    `column-${props.userProfile.username}-preset-3`
  );
  localStorage.setItem('active-table-column', columnPreset);

  const allColumns = nonLegDivisionRoles.includes(props.userProfile.role)
    ? orderedFields.filter(field => field.viewability !== 'staffOnly')
    : orderedFields;

  let savedColumns = localStorage.getItem(columnPreset) || defaultColumns;
  const presetColumns = allColumns.map(column => {
    if (savedColumns.includes(column.title)) {
      return { ...column, show: true };
    } else {
      return { ...column, show: false };
    }
  });

  const [columns, setColumns] = useState(presetColumns);
  const [showColumnMenu, setShowColumnMenu] = useState(false);
  const checkAllColumns = columns.every(col => col.show);

  let columnsToSave = columns.reduce((acc, column) => {
    if (column.show) {
      acc.push(column.title);
    }
    return acc;
  }, []);
  localStorage.setItem(columnPreset, columnsToSave.join(','));

  /** Filter States */
  const defaultFilterValues = {
    'committee': { check: false, value: [], locked: false },
    'submitted_by': { check: false, value: [], locked: false },
    'division': { check: false, value: [], locked: false },
    'first_prime': {
      check: nonLegDivisionRoles.includes(props.userProfile.role)
        ? true
        : false,
      value: nonLegDivisionRoles.includes(props.userProfile.role)
        ? props.userProfile.under_council_member
          ? [props.userProfile.under_council_member.toString()]
          : [props.userProfile.id.toString()]
        : [],
      locked: nonLegDivisionRoles.includes(props.userProfile.role)
        ? true
        : false,
    },
    'first_in_time': { check: false, value: [], locked: false },
    'last_activated': {
      check: window.location.pathname.toLowerCase().includes('myactivels')
        ? true
        : false,
      value: window.location.pathname.toLowerCase().includes('myactivels')
        ? 'all'
        : '',
      locked: false,
    },
    'ls_number': { check: false, value: '', locked: false },
    'ls_type': { check: false, value: [], locked: false },
    'staff': {
      check:
        window.location.pathname.toLowerCase().includes('mytasks') &&
        ![...nonLegDivisionRoles, ...adminRoles].includes(
          props.userProfile.role
        )
          ? true
          : false,
      value:
        window.location.pathname.toLowerCase().includes('mytasks') &&
        ![...nonLegDivisionRoles, ...adminRoles].includes(
          props.userProfile.role
        )
          ? [props.userProfile.id.toString()]
          : [],
      locked:
        window.location.pathname.toLowerCase().includes('mytasks') &&
        ![...nonLegDivisionRoles, ...adminRoles].includes(
          props.userProfile.role
        )
          ? true
          : false,
    },
    'status': { check: false, value: [], locked: false },
  };

  const [filterPreset, setFilterPreset] = useState(
    localStorage.getItem('active-table-filter') ||
      `filter-${props.userProfile.username}`
  );
  localStorage.setItem('active-table-filter', filterPreset);

  let savedFilterValues =
    JSON.parse(localStorage.getItem(filterPreset)) || defaultFilterValues;

  savedFilterValues['last_activated'].check =
    window.location.pathname.includes('myActiveLs');
  savedFilterValues['last_activated'].value = 'all';

  const [filterValues, setFilterValues] = useState(savedFilterValues);
  localStorage.setItem(filterPreset, JSON.stringify(filterValues));
  const [searchInput, setSearchInput] = useState('');
  const [search, setSearch] = useState(searchInput);
  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [filtered, setFiltered] = useState({ bool: false, url: '' });

  const firstRender = useRef(true);
  // useEffect hook for component did mount
  useEffect(() => {
    applyFilters();
    if (!filterFlag) {
      setFilterFlag(true);
    }

    try {
      if (id) {
        props.fetchOneLsr(id, () => {});
        props.checkDuplicate(id);
      }
    } catch (e) {
      console.log(e);
    }
  }, []);

  /*
  UseEffect hook to initialize dupBucket in local storage
  */

  useEffect(() => {
    // Get the current dupBucket from localStorage
    let dupBucket = JSON.parse(localStorage.getItem('dupBucket')) || [];

    if (mainLs && dupArray.length > 0) {
      // Find the index of the object with the matching ls_number
      const index = dupBucket.findIndex(
        item => item.ls_number === mainLs.ls_number
      );
      if (index === -1) {
        // If the LS does not exist, create a new object and add it to the dupBucket
        dupBucket.push({
          ls_number: mainLs.ls_number,
          overlapDuplicates: overlapDuplicates,
          relatedDuplicates: relatedDuplicates,
          dupArray: dupArray,
        });
      } else if (
        index !== -1 &&
        dupBucket[index].dupArray.length <= dupArray.length &&
        !dupArray?.every(num => dupBucket[index].dupArray.includes(num))
      ) {
        dupBucket[index].dupArray = dupArray;
      }
    }

    // Update the dupBucket in localStorage
    localStorage.setItem('dupBucket', JSON.stringify(dupBucket));
  }, [dupArray, relatedDuplicates, overlapDuplicates]);

  /**
   * useEffect hook to detect once LSR is fetched, update overlap and related duplicates
   */
  useEffect(() => {
    let { updateTargetLs } = props;
    if (JSON.stringify(updateTargetLs) !== '{}') {
      // If it's the first time render, updateTargetLs will be the mainLS
      // Otherwise, it'll be a result of calling fetchOneLSR for dupArray
      if (firstRender.current) {
        firstRender.current = false;

        /* check if related and overlap should be initialized from the actual 
        updateTargetLs or from the dupBucket previous save */

        let dupBucket = JSON.parse(localStorage.getItem('dupBucket')) || [];

        let index = dupBucket?.findIndex(
          item => item?.ls_number === updateTargetLs.ls_number
        );

        let overlapDuplicatesArray;
        let relatedDuplicatesArray;

        if (index !== -1) {
          overlapDuplicatesArray = dupBucket[index].overlapDuplicates;
          relatedDuplicatesArray = dupBucket[index].relatedDuplicates;
        } else {
          overlapDuplicatesArray =
            updateTargetLs.overlapping_ls.map(e => e.ls_number) || [];
          relatedDuplicatesArray =
            updateTargetLs.related_ls.map(e => e.ls_number) || [];
        }

        setMainLs(updateTargetLs);

        setMainBarOpen(true);
        setOverlapDuplicates(overlapDuplicatesArray);
        setRelatedDuplicates(relatedDuplicatesArray);

        let all_dupes = overlapDuplicatesArray.concat(relatedDuplicatesArray);

        /* Making the API call for all the LSRs in one call instead of doing it one by one with fetchOneLsr, which 
        take more time causing to assign and pass dupArray array with some mising elements (LSRs not yet fetched) to
        the LsRequestTable and NewLsMenu child components */

        if (all_dupes.length > 0) {
          const filters = encodeURIComponent(
            JSON.stringify({ ls_number: all_dupes.join(',') })
          );
          const endpoint =
            url + `/api/lsRequest/filterLsRequest/?filters=${filters}`;
          axios
            .get(endpoint)
            .then(response => {
              setDupArray(response.data.es_results);
            })
            .catch(error => {
              console.error('Error fetching duplicates:', error);
            });
        }
      }
    }
  }, [props.updateTargetLs]);

  /**
   * COLUMN HANDLING FUNCTIONS
   * * toggleColumn
   *   toggles a column to be shown or hidden
   * * setDefaultColumns
   *   reset selected columns to default columns
   * * changeColumnPreset
   *   change user selected column preset and select saved columns
   */
  function toggleColumn(title) {
    setColumns(cols =>
      cols.map(col => (col.title === title ? { ...col, show: !col.show } : col))
    );
  }

  function setDefaultColumns() {
    let columnView = allColumns.map(column => {
      if (defaultColumns.includes(column.title)) {
        return { ...column, show: true };
      } else {
        return { ...column, show: false };
      }
    });
    setColumns(columnView);
  }

  function changeColumnPreset(columnPresetOption) {
    localStorage.setItem('active-table-column', columnPresetOption);
    // If column preset already exists, set columns to preset values or default.
    if (localStorage.getItem(columnPresetOption)) {
      let savedColumns = localStorage.getItem(columnPresetOption).split(',');
      setColumns(cols =>
        cols.map(col => {
          col.show = savedColumns.includes(col.title);
          return col;
        })
      );
    } else {
      setDefaultColumns();
    }
    setColumnPreset(columnPresetOption);
  }

  // Check or uncheck all the checkbox
  function toggleAllColumns() {
    // If not already all checked, check all
    if (!checkAllColumns) {
      setColumns(cols => cols.map(col => ({ ...col, show: true })));
    } else {
      // otherwise, set to default
      setDefaultColumns();
    }
  }
  /**
   * * END COLUMN HANDLING FUNCTIONS
   */

  /** FILTER HANDLING FUNCTIONS
   * * onClearSearch : clear search
   * * onClearFilter : clear filters
   * * onGetFilterResult : call api to load filter results
   * * changeFilterPreset : change filter preset
   */
  // On clear the filter fields
  function onClearSearch() {
    setSearchInput('');
    applyFilters('', filterValues, sortedBy);
  }

  function onClearFilter() {
    setDefaultFilters();
    applyFilters(searchInput, defaultFilterValues, sortedBy);
  }

  function setDefaultFilters() {
    setFilterValues({ ...defaultFilterValues });
  }

  function changeFilterPreset(filterPresetOption) {
    localStorage.setItem('active-table-filter', filterPresetOption);
    // If filter preset already exists, get saved values
    if (localStorage.getItem(filterPresetOption)) {
      let savedFilters = JSON.parse(localStorage.getItem(filterPresetOption));
      setFilterValues(savedFilters);
    } else {
      setDefaultFilters();
    }
    setFilterPreset(filterPresetOption);
  }

  /**
   * onAddFilter
   * @param field: which field to add filter to
   * @param filter: value to be added to field value
   *
   * If the field is an LSR number, check if the number already exists first.
   *   If it doesn't exist and is not empty, add to field
   *
   * Otherwise, for other fields, check if the value already exists.
   *   If it doesn't exist, add filter
   *
   * If no filters previously existed for the field, activate check flag.
   * Calls state setter for filter values with updated filter values.
   */
  function onAddFilter(field, filter) {
    let values = { ...filterValues };
    if (field === 'ls_number' && filter.length > 0) {
      // prevent duplicates
      let ls_nums_to_add = filter.split(',');
      let current_ls_nums =
        values[field].value.length > 0 ? values[field].value.split(',') : [];
      ls_nums_to_add.forEach(ls_num => {
        if (!current_ls_nums.includes(ls_num) && ls_num.length > 0) {
          current_ls_nums.push(ls_num);
        }
      });
      values[field].value = current_ls_nums.join(',');
      if (!values[field].check) values[field].check = true;
    } else {
      if (!values[field].value.includes(filter)) {
        values[field].value.push(filter);
        if (!values[field].check) values[field].check = true;
      }
    }
    setFilterValues(values);
  }

  /**
   * onRemoveFilter
   * @param field: field to remove filter from
   * @param filter: filter to be removed from ls field
   *
   * If field is number, convert value from string to list and remove the filter.
   * For other fields, check if the filter exists and remove from list.
   *
   * If there are no more filters after removing, deactivate check flag.
   */
  function onRemoveFilter(field, filter) {
    let values = { ...filterValues };
    if (field === 'ls_number') {
      let ls_nums = values[field].value.split(','); // convert field to list
      values[field].value = ls_nums.filter(val => val !== filter).join(','); //remove filter and rejoin into string
      if (values[field].value.length === 0 && values[field].check)
        values[field].check = false;
    } else {
      if (values[field].value.includes(filter)) {
        values[field].value = values[field].value.filter(val => val !== filter);
        if (values[field].value.length === 0 && values[field].check)
          values[field].check = false;
      }
    }
    setFilterValues(values);
  }

  function applyFilters(
    search = searchInput,
    filters = filterValues,
    sort = sortedBy
  ) {
    let combination = {};
    if (search) combination['keyword'] = search;
    if (!localStorage.getItem('lsr-search-fields')) {
      localStorage.setItem('lsr-search-fields', JSON.stringify({...getFilteredDefaultLSRFieldTypes(props.userProfile.role, LsFields)}))
    }
    
    if (!localStorage.getItem('doc-search-fields')) {
      localStorage.setItem('doc-search-fields', JSON.stringify({...getFilteredDefaultLSRDocumentTypes(props.userProfile.role, LsFields)}))
    }

    const storedLsrFilters = localStorage.getItem('lsr-search-fields') 
      ? JSON.parse(localStorage.getItem('lsr-search-fields'))
      : {...defaultLSRFieldTypes}
    const storedDocFilters = localStorage.getItem('doc-search-fields')
      ? JSON.parse(localStorage.getItem('doc-search-fields'))
      : {...defaultLSRDocumentTypes}

    // Get list of fields that should be included in search by index
    const lsrFields = Object.keys(storedLsrFilters).filter(k => k !== "Check All" && storedLsrFilters[k]).map(f => lsrFieldsToIdx[f])
    const docFields = Object.keys(storedDocFilters).filter(k => k !== "Check All" && storedDocFilters[k]).map(f => docFieldsToIdx[f])

    // Make array of 0s of length equal to number of fields
    let lsrBoolString = new Array(LsFields.length - allFileFields.length - 1).fill(0)
    let docBoolString = new Array(allFileFields.length - 1).fill(0)

    // Convert those included in search to 1s
    lsrFields.forEach(ele => lsrBoolString[ele] = 1)
    docFields.forEach(ele => docBoolString[ele] = 1)

    if (lsrBoolString.every(ele => ele === 0) && docBoolString.every(ele => ele === 0) && search !== '') {
      setSearch('')
      alert('You must select at least one field to search')
    }
    else {
      for (let key in filters) {
        if (filters[key].check) {
          combination[key] = filters[key].value;
        }
      }
      const filtered = {
        bool: Object.values(combination).length > 0,
        url: encodeURI(JSON.stringify(combination)),
      };

      if (props.lsRequests.length > 0) {
        props.clearLsRequestArray();
      }
      props.loadLsRequests(null, sort, filtered);
      setFiltered(filtered);
    }
  }

  const buttonFilterToggle = (e, days) => {
    const dayButtons = document.getElementsByClassName(
      styles['day-toggle-buttons']
    );
    for (let i = 0; i < dayButtons.length; i++) {
      dayButtons[i].classList.remove('focus');
    }
    e.target.classList.add('focus');
    let values = { ...filterValues };
    values['last_activated'].check = true;
    values['last_activated'].value = days;
    setFilterValues(values);

    applyFilters(searchInput, values, sortedBy);
  };
  /**
   * * END FILTER HANDLING FUNCTIONS
   */

  function onGetLsRequest(ls) {
    props.clickedLsRequest(ls || props.updateTargetLs, 'dropdown');
  }
  function onSortByHeader(field) {
    // If the field differs from current sort, set it to new field (as descending). Otherwise, alternate asc/desc for current field.
    let newSortedBy =
      sortedBy[0] === '-' && field === sortedBy.substring(1)
        ? field
        : '-' + field;
    setSortedBy(newSortedBy);

    if (props.lsRequests.length > 0) {
      props.clearLsRequestArray();
    }
    props.loadLsRequests(null, newSortedBy, filtered);
  }

  function onOpenOrCloseSidebar(isOpen) {
    setSidebarOpen(isOpen);
    if (!isOpen) {
      document.body.style.overflow = 'auto';
    }
  }

  function loadMoreLsRequests() {
    if (props.nextApi) {
      console.log('props.nextApi', props.nextApi);
      props.loadLsRequests(props.nextApi, sortedBy, filtered);
    }
  }

  function scrollToTop() {
    document.getElementById('lsTable').scrollTo({ top: 0 });
  }

  /**
   * * DUPLICATE CHECKER FUNCTIONS
   */
  function toggleSearchType(searchType) {
    setCurrentSearchType(searchType);
    scrollToTop();
  }

  /**
   * Mark LS Request as overlapping
   */
  function markOverlapping(ls) {
    // If it did not exist as overlapping, add to overlap duplicates array
    if (overlapDuplicates.indexOf(ls.ls_number) === -1) {
      // Get the current dupBucket from localStorage

      // Update the state for overlapDuplicates and relatedDuplicates
      const updatedOverlapDuplicates = [...overlapDuplicates, ls.ls_number];

      const updatedRelatedDuplicates = relatedDuplicates.filter(
        e => e !== ls.ls_number
      );
      setOverlapDuplicates(updatedOverlapDuplicates);
      setRelatedDuplicates(updatedRelatedDuplicates);

      // If not previously in dupArray, add ls object to array
      const updatedDupArray = [...dupArray, ls];
      if (!dupArray.find(dupe => dupe.ls_number === ls.ls_number)) {
        setDupArray(updatedDupArray);
      }

      let dupBucket = JSON.parse(localStorage.getItem('dupBucket')) || [];

      // Find the index of the object with the matching ls_number
      const index = dupBucket.findIndex(
        item => item.ls_number === mainLs.ls_number
      );

      // If the LS exists in localStorage, update its relatedDuplicates and overlapDuplicates
      if (index !== -1) {
        dupBucket[index].relatedDuplicates = updatedRelatedDuplicates;
        dupBucket[index].overlapDuplicates = updatedOverlapDuplicates;
        if (
          !dupBucket[index].dupArray.find(
            dupe => dupe.ls_number === ls.ls_number
          )
        ) {
          dupBucket[index].dupArray = updatedDupArray;
        }

        // If the LS does not exist, create a new object and add it to the dupBucket
      } else {
        dupBucket.push({
          ls_number: mainLs.ls_number,
          overlapDuplicates: updatedOverlapDuplicates,
          relatedDuplicates: updatedRelatedDuplicates,
          dupArray: updatedDupArray,
        });
      }
      localStorage.setItem('dupBucket', JSON.stringify(dupBucket));
    }
  }

  /**
   * Mark LS Request as related
   */
  function markRelated(ls) {
    // If it did not exist as related, add to related duplicates array
    if (relatedDuplicates.indexOf(ls.ls_number) === -1) {
      // Update the state for relatedDuplicates and overlapDuplicates
      const updatedRelatedDuplicates = [...relatedDuplicates, ls.ls_number];
      const updatedOverlapDuplicates = overlapDuplicates.filter(
        e => e !== ls.ls_number
      );
      setRelatedDuplicates(updatedRelatedDuplicates);
      setOverlapDuplicates(updatedOverlapDuplicates);

      // If not previously in dupArray, add ls object to array
      const updatedDupArray = [...dupArray, ls];
      if (!dupArray.find(dupe => dupe.ls_number === ls.ls_number)) {
        setDupArray(updatedDupArray);
      }

      // Get the current dupBucket from localStorage
      let dupBucket = JSON.parse(localStorage.getItem('dupBucket')) || [];

      // Find the index of the object with the matching ls_number
      const index = dupBucket.findIndex(
        item => item.ls_number === mainLs.ls_number
      );

      if (index !== -1) {
        // If the LS exists in localStorage, update its relatedDuplicates and overlapDuplicates
        dupBucket[index].relatedDuplicates = updatedRelatedDuplicates;
        dupBucket[index].overlapDuplicates = updatedOverlapDuplicates;
        if (
          !dupBucket[index].dupArray.find(
            dupe => dupe.ls_number === ls.ls_number
          )
        ) {
          dupBucket[index].dupArray = updatedDupArray;
        }
      } else {
        // If the LS does not exist, create a new object and add it to the dupBucket
        dupBucket.push({
          ls_number: mainLs.ls_number,
          overlapDuplicates: updatedOverlapDuplicates,
          relatedDuplicates: updatedRelatedDuplicates,
          dupArray: updatedDupArray,
        });
      }

      // Update the dupBucket in localStorage
      localStorage.setItem('dupBucket', JSON.stringify(dupBucket));
    }
  }

  /**
   * Mark LS Request as not duplicate
   * From LS Request from duplicate arrays if it exists.
   */
  function markNotDuplicate(ls) {
    // Update the state
    const updatedOverlapDuplicates = overlapDuplicates.filter(
      e => e !== ls.ls_number
    );
    const updatedRelatedDuplicates = relatedDuplicates.filter(
      e => e !== ls.ls_number
    );
    setOverlapDuplicates(updatedOverlapDuplicates);
    setRelatedDuplicates(updatedRelatedDuplicates);
    setDupArray(dupArray.filter(e => e.ls_number !== ls.ls_number));

    // Get the dupBucket from localStorage
    const dupBucket = JSON.parse(localStorage.getItem('dupBucket')) || [];

    // Find the index of the object with the matching ls_number
    const index = dupBucket.findIndex(
      item => item.ls_number === mainLs.ls_number
    );

    if (index !== -1) {
      // If the LS exists in localStorage, update its overlapDuplicates and relatedDuplicates
      dupBucket[index].overlapDuplicates = updatedOverlapDuplicates;
      dupBucket[index].relatedDuplicates = updatedRelatedDuplicates;

      dupBucket[index].dupArray = dupBucket[index].dupArray.filter(
        dupe => dupe.ls_number !== ls.ls_number
      );
    }
    // Update the dupBucket in localStorage
    localStorage.setItem('dupBucket', JSON.stringify(dupBucket));
  }

  /**
   * Save the currently selected overlapping and related arrays and input whether the check is complete.
   */
  function saveDuplicates(e) {
    e.preventDefault();
    setOverlapDuplicates([]);
    setRelatedDuplicates([]);
    setDupArray([]);

    const dupBucket = JSON.parse(localStorage.getItem('dupBucket')) || [];
    // Find the index of the object with the matching ls_number
    const index = dupBucket.findIndex(
      item => item.ls_number === mainLs.ls_number
    );

    //Take out the LSR that we are doing the duplicate search on from the dupBucket
    dupBucket.splice(index, 1);

    // Update the dupBucket in localStorage
    localStorage.setItem('dupBucket', JSON.stringify(dupBucket));
    setDupArray([]);

    let lsRequest = Object.assign({}, mainLs, {
      first_prime: mainLs.first_prime.id,
      contact_person: mainLs.contact_person.id,
      staff: mainLs.staff.id,
      committee: mainLs.committee.id,
      overlapping_ls: overlapDuplicates,
      related_ls: relatedDuplicates,
      duplicate_check: true,
    });

    props.updateTargetLsRequest(lsRequest);
  }

  return (
    <>
      {mainBarOpen && (
        <div
          style={{
            background: 'white',
            height: '94vh',
            width: '20%',
            float: 'left',
            overflow: 'auto',
          }}>
          <LsInfoDisplay injected_ls={mainLs} infoLocation='sidebar' />
        </div>
      )}
      <div style={{ width: '80%', float: 'right' }}>
        <NewLsMenu
          filterFlag={filterFlag}
          columns={columns}
          columnPreset={columnPreset}
          showColumnMenu={showColumnMenu}
          checkAllColumns={checkAllColumns}
          toggleColumn={toggleColumn}
          toggleColumnMenu={() => setShowColumnMenu(!showColumnMenu)}
          changeColumnPreset={changeColumnPreset}
          toggleAllColumns={toggleAllColumns}
          sidebarOpen={sidebarOpen}
          sortedBy={sortedBy}
          defaultFilterValues={defaultFilterValues}
          filterPreset={filterPreset}
          filterValues={filterValues}
          search={search}
          setSearch={setSearch}
          searchInput={searchInput}
          setSearchInput={setSearchInput}
          showFilterMenu={showFilterMenu}
          onClearFilter={onClearFilter}
          onClearSearch={onClearSearch}
          changeFilterPreset={changeFilterPreset}
          toggleFilterMenu={() => setShowFilterMenu(!showFilterMenu)}
          onAddFilter={onAddFilter}
          onRemoveFilter={onRemoveFilter}
          applyFilters={applyFilters}
          buttonFilterToggle={buttonFilterToggle}
          currentSearchType={currentSearchType}
          toggleSearchType={toggleSearchType}
          dupArray={dupArray}
          saveDuplicates={saveDuplicates}
          setOptionsChanged={setOptionsChanged}
        />
        {
          <LsRequestsTable
            id='lsTable'
            columns={columns}
            sortedBy={sortedBy}
            onSortByHeader={onSortByHeader}
            loadMoreLsRequests={loadMoreLsRequests}
            onGetLsRequest={onGetLsRequest}
            dupArray={dupArray}
            currentSearchType={currentSearchType}
            overlapDuplicates={overlapDuplicates}
            relatedDuplicates={relatedDuplicates}
            currentDupsearchLsNum={mainLs?.ls_number}
            markOverlapping={markOverlapping}
            markRelated={markRelated}
            markNotDuplicate={markNotDuplicate}
            onChangeCreateSubLS={() => setCurtPopup('createSubLS')}
            onChangeToBillHistory={() => setCurtPopup('history')}
            onOpenOrCloseSidebar={e => onOpenOrCloseSidebar(e)}
            optionsChanged={optionsChanged}
          />
        }
        {sidebarOpen && (
          <Sidebar
            onSetOpen={e => {
              onOpenOrCloseSidebar(e);
            }}
            open={sidebarOpen}
            pullRight={true}
            sidebar={
              <LsInfoDisplay
                infoLocation='sidebar'
                onOpenOrCloseSidebar={e => onOpenOrCloseSidebar(e)}
              />
            }
            styles={{
              sidebar: {
                background: 'white',
                zIndex: 4,
                overflow: 'hidden',
                maxWidth: '350px',
                width: '100%',
                marginTop: '55px',
                marginRight: '30px',
                borderRadius: '8px 8px 0px 0px',
                bottom: '30px',
              },
              overlay: { zIndex: 3 },
            }}>
            <div style={{ display: 'none' }}></div>
          </Sidebar>
        )}
      </div>
    </>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    lsRequests: state.lsRequestsReducer.lsRequests,
    nextApi: state.lsRequestsReducer.nextApi,
    lsRequestServicePopupStatus:
      state.lsRequestsReducer.lsRequestServicePopupStatus,
    updateTargetLs: state.lsRequestsReducer.updateTargetLs,
  };
};

const mapDispatchToProps = {
  loadLsRequests,
  clearLsRequestArray,
  fetchOneLsr,
  updateTargetLsRequest,
  checkDuplicate,
};

export default connect(mapStateToProps, mapDispatchToProps)(LsDupSearch);
