import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import lsStyles from '../../styles/ls-request/LsRequestsList.module.scss';
import styles from '../../styles/ls-request/NewLsMenu.module.scss';
import CheckboxMenu from '../library/CheckboxMenu';
import FilterMenu from './FilterMenu';
import { exportLsRequest, storeSearchTerms } from '../../actions/lsRequestAction';
import { csvHeaderCM, csvHeaderNonCM } from '../../services/LsFields';
import { rolesUnderCouncilMembers } from '../../services/constants';
import { CSVLink } from 'react-csv';
import {
  PlusIcon,
  SaveIcon,
  OptionsGearIcon,
  ChevronDownRegularIcon,
  ChevronUpRegularIcon,
  SearchIcon,
  InfoCircleFilledIcon,
  ColumnsFilterIcon,
  SliderFilterIcon,
  CloseButtonCircleIcon,
} from '../../services/SvgLibrary';
import { isConfidential } from '../../utils/helper';
import LsAdvancedSearch from './LsAdvancedSearch';

const NewLsMenu = props => {
  /** COLUMN PROPS */
  const {
    columns,
    columnPreset,
    showColumnMenu,
    checkAllColumns,
    toggleColumn,
    toggleColumnMenu,
    changeColumnPreset,
    toggleAllColumns,
    storeSearchTerms
  } = props;

  /** FILTER PROPS */
  const {
    filterValues,
    filterPreset,
    searchInput,
    setSearchInput,
    showFilterMenu,
    onClearFilter,
    onClearSearch,
    changeFilterPreset,
    toggleFilterMenu,
    onAddFilter,
    onRemoveFilter,
    applyFilters,
    buttonFilterToggle,
    filterFlag,
    statusChangeRequestCounter,
    setOptionsChanged
  } = props;

  /** DUPLICATE CHECKER PROPS */
  const { currentSearchType, toggleSearchType, dupArray, saveDuplicates } =
    props;
  /** OTHER PROPS */
  const { onOpenCreate, onOpenStatusChange, sidebarOpen, userProfile, search, setSearch } = props;
  //**state */
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [scrollDown, setScrollDown] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(null);
  const [activeView, setActiveView] = useState('LSR');
  const advancedOptionsRef = useRef(null);
  const toggleButtonRef = useRef(null);

  const handleActiveViewChange = view => {
    setActiveView(view);
  };
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  // Defining Scroll event "handler" to detect scroll direction and display/hide the 'add LS' button:
  const handleScroll = () => {
    const scrollWindow = document.getElementsByClassName(
      `${lsStyles['list-table']} list-table`
    )[0].children[1];

    // Logic to handle the scroll event
    const currentScrollPosition = scrollWindow.getBoundingClientRect().top;
    setScrollPosition(currentScrollPosition);
    setScrollDown(
      !(scrollWindow.getBoundingClientRect().top >= scrollPosition)
    );
  };
  const handleSaveDuplicate = e => {
    e.preventDefault();
    setIsButtonDisabled(true);
    saveDuplicates(e);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setScreenWidth(window.innerWidth);
    });

    // Adding Scroll event "listener" when the component mounts to detect scroll direction and display/hide the 'add LS' button:
    const scrollElement = document.getElementById('lsTable');
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    // Returning a cleanup function to remove the event listeners
    return () => {
      window.removeEventListener('resize', () => {
        setScreenWidth(window.innerWidth);
      });
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollPosition]);

  const noLinebreakFunction = ls => {
    let lsCopy = Object.assign({}, ls);
    for (let [key, value] of Object.entries(lsCopy)) {
      if (typeof value === 'string') {
        // String values may have escaped whitespace or HTML encoding that need replacing
        lsCopy[key] = value
          .replace(/(\r\n|\n|\r)/gm, ' ')
          .replace(/"/g, "\\'")
          .replace('&#60;', '<')
          .replace('&#62;', '>');
      } else if (typeof value === 'object') {
        // If the value is an object that means it's one our relational fields
        if (key === 'co_prime') {
          // For co-prime field
          lsCopy[key] = isConfidential(
            props.userProfile,
            lsCopy.make_first_prime_public
          )
            ? 'CONFIDENTIAL'
            : value.map(coPrime => coPrime.full_name).join(', ');
        } else if (['overlapping_ls', 'related_ls'].includes(key)) {
          // For the overlapping or related LS field
          lsCopy[key] = value.map(ls => ls.ls_number).join(', ');
        } else if (value?.full_name) {
          // For first prime, staff, contact person, and submitted by fields
          lsCopy[key] = value.full_name;
        } else if (Array.isArray(value) && value.length > 0) {
          // For the file fields like Operational Requirement/Milestone attachments, etc.
          if (key === 'associated_report_tracking') {
            let joinedReports = [];
            for (let report of value) {
              // 'value' when key is associated_report_tracking is an array of objects/reports
              if ('attachment' in report) {
                let reportAttachmentLinks = report['attachment']
                  .map(file => file.file)
                  .join('\r\n');
                joinedReports.push(reportAttachmentLinks);
              }
            }
            lsCopy[key] = joinedReports.join('\r\n');
          } else {
            lsCopy[key] = value.map(file => file.file).join('\r\n');
          }
        } else if (key === 'committee') {
          // For committee field
          lsCopy[key] = value.committee_name;
        }
      } else {
        // Anything else like boolean values, integers, etc.
        lsCopy[key] = value;
      }
    }
    return lsCopy;
  };

  const filterCountList = rolesUnderCouncilMembers.includes(
    props.userProfile.role
  )
    ? [
        'committee',
        'submitted_by',
        'division',
        'first_in_time',
        'ls_number',
        'ls_type',
        'staff',
        'status',
      ]
    : [
        'committee',
        'submitted_by',
        'division',
        'first_prime',
        'first_in_time',
        'ls_number',
        'ls_type',
        'staff',
        'status',
      ];
  const filterCount = filterCountList.filter(
    field => filterValues[field].check
  ).length;
  const columnCount = columns.filter(
    field => field.show && field.field !== 'ls_number'
  ).length;

  const [showASCheckboxMenu, setShowASCheckboxMenu] = useState(false);

  const toggleAdvancedOptions = event => {
    event.stopPropagation(); // Prevent the event from bubbling up
    setShowASCheckboxMenu(prevShow => !prevShow);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        showASCheckboxMenu &&
        advancedOptionsRef.current &&
        !advancedOptionsRef.current.contains(event.target) &&
        !toggleButtonRef.current.contains(event.target)
      ) {
        // Check if the click is not on the button
        setShowASCheckboxMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showASCheckboxMenu]);
  return (
    <div className={`container-fluid ${!filterFlag && 'gx-0'}`}>
      {filterFlag && (
        <div
          className={`d-flex align-items-center ${styles['search-menu-container']}`}>
          {window.location.pathname.includes('LsDupSearch') &&
            currentSearchType === 'Automated' && (
              <div style={{ position: 'absolute', left: '50%' }}>
                <h1
                  style={{
                    textAlign: 'center',
                    color: '#4a5f96',
                    marginBottom: '0px',
                  }}>
                  Automated Search
                </h1>
              </div>
            )}
          {window.location.pathname.includes('LsDupSearch') &&
            currentSearchType === 'Review' && (
              <div style={{ position: 'absolute', left: '50%' }}>
                <h1
                  style={{
                    textAlign: 'center',
                    color: '#4a5f96',
                    marginBottom: '0px',
                  }}>
                  Selected Duplicates
                </h1>
              </div>
            )}
          {!window.location.pathname.includes('LsDupSearch') &&
            props.userProfile.permissions.lsRequest_api.add_lsrequest && (
              <button
                title='Create New LSR'
                className={`${styles['create-ls-button']} ${
                  sidebarOpen && styles['open-sidebar']
                } ${scrollDown && styles['hide']}`}
                id='create-ls-button'
                onClick={onOpenCreate}>
                <PlusIcon />{' '}
                <span className={styles['create-new-text']}>
                  Create New LSR
                </span>
              </button>
            )}
          {!(
            window.location.pathname.includes('LsDupSearch') &&
            !(currentSearchType === 'Manual')
          ) && (
            <>
              <div
                className={`${styles['input-search-wrapper']} ${
                  showASCheckboxMenu && styles['expanded']
                }`}>
                <div className='d-flex align-items-center'>
                  {props.isLoading ? (
                    <div
                      className={`spinner-border ${
                        styles['ls-loading-spinner']
                      } ${filterCount > 0 && styles['filter']}`}
                      role='status'>
                      <span className='visually-hidden'>Loading...</span>
                    </div>
                  ) : (
                    <SearchIcon
                      width='24'
                      height='24'
                      classNameProp={styles['search-icon']}
                      onClickFunction={() => applyFilters()}
                    />
                  )}
                  <input
                    type='text'
                    maxLength={300}
                    className={`border-0 h-100 w-100 ${
                      styles['input-search-box']
                    } ${filterCount > 0 && styles['filter']}`}
                    placeholder='Search LS Requests with Phrases or Boolean Logic Operators'
                    value={search}
                    disabled={props.isLoading}
                    onChange={e => setSearch(e.target.value)}
                    onBlur={e => {
                      if (search !== searchInput) {
                        setSearchInput(search);
                        storeSearchTerms(search)
                        applyFilters(search);
                      }
                    }}
                    onKeyDown={e => {
                      if (e.keyCode === 13) {
                        setSearchInput(search);
                        storeSearchTerms(search)
                        applyFilters(search);
                      }
                    }}
                  />
                  <div>
                    {300 - search.length}/300
                  </div>
                  {!props.isLoading && search !== '' && (
                    <CloseButtonCircleIcon
                      width='24'
                      height='24'
                      classNameProp={styles['clear-search-icon']}
                      onMouseDownFunction={() => {
                        setSearch('');
                        storeSearchTerms('')
                        onClearSearch();
                      }}
                    />
                  )}
                  <div className={styles['vertical-line']}></div>
                  <button
                    type='button'
                    ref={toggleButtonRef}
                    className={`btn btn-sm p-0 me-3 border-0 fw-bolder ${styles['adv-button']}`}
                    onClick={event => toggleAdvancedOptions(event)}>
                    {/* {!showASCheckboxMenu ? (
                      <>
                        Show Advanced <ChevronDownFillIcon />
                      </>
                    ) : (
                      <>
                        Hide Advanced <ChevronUpFillIcon />
                      </>
                    )} */}
                    Options
                    {!showASCheckboxMenu ? (
                      <ChevronDownRegularIcon />
                    ) : (
                      <ChevronUpRegularIcon />
                    )}
                  </button>
                </div>
                {showASCheckboxMenu && (
                  <div
                    ref={advancedOptionsRef}
                    className='d-flex w-100 flex-column pt-3 mt-3'
                    style={{
                      backgroundColor: '#f7f7f7',
                      borderRadius: '25px',
                      maxHeight: 'calc(100vh - 130px)',
                      position: 'relative',
                    }}>
                    <LsAdvancedSearch
                      activeView={activeView}
                      onActiveViewChange={handleActiveViewChange}
                      setOptionsChanged={setOptionsChanged}
                    />
                  </div>
                )}
                {/* Commented out. Getting ToolTipFilledIcon is not defined error */}
                <InfoCircleFilledIcon
                  classNameProp={`${styles['search-popover-icon']} ${
                    showASCheckboxMenu && styles['expanded']
                  }`}
                  dataBsToggleProp='modal'
                  dataBsTargetProp='#searchGuideModal'
                />
              </div>
            </>
          )}
        </div>
      )}
      <div
        className={`modal fade ${styles['search-feature-guide-modal-container']}`}
        id='searchGuideModal'
        tabIndex='-1'
        aria-labelledby='searchGuideModalLabel'
        aria-hidden='true'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title' id='searchGuideModalLabel'>
                Advanced Search Tips for LS Request Search Bar
              </h5>
              <button
                type='button'
                className='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'></button>
            </div>
            <div
              className={`modal-body ${styles['search-feature-guide-modal-body']}`}>
              <p>
              The LS Request search bar supports both traditional keyword searches
               and advanced Boolean logic for precise filtering. Here's a concise
                guide to help you maximize its potential:
              </p>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  <h5>Basic Usage</h5>
                  <p>Simply type a word or phrase to perform a search.
                    Use the dropdown on the right to select fields for your search.
                    By default, all fields are searched; uncheck boxes to narrow results.</p>
                </li>
                 <li>
                  <h5>Boolean Operators</h5>
                  <p>Boolean operators allow you to combine or exclude terms. The default order of operations is:</p>
                  <p><strong>NOT (!)</strong> &gt; <strong>AND (&)</strong> &gt; <strong>OR (|)</strong>. 
                  Use parentheses <code>()</code> to override this order.</p>
                 </li>
                <li>
                  <h5>
                    NOT: <code>!</code>
                  </h5>
                  <p>Excludes specific terms, phrases, or groups. Example: <code>transportation & !bridges</code> finds results containing "transportation" but not "bridges.".
                  <br/>
                  To exclude a phrase, place <code>!</code> outside quotation marks:. Example: <code>!"New York City"</code> excludes this exact phrase.</p>
                </li>         
                <li>
                  <h5>
                    AND: <code>&amp;</code>
                  </h5>
                  <p>Includes results containing all specified terms. Typing terms without operators converts spaces between words to <code>&</code>.<br/>
                  Example: <code>transportation department fields</code> is the same as <code>transportation & department & fields</code>. Both searches will find results containing all terms.</p>
                </li>
                <li>
                  <h5>
                    OR: <code>|</code>
                  </h5>
                  <p>
                  Includes results containing any of the specified terms. Example: <code>transportation | bridges</code> finds results with either or both terms.
                  </p>
                </li>
                <li>
                  <h5>
                    Parentheses: <code>( )</code>
                  </h5>
                  <p>
                  Group terms to control precedence. Example: <code>roads & ("school bus" | transport)</code> ensures {' '}
                  <code>"school bus"</code> OR <code>transport</code> is resolved first, then combined with <code>roads</code>.
                  </p>
                </li>
                <li>
                  <h5>
                    Quotations: <code>" "</code>
                  </h5>
                  <p>
                  Search for exact phrases, spacing included. Example: <code>"dogs look like foxes"</code> returns only results with this exact phrase.
                  </p>
                </li>
                <li>
                  <h5>
                    Wildcard(s): <code>*</code>
                  </h5>
                  <p>
                  Use at the start, end, or within words to match variations. Example: <code>inter*</code> matches <code>interest</code>, <code>international</code>, etc.; <code>*inter*</code> also matches <code>winter</code> and <code>splintering</code>.                  </p>
                </li>
              </ul>
              <div className='alert alert-warning' role='alert'>
                <b>Tips and Notes:</b>
                <ul>
                  <li><strong>Operator Precedence:</strong> Use parentheses and remember the default order (<code>!</code> &gt; <code>&</code> &gt; <code>|</code>).</li>
                  <li><strong>De Morgan’s Laws</strong> (for NOT):
                    <ul>
                      <li>NOT (A AND B) = NOT A OR NOT B</li>
                      <li>NOT (A OR B) = NOT A AND NOT B</li>
                    </ul>
                  </li>
                  <li><strong>Avoid Symbol Errors:</strong> Remove <code>!</code>, <code>&</code>, <code>|</code> from copied text unless used intentionally for Boolean logic.</li>
                  <li><strong>Session Persistence:</strong> Field selections persist until logout or session expiry, after which all fields are reselected by default.</li>
                </ul>          
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='row g-0 px-2 justify-content-between mb-2'>
        <div className='col d-flex justify-content-start align-items-center'>
          {window.location.pathname.toLowerCase().includes('myactivels') &&
            screenWidth > 1233 && (
              <div
                className={`btn-group btn-group-sm ${styles['day-toggle-buttons-container']}`}
                role='group'
                aria-label='Day toggle buttons'>
                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='daytoggle60'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, '60')}
                />
                <label className='btn btn-primary' for='daytoggle60'>
                  &ge; 60 days
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='daytoggle55'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, '55')}
                />
                <label className='btn btn-primary' for='daytoggle55'>
                  &ge; 55 days
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='daytoggle45'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, '45')}
                />
                <label className='btn btn-primary' for='daytoggle45'>
                  &ge; 45 days
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='daytoggle30'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, '30')}
                />
                <label className='btn btn-primary' for='daytoggle30'>
                  &ge; 30 days
                </label>

                <input
                  type='radio'
                  className='btn-check'
                  name='btndaytoggle'
                  id='alldaytoggle'
                  autocomplete='off'
                  onClick={e => buttonFilterToggle(e, 'all')}
                />
                <label className='btn btn-primary' for='alldaytoggle'>
                  All
                </label>
              </div>
            )}
          {/* START Columns Filter Button */}
          <button
            id='columnMenuToggle'
            className={`btn me-2 d-flex align-items-center ${styles['table-filter-icon-btn']}`}
            onClick={() => {
              if (showFilterMenu && !showColumnMenu) {

                toggleFilterMenu();
              }
              toggleColumnMenu();
            }}>
            <div
              className={`position-relative d-inline-flex ${
                screenWidth > 768 && 'me-2'
              }`}>
              <ColumnsFilterIcon width='21' height='21' />
              {columnCount > 0 && (
                <span
                  className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${styles['counter']}`}>
                  {columnCount}
                  <span className='visually-hidden'>
                    columns filter selected
                  </span>
                </span>
              )}
            </div>
            {screenWidth > 768 && 'Columns'}
          </button>
          {/* END Columns Filter Button */}
          {!(
            window.location.pathname.includes('LsDupSearch') &&
            currentSearchType !== 'Manual'
          ) && (
            <button
              id='filterMenuToggle'
              className={`btn me-2 d-flex align-items-center ${
                !filterFlag && 'd-none'
              } ${styles['table-filter-icon-btn']}`}
              onClick={() => {
                if (showColumnMenu && !showFilterMenu) {
                  toggleColumnMenu();
                }
                toggleFilterMenu();
              }}>
              <div
                className={`position-relative d-inline-flex ${
                  screenWidth > 768 && 'me-2'
                }`}>
                <SliderFilterIcon width='21' height='21' />
                {filterCount > 0 && (
                  <span
                    className={`position-absolute top-0 start-100 translate-middle badge rounded-pill ${styles['counter']}`}>
                    {filterCount}
                    <span className='visually-hidden'>filter selected</span>
                  </span>
                )}
              </div>
              {screenWidth > 768 && 'Filters'}
            </button>
          )}
          {!props.isLoading &&
            !(
              window.location.pathname.includes('LsDupSearch') &&
              currentSearchType !== 'Manual'
            ) &&
            filterFlag && (
              <span className={`${styles['results-count']} text-nowrap`}>
                <strong>
                  {props.searchCount
                    ? Number(props.searchCount).toLocaleString()
                    : 0}
                </strong>{' '}
                Matching Results
              </span>
            )}
        </div>
        <div className='col d-flex justify-content-end align-items-center'>
          {window.location.pathname.includes('LsDupSearch') && (
            <>
              <button
                className={`btn text-white text-nowrap me-2 fw-bolder ${styles['lsr-action-btn']}`}
                type='button'
                onClick={e => handleSaveDuplicate(e)}
                disabled={isButtonDisabled}>
                {isButtonDisabled ? (
                  <span
                    className='spinner-border spinner-border-sm'
                    role='status'
                    aria-hidden='true'
                  />
                ) : (
                  <SaveIcon height='19px' width='19px' />
                )}{' '}
                Save Duplicates
              </button>
              <div className='dropdown'>
                <button
                  className={`btn btn-secondary dropdown-toggle fw-bolder position-relative text-white border-0 ${styles['options-dropdown']}`}
                  type='button'
                  id='lsRequestExport'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'>
                  <span>{currentSearchType}</span>
                </button>
                <ul
                  className={`dropdown-menu ${styles['lsr-options-dropdown-menu']}`}
                  aria-labelledby='lsRequestExport'>
                  {currentSearchType !== 'Automated' && (
                    <li
                      className='dropdown-item'
                      onClick={() => {
                        toggleSearchType('Automated');
                      }}>
                      Automated
                    </li>
                  )}
                  {currentSearchType !== 'Manual' && (
                    <li
                      className='dropdown-item'
                      onClick={() => {
                        toggleSearchType('Manual');
                      }}>
                      Manual
                    </li>
                  )}
                  {currentSearchType !== 'Review' && dupArray.length > 0 && (
                    <li
                      className='dropdown-item'
                      onClick={() => {
                        if (dupArray.length) toggleSearchType('Review');
                      }}>
                      Review {dupArray.length > 0 && `(${dupArray.length})`}
                    </li>
                  )}
                </ul>
              </div>
            </>
          )}
          {!window.location.pathname.includes('LsDupSearch') && (
            <>
              {' '}
              {screenWidth > 768 &&
                userProfile.permissions.lsRequest_api.approve_status_change && (
                  <button
                    hidden={!filterFlag}
                    className={`btn text-white text-nowrap me-2 fw-bolder ${styles['lsr-action-btn']}`}
                    type='button'
                    onClick={onOpenStatusChange}>
                    <span className={`badge me-1 ${styles['badge-count']}`}>
                      {statusChangeRequestCounter}
                    </span>
                    Status Change Request
                    {statusChangeRequestCounter > 0 && 's'}
                  </button>
                )}
              <div className='dropdown'>
                <button
                  hidden={!filterFlag}
                  className={`btn dropdown-toggle fw-bolder text-white ${styles['options-dropdown']}`}
                  type='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'>
                  <OptionsGearIcon /> Options
                  {screenWidth <= 768 && statusChangeRequestCounter > 0 && (
                    <span className='position-absolute top-0 start-100 translate-middle p-2 bg-warning border border-light rounded-circle'>
                      <span className='visually-hidden'>New alerts</span>
                    </span>
                  )}
                </button>
                <ul
                  className={`dropdown-menu ${styles['lsr-options-dropdown-menu']}`}>
                  {props.userProfile.permissions.lsRequest_api
                    .add_lsrequest && (
                    <>
                      <li className='dropdown-item' onClick={onOpenCreate}>
                        Create New LSR
                      </li>
                      {screenWidth < 768 && (
                        <li
                          className='dropdown-item'
                          onClick={onOpenStatusChange}>
                          Status Change Request
                          {statusChangeRequestCounter > 0 && 's'}
                          <span
                            className={`badge ms-2 ${styles['badge-count']}`}>
                            {statusChangeRequestCounter}
                          </span>
                        </li>
                      )}
                      <hr className='dropdown-divider' />
                    </>
                  )}
                  {window.location.pathname
                    .toLowerCase()
                    .includes('myactivels') &&
                    screenWidth < 1233 && (
                      <>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggle60').click()
                          }>
                          <label
                            for='daylisttoggle60'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggle60'
                              onClick={e => buttonFilterToggle(e, '60')}
                            />
                            View 60 days
                          </label>
                        </li>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggle55').click()
                          }>
                          <label
                            for='daylisttoggle55'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggle55'
                              onClick={e => buttonFilterToggle(e, '55')}
                            />
                            View 55 days
                          </label>
                        </li>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggle45').click()
                          }>
                          <label
                            for='daylisttoggle45'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggle45'
                              onClick={e => buttonFilterToggle(e, '45')}
                            />
                            View 45 days
                          </label>
                        </li>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggle30').click()
                          }>
                          <label
                            for='daylisttoggle30'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggle30'
                              onClick={e => buttonFilterToggle(e, '30')}
                            />
                            View 30 days
                          </label>
                        </li>
                        <li
                          className='dropdown-item'
                          onClick={() =>
                            document.getElementById('daylisttoggleall').click()
                          }>
                          <label
                            for='daylisttoggleall'
                            className='d-flex align-items-center'>
                            <input
                              className='form-check-input me-2'
                              type='radio'
                              name='daylisttoggle'
                              value=''
                              id='daylisttoggleall'
                              onClick={e => buttonFilterToggle(e, 'all')}
                            />
                            View All
                          </label>
                        </li>
                        <hr className='dropdown-divider' />
                      </>
                    )}
                  <li className='dropdown-item'>
                    <CSVLink
                      className='text-decoration-none'
                      headers={
                        rolesUnderCouncilMembers.includes(
                          props.userProfile.role
                        )
                          ? csvHeaderCM
                          : csvHeaderNonCM
                      }
                      data={props.lsRequests.map(l => noLinebreakFunction(l))}
                      filename={`LS-export.csv`}>
                      Export Current View
                    </CSVLink>
                  </li>
                  {document.cookie.indexOf('exported_recently=true') === -1 &&
                    !rolesUnderCouncilMembers.includes(
                      props.userProfile.role
                    ) && (
                      <li
                        className='dropdown-item'
                        onClick={props.exportLsRequest}>
                        Export Full Log
                      </li>
                    )}
                  {document.cookie.indexOf('exported_recently=true') !== -1 &&
                    !rolesUnderCouncilMembers.includes(
                      props.userProfile.role
                    ) && (
                      <li
                        className='dropdown-item'
                        style={{
                          cursor: 'not-allowed',
                          pointerEvents: '  auto',
                        }}
                        disabled>
                        Export Full Log
                      </li>
                    )}
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
      <div
        aria-hidden={!props.exporting}
        aria-modal={props.exporting}
        className={`modal fade ${props.exporting ? 'show' : ''}`}
        role='dialog'
        style={{
          display: props.exporting ? 'block' : 'none',
          backgroundColor: props.exporting ? 'rgba(0,0,0,.4)' : 'transparent',
        }}
        tabIndex='-1'>
        <div className='modal-dialog modal-dialog-centered'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Please wait...</h5>
            </div>
            <div className='modal-body'>
              <p>
                We are processing your request. This may take a few minutes.
              </p>
            </div>
          </div>
        </div>
      </div>
      {showFilterMenu && (
        <FilterMenu
          filterValues={filterValues}
          filterPreset={filterPreset}
          changeFilterPreset={changeFilterPreset}
          toggleFilterMenu={toggleFilterMenu}
          onClearFilter={onClearFilter}
          onAddFilter={onAddFilter}
          onRemoveFilter={onRemoveFilter}
          applyFilters={applyFilters}
          setOptionsChanged={setOptionsChanged}
        />
      )}
      {showColumnMenu && (
        <CheckboxMenu
          idValue='columnMenuToggle'
          filterFlag={filterFlag}
          checkboxes={columns}
          preset={columnPreset}
          toggleCheckbox={toggleColumn}
          togglePreset={changeColumnPreset}
          checkAll={checkAllColumns}
          isOpen={showColumnMenu}
          toggleCheckboxMenu={toggleColumnMenu}
          toggleAllCheckboxes={toggleAllColumns}
          params='title'
          presetNameBase='column'
          alphabetize={true}
          skipFirst={true}
          lsField={true}
          inputStyle={styles['column-selector-container']}
        />
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    searchCount: state.lsRequestsReducer.fullCount,
    lsRequests: state.lsRequestsReducer.lsRequests,
    userProfile: state.userReducer.userProfile,
    isLoading: state.lsRequestsReducer.isLoading,
    exporting: state.lsRequestsReducer.exporting,
  };
};

const mapDispatchToProps = {
  exportLsRequest,
  storeSearchTerms
};

export default connect(mapStateToProps, mapDispatchToProps)(NewLsMenu);
