import { lsRequestActions } from '../actions/lsRequestAction';
import {defaultLSRDocumentTypes, defaultLSRFieldTypes} from '../services/constants'

// This is the initial state that created and stored in the redux store
const initialState = {
  fullCount: null,
  invalidSearch: false,
  lsRequests: [],
  nextApi: null,
  prevApi: null,
  isLoading: null,
  lsRequestStatusApprovals: [],
  newCreateLsNumber: null,
  newCreateLsStatus: null,
  lsRequestServicePopupStatus: false,
  updateTargetLs: {},
  updateTargetLsStatus: null,
  targetLsNumber: null,
  duplicateCheckResults: [],
  isDuplicateLoading: false,
  duplicateInput: [],
  billHistoryList: [],
  lsHistoryList: [],
  lsHistoryCurrent: null,
  selectedLsHistory: null,
  selectLsHistoryIndex: -1,
  errorMessage: '',
  savedProgress: null,
  exporting: false,
  lsDocSearchFields: [],
  lsSearchFields: [],
  highlights: [],
  searchTerms: ''
};


// Reducer is for data manipulation and return back the new state to the redux store
// Redux store will generate the virtual dom based on state and compare the differences
// to decide whether or not re-render the component
// Reducer functions is triggered by matching the action type
// Action type is defined in the actions folder
export default function reducer(state = initialState, action) {
  switch (action.type) {
    case lsRequestActions.STORE_LS_REQUESTS:
      let actionLS;
      if (action.lsRequests.hasOwnProperty('results')) {
        actionLS = action.lsRequests.results.hasOwnProperty('ls')
          ? action.lsRequests.results.ls
          : action.lsRequests.results;
        // actionLS = action.lsRequests.results
      } else {
        actionLS = action.lsRequests.hasOwnProperty('ls')
          ? action.lsRequests.ls
          : action.lsRequests;
        // actionLS = action.lsRequests
      }
      return {
        ...state,
        lsRequests: [...state.lsRequests, ...actionLS],
        prevApi: action.prevApi,
        nextApi: action.nextApi,
        fullCount: action.fullCount,
        isLoading: action.isLoading,
        invalidSearch: action.invalidSearch,
        duplicateInput: [],
        highlights: action.highlights
      };
    case lsRequestActions.STORE_LS_REQUEST_STATUS_APPROVALS:
      return { ...state, lsRequestStatusApprovals: action.lsRequests };
    case lsRequestActions.STORE_SEARCH_TERMS:
      return {...state, searchTerms: action.search}
    case lsRequestActions.STORE_HIGHLIGHTED_LS_REQUESTS:
      return {...state, lsRequests: [...state.lsRequests, ...action.lsRequests]}

    case lsRequestActions.DELETE_FILE:
      // The deleteFileResponse function in lsRequest saga actually deletes the file from the database.
      // Below only "aesthetically" removes the selected file from our redux store
      let lsRequests = [...state.lsRequests];
      let ls_index = lsRequests.findIndex(
        ls => ls.ls_number === action.ls.ls_number
      );
      lsRequests[ls_index][action.field] = lsRequests[ls_index][
        action.field
      ].filter(file => file.id !== action.id);
      let updateTargetLs = { ...state.updateTargetLs };
      updateTargetLs[action.field].filter(file => file.id !== action.id);
      return {
        ...state,
        lsRequests: lsRequests,
        updateTargetLs: updateTargetLs,
      };
    case lsRequestActions.CHECK_LS_IS_LOADING:
      return { ...state, isLoading: action.status };
    case lsRequestActions.SAVE_LS_PROGRESS:
      return { ...state, savedProgress: action.ls };
    case lsRequestActions.STORE_PAGINATION_NEXT:
      return { ...state, nextApi: action.nextApi };
    case lsRequestActions.STORE_PAGINATION_PREV:
      return { ...state, prevApi: action.prevApi };
    case lsRequestActions.STORE_SEARCH_RESULT_COUNT:
      return { ...state, fullCount: action.count };
    case lsRequestActions.CREATE_NEW_LS_REQUEST_STATUS:
      return { ...state, newCreateLsStatus: action.status };
    case lsRequestActions.GET_NEW_CREATED_LS_NUMBER:
      return { ...state, newCreateLsNumber: action.number };
    case lsRequestActions.STORE_TARGET_LS_REQUEST:
      const targetResult = [];
      targetResult.push(action.target);
      return { ...state, lsRequests: targetResult };
    case lsRequestActions.CLEAR_LS_REQUEST_ARRAY:
      return { ...state, lsRequests: [], prevApi: null, nextApi: null };
    case lsRequestActions.OPEN_OR_CLOSE_LS_REQUEST_SERVICE_POPUP:
      return { ...state, lsRequestServicePopupStatus: action.status };
    case lsRequestActions.SAVE_CURRENT_LS_REQUEST:
      return { ...state, updateTargetLs: action.ls };
    case lsRequestActions.UPDATE_TARGET_LS_REQUEST_STATUS:
      return { ...state, updateTargetLsStatus: action.status };
    case lsRequestActions.STORE_DUPLICATE_CHECK_RESULT:
      return { ...state, duplicateCheckResults: action.results };
    case lsRequestActions.STORE_DUPLICATE_LS_REQUEST:
      let duplicateInputs = [];
      if (action.request) {
        state.duplicateInput.unshift(action.request);
        duplicateInputs = state.duplicateInput;
      }
      return { ...state, duplicateInput: duplicateInputs };
    case lsRequestActions.IS_DUPLICATE_LS_LOADING:
      return { ...state, isDuplicateLoading: action.status };
    case lsRequestActions.SAVE_ALL_HISTORY_LIST:
      return { ...state, billHistoryList: action.bills };
    case lsRequestActions.SAVE_ERROR_MESSAGE:
      return { ...state, errorMessage: action.message };
    case lsRequestActions.EXPORT_ALL_LS_REQUESTS:
      return state;
    case lsRequestActions.STORE_LS_REQUEST_HISTORY:
      return {
        ...state,
        lsHistoryList: action.history.history,
        lsHistoryCurrent: action.history.current,
      };
    case lsRequestActions.SELECT_LS_REVISION:
      return {
        ...state,
        selectedLsHistory: action.revision,
        selectLsHistoryIndex: action.index,
      };
    case lsRequestActions.EXPORTING:
      return { ...state, exporting: action.bool };
    case lsRequestActions.SAVE_LS_DOC_SEARCH_FIELDS:
      return {
        ...state, lsDocSearchFields: action.fields
      };
    case lsRequestActions.SAVE_LS_SEARCH_FIELDS:
      return {
        ...state, lsSearchFields: action.fields
      };
    default:
      return state;
  }
}
