import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import styles from '../../styles/user/Login.module.scss';
import { login, gettingSuccessMessage } from '../../actions/userAction';
import LoginCarousel from './LoginCarousel';
import PasswordReset from './PasswordReset';
import TwoFactorAuthModal from './TwoFactorAuthModal';
import version from '../../../package.json';

const Login = props => {
  const [validWarningMessage, setValidWarningMessage] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [twoFactorPassword, setTwoFactorPassword] = useState('');
  const {
    checkingLoginStatus,
    errorMessage,
    forgotPassword,
    gettingSuccessMessage,
    inputPassword,
    inputUsername,
    location,
    login,
    setPassword,
    setUsername,
    successMessage,
    twoFactorAuthMessage,
    userLoginStatus,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  /** useEffect hook for component mount */
  useEffect(() => {
    let user = localStorage.getItem('user');
    gettingSuccessMessage('');
    if (user) {
      setRememberMe(true);
      setUsername(user);
    }
  }, [gettingSuccessMessage, setUsername]);

  // Explore changes of the input username
  function onGetInputUsername(e) {
    setUsername(e.target.value);
  }

  // Explore changes of the input password
  function onGetInputPassword(e) {
    setPassword(e.target.value);
  }

  // Reset the input area
  function onForgotPassword(e, status) {
    e.preventDefault();
    checkingLoginStatus(status);
  }

  // Handle event when the end user press enter
  function onHandlePressEnter(e) {
    if (e.key === 'Enter') {
      onLogin(e);
    }
  }

  /** Two Factor Authentication  */
  function handleTwoFALogin(code) {
    setTwoFactorPassword(code);
  }

  // handle password reveal change
  function handleShowPasswordChange() {
    setShowPassword(!showPassword);
  }

  useEffect(() => {
    if (twoFactorPassword !== '') {
      login(
        inputUsername,
        inputPassword,
        rememberMe,
        location,
        null,
        twoFactorPassword
      );
    }
    // eslint-disable-next-line
  }, [twoFactorPassword]);
  /** End Two Factor Authentication */

  async function onLogin(e) {
    e.preventDefault();
    if (inputUsername === '') {
      setValidWarningMessage('Username cannot be empty!');
    } else if (inputPassword === '') {
      setValidWarningMessage('Password cannot be empty!');
    } else {
      setValidWarningMessage('');
      login(inputUsername, inputPassword, rememberMe, location);
    }
  }

  return (
    <div className={styles['pageBody']}>
      <div className='container-fluid'>
        <div className='row h-100'>
          <div className={`col-4 ${styles['slider-info']} d-none d-md-flex`}>
            <div className={styles['carousel-wrapper']}>
              <LoginCarousel />
            </div>
          </div>
          <div
            className={`col-md-8 col-sm-12 ${styles['login-container-initial']}`}>
            <img
              className={styles['login-logo']}
              src='img/Logo/iwomm logo (login).png'
              alt='IWOMM logo'
              width='313'
              sizes='(max-width: 479px) 125px, (max-width: 767px) 18vw, 125px'
            />
            {(userLoginStatus === 'forgotPassword' ||
              userLoginStatus === 'register') && <PasswordReset />}
            {userLoginStatus !== 'success' && !forgotPassword && (
              <form
                onKeyUp={onHandlePressEnter}
                className={styles['input-wrapper']}>
                <h1>Login</h1>
                {validWarningMessage && (
                  <div className='alert alert-danger' role='alert'>
                    {validWarningMessage}
                  </div>
                )}
                {userLoginStatus === 'fail' && errorMessage && (
                  <div className='alert alert-danger' role='alert'>
                    {errorMessage}
                  </div>
                )}
                {successMessage && (
                  <div className='alert alert-success' role='alert'>
                    {successMessage}
                  </div>
                )}
                <div className='form-floating mb-3'>
                  <input
                    autoComplete="'off'"
                    type='email'
                    className='form-control'
                    id='floatingUsername'
                    placeholder='name@example.com'
                    maxLength='256'
                    value={inputUsername}
                    onChange={onGetInputUsername}
                  />
                  <label htmlFor='floatingUsername'>Username or Email</label>
                </div>
                <div className='form-floating'>
                  <input
                    autoComplete="'off'"
                    type={showPassword ? 'text' : 'password'}
                    className='form-control'
                    id='floatingPassword'
                    placeholder='Password'
                    maxLength='256'
                    value={inputPassword}
                    onChange={onGetInputPassword}
                  />
                   <button className={`btn border-0 p-1 ${styles['button-eye']}`} type="button" onClick={handleShowPasswordChange}><i className={showPassword ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}></i></button>
                  <label htmlFor='floatingPassword'>Password</label>
                </div>           
                <div className={`form-check ${styles['remember-container']}`}>
                  <input
                    className='form-check-input'
                    type='checkbox'
                    value='remember'
                    id='flexCheckChecked'
                    onChange={() => setRememberMe(!rememberMe)}
                    checked={rememberMe}
                  />
                  <label
                    className='form-check-label'
                    htmlFor='flexCheckChecked'>
                    Remember Username
                  </label>  
                              
                </div>
                <div className='d-grid gap-2'>
                  <button
                    className={`submit-button ${styles['login-button']}`}
                    onClick={onLogin}
                    type='button'>
                    Login
                  </button>
                </div>
                <div className={styles['account-activate']}>
                  <button
                    type='button'
                    className={`btn btn-link ${styles['override-cancel-button']}`}
                    onClick={e => onForgotPassword(e, 'forgotPassword')}>
                    Forgot your password?
                  </button>
                </div>
              </form>
            )}
            <TwoFactorAuthModal
              message={twoFactorAuthMessage}
              handleTwoFALogin={handleTwoFALogin}
            />
            <div className={styles['login-footer']}>
              <h3 className={styles['login-footer-title']}>
                IWOMM (v {version.version})
              </h3>
              <p className={styles['login-footer-sub-title']}>
                Powered by New York City Council's Web Development Unit
              </p>
            </div>
          </div>
          {/* Second Column */}
        </div>
        {/* Row */}
      </div>
      {/* Container */}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userLoginStatus: state.userReducer.userLoginStatus,
    inputUsername: state.userReducer.inputUsername,
    inputPassword: state.userReducer.inputPassword,
    errorMessage: state.userReducer.errorMessage,
    successMessage: state.userReducer.successMessage,
    twoFactorAuthMessage: state.userReducer.twoFactorAuthMessage,
  };
};

const mapDispatchToProps = {
  login,
  gettingSuccessMessage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
