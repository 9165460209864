import React, { useEffect, useState } from 'react';
import styles from '../../styles/user/UserForm.module.scss';
import { roles } from '../../services/constants';
import { connect, useDispatch } from 'react-redux';
import Toggle from '../library/Toggle';
import { checkingCreateNewUserStatus } from '../../actions/userAction';
import UserSelect from './UserSelect';

const suffixes = ['', 'Jr.', 'Sr.', 'I', 'II', 'III'];

const renderPermissions = props => {
  if (props.onTogglePermission) {
    return (
      <>
        <br />
        <h4>Permissions</h4>
        <h5>LS Request</h5>
        <div className='row'>
          {Object.keys(props.permissions['LS_Request']).map(
            (operation, index) => (
              <div className='col-4' key={index}>
                <div className={styles['inline-block']} key={index}>
                  <label>
                    <input
                      style={{ marginRight: '5px' }}
                      type='checkbox'
                      onChange={props.onTogglePermission.bind(
                        this,
                        'LS_Request',
                        operation
                      )}
                      checked={props.permissions['LS_Request'][operation]}
                    />
                    {operation.replace(/_/g, ' ').toUpperCase()}
                  </label>
                </div>
              </div>
            )
          )}
        </div>
        <hr />
        <h5>Operational Requirement</h5>
        <div className='row'>
          {Object.keys(props.permissions['Report_Tracking']).map(
            (operation, index) => (
              <div className='col-4' key={index}>
                <div className={styles['inline-block']} key={index}>
                  <label>
                    <input
                      style={{ marginRight: '5px' }}
                      type='checkbox'
                      onChange={props.onTogglePermission.bind(
                        this,
                        'Report_Tracking',
                        operation
                      )}
                      checked={props.permissions['Report_Tracking'][operation]}
                    />
                    {operation
                      .replace(/_/g, ' ')
                      .replace('agencyuser', 'AGENCY USER')
                      .toUpperCase()}
                  </label>
                </div>
              </div>
            )
          )}
        </div>
        <hr />
        <h5>CLEX</h5>
        <div className='row'>
          {Object.keys(props.permissions['Wiki']).map((operation, index) => (
            <div className='col-4' key={index}>
              <div className={styles['inline-block']} key={index}>
                <label>
                  <input
                    style={{ marginRight: '5px' }}
                    type='checkbox'
                    onChange={props.onTogglePermission.bind(
                      this,
                      'Wiki',
                      operation
                    )}
                    checked={props.permissions['Wiki'][operation]}
                  />
                  {operation
                    .replace(/_/g, ' ')
                    .replace('pagereview', 'page review')
                    .replace('versiondiff', 'page history')
                    .toUpperCase()}
                </label>
              </div>
            </div>
          ))}
        </div>
        <hr />
        <h5>Proclamation Request</h5>
        <div className='row'>
          {Object.keys(props.permissions['Proclamation_Request']).map(
            (operation, index) => (
              <div className='col-4' key={index}>
                <div className={styles['inline-block']} key={index}>
                  <label>
                    <input
                      style={{ marginRight: '5px' }}
                      type='checkbox'
                      onChange={props.onTogglePermission.bind(
                        this,
                        'Proclamation_Request',
                        operation
                      )}
                      checked={
                        props.permissions['Proclamation_Request'][operation]
                      }
                    />
                    {operation
                      .replace(/_/g, ' ')
                      .replace('proclamationrequest', 'proclamation request')
                      .toUpperCase()}
                  </label>
                </div>
              </div>
            )
          )}
        </div>
        <hr />
        <h5>Citation Request</h5>
        <div className='row'>
          {Object.keys(props.permissions['Citation_Request']).map(
            (operation, index) => (
              <div className='col-4' key={index}>
                <div className={styles['inline-block']} key={index}>
                  <label>
                    <input
                      style={{ marginRight: '5px' }}
                      type='checkbox'
                      onChange={props.onTogglePermission.bind(
                        this,
                        'Citation_Request',
                        operation
                      )}
                      checked={props.permissions['Citation_Request'][operation]}
                    />
                    {operation
                      .replace(/_/g, ' ')
                      .replace('citationrequest', 'citation request')
                      .toUpperCase()}
                  </label>
                </div>
              </div>
            )
          )}
        </div>
        <hr />
      </>
    );
  }
};
const renderAccess = props => {
  if (props.formTitle.includes('Edit')) {
    return (
      <>
        <br />
        <h4>Access</h4>
        <div className={styles['accessContent']}>
          {props.isLocked && (
            <input
              type='button'
              className={styles['unlock']}
              value='Unlock'
              onClick={props.unlockUser}
            />
          )}
          <Toggle
            value={props.inputIsActive}
            changeHandler={props.toggleActive}
            label="Toggle to control the user's access to the system"
          />
        </div>
      </>
    );
  }
  // return <div />
};
const UserForm = props => {
  const [associatedCMFocused, setAssociatedCMFocused] = useState(false);
  const districtRoles = [
    'District Chief of Staff',
    'District Legislative Director',
    'District Legislative Fellow',
    'District Legislative/Policy Associate',
    'District Proc/Citation Liaison',
  ];
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(checkingCreateNewUserStatus(''));
    };
  }, [dispatch]);

  const confirmUpdate = () => {
    if (window.location.pathname) {
      window.location.reload();
    } else {
      window.location.assign(`/staff/${props.editId}`);
    }
  };
  const bioPlaceholder = `"Hi, my name is Bob and I like long walks on the beach. I am counsel to the committee on culinary arts and eating food is my passion. I'm cranky when I'm hungry, so keep that in mind when timing our meetings."`;
  return (
    <>
      <form>
        {props.newUserCreationStatus !== 'success' && (
          <>
            <div className={styles['create-new-user-form-wrapper']}>
              <div className='row'>
                <div className='col-12'>
                  {/* Empty alert message appearing - needs revision */}
                  {props.warningMessage && (
                    <div className='alert alert-danger' role='alert'>
                      {props.warningMessage}
                    </div>
                  )}
                  {props.errorMessage && (
                    <div className='alert alert-danger' role='alert'>
                      {props.errorMessage}
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                {props.formTitle !== 'Create a new user' &&
                  props.formTitle !== 'Create a new agency user' && (
                    <div className='col-12 col-md-6'>
                      <div className='form-floating mb-3'>
                        <input
                          type='text'
                          maxLength='256'
                          className='form-control'
                          name='inputUsername'
                          id='inputUsername'
                          value={props.inputUsername || ''}
                          onChange={props.onDetectInputChange}
                          disabled={
                            props.userProfile.permissions.auth.change_user
                          }
                        />
                        <label htmlFor='inputUsername'>Username *</label>
                      </div>
                    </div>
                  )}
                <div
                  className={
                    props.formTitle !== 'Create a new user'
                      ? 'col-12 col-md-6'
                      : 'col-12'
                  }>
                  <div className='form-floating mb-3'>
                    <input
                      type='email'
                      maxLength='256'
                      className='form-control'
                      id='inputEmail'
                      name='inputEmail'
                      value={props.inputEmail || ''}
                      onChange={props.onDetectInputChange}
                      placeholder={`Enter their ${
                        props.inputRole === 'Agency'
                          ? 'nyc.gov'
                          : 'council.nyc.gov'
                      } email `}
                    />
                    <label htmlFor='inputEmail'>{`Enter their ${
                      props.inputRole === 'Agency'
                        ? 'nyc.gov'
                        : 'council.nyc.gov'
                    } email `}</label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-floating mb-3'>
                    <input
                      placeholder='Enter their first name'
                      type='text'
                      maxLength='256'
                      className='form-control'
                      name='inputFirstName'
                      id='inputFirstName'
                      value={props.inputFirstName || ''}
                      onChange={props.onDetectInputChange}
                    />
                    <label htmlFor='inputFirstName'>First Name *</label>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='form-floating mb-3'>
                    <input
                      placeholder=' Enter their middle name'
                      type='text'
                      maxLength='256'
                      className='form-control'
                      name='inputMiddleName'
                      id='inputMiddleName'
                      value={props.inputMiddleName || ''}
                      onChange={props.onDetectInputChange}
                    />
                    <label htmlFor='inputMiddleName'>Middle Name</label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-9 col-md-8'>
                  <div className='form-floating mb-3'>
                    <input
                      placeholder=' Enter their last name'
                      type='text'
                      maxLength='256'
                      className='form-control'
                      name='inputLastName'
                      id='inputLastName'
                      value={props.inputLastName || ''}
                      onChange={props.onDetectInputChange}
                    />
                    <label htmlFor='inputLastName'>Last Name *</label>
                  </div>
                </div>
                <div className='col-3 col-md-4'>
                  <div className='form-floating mb-3'>
                    <select
                      className='form-select'
                      name='inputSuffix'
                      id='inputSuffix'
                      value={props.inputSuffix || ''}
                      onChange={props.onDetectInputChange}>
                      {suffixes.map((suffix, index) => (
                        <option key={suffix + index} value={suffix}>
                          {suffix}
                        </option>
                      ))}
                    </select>
                    <label htmlFor='inputSuffix'>Suffix</label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-6'>
                  <div className='form-floating mb-3'>
                    <input
                      placeholder=' Enter their phone number'
                      type='text'
                      maxLength='256'
                      className='form-control'
                      name='inputPhone'
                      id='inputPhone'
                      value={props.inputPhone || ''}
                      onChange={props.onDetectInputChange}
                      required
                    />
                    <label htmlFor='inputPhone'>Phone *</label>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='form-floating mb-3'>
                    <input
                      placeholder=' Enter their alternative phone number'
                      type='text'
                      maxLength='256'
                      className='form-control'
                      name='inputAlternativePhone'
                      id='inputAlternativePhone'
                      value={props.inputAlternativePhone || ''}
                      onChange={props.onDetectInputChange}
                    />
                    <label htmlFor='inputAlternativePhone'>
                      Alternative Phone
                    </label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12 col-md-12'>
                  <div className='form-floating mb-3'>
                    <textarea
                      placeholder={bioPlaceholder}
                      rows='7'
                      style={{ height: 'auto' }}
                      maxLength='500'
                      className='form-control custom-placeholder'
                      name='inputBio'
                      id='inputBio'
                      value={props.inputBio || ''}
                      onChange={props.onDetectInputChange}
                    />
                    <label htmlFor='inputBio'>{`Bio (${
                      500 - props.inputBio.length
                    } characters remaining)`}</label>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-12'>
                  <div className='form-floating mb-3'>
                    <select
                      className='form-select'
                      name='inputRole'
                      id='inputRole'
                      value={props.inputRole || ''}
                      onChange={props.onDetectInputChange}
                      required>
                      <option value=''>Select their role</option>
                      {roles.map((role, index) => (
                        <option key={role + index} value={role}>
                          {role}
                        </option>
                      ))}
                    </select>
                    <label htmlFor='inputRole'>Role *</label>
                  </div>
                </div>
                {districtRoles.includes(props.inputRole) && (
                  <div className='col-12'>
                    <div className='form-floating mb-3'>
                      <input
                        type='text'
                        value={props.search || ''}
                        name='underCouncilMember'
                        key={'UnderCouncilMember'}
                        className={`form-control ${styles['input-text-box']}`}
                        onChange={props.onDetectInputChange}
                        onFocus={() => {
                          if (props.userProfile.permissions.auth.change_user)
                            setAssociatedCMFocused(true);
                        }}
                        onBlur={(e) => { e.target.value = '' }}
                      />
                      <label className={styles['input-name-not-empty']}>
                        Associated Council Member *
                      </label>
                      <UserSelect
                        search={props.search || ''}
                        handleClick={e => {
                          props.handleClick(e);
                          setAssociatedCMFocused(false);
                        }}
                        typeOfUsers='council members'
                        focused={associatedCMFocused}
                      />
                    </div>
                  </div>
                )}
              </div>
              {props.inputRole !== 'Agency' && (
                <>
                  <div className='row'>
                    <div className='col-12'>
                      <label className={styles['input-name-not-empty']}>
                        Division
                        {districtRoles.includes(props.inputRole) ? '' : '*'}
                        <span className={styles['instructions']}>
                          (Press 'ctrl' for choosing multiple divisions)
                        </span>
                      </label>
                      <select
                        className='form-select'
                        size='3'
                        multiple={true}
                        name='inputDivision'
                        value={props.inputDivision.split(',') || ''}
                        onChange={props.onDetectDivision}
                        disabled={
                          districtRoles.includes(props.inputRole)
                            ? 'disabled'
                            : false
                        }>
                        {props.division.map(division => (
                          <option
                            value={division.id}
                            key={division.division_name + division.id}>
                            {division.division_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-12'>
                      <label className={styles['input-name-not-empty']}>
                        Committee
                        {districtRoles.includes(props.inputRole) ? '' : '*'}
                        <span className={styles['instructions']}>
                          (Press 'ctrl' for choosing multiple committees)
                        </span>
                      </label>
                      <select
                        className='form-select'
                        size='3'
                        multiple={true}
                        name='inputCommittee'
                        value={props.inputCommittee.split(',') || ''}
                        onChange={props.onDetectCommittee}
                        disabled={
                          districtRoles.includes(props.inputRole)
                            ? 'disabled'
                            : false
                        }>
                        {props.committees.map(committee => (
                          <option
                            value={committee.id}
                            key={committee.committee_name + committee.id}>
                            {committee.committee_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  {props.userProfile.permissions.auth.change_user &&
                    renderPermissions(props)}
                </>
              )}
              {props.inputRole === 'Agency' && (
                <div className='row'>
                  <div className='col-12'>
                    <div className='form-floating mb-3'>
                      <select
                        className={`form-select ${styles['input-text-box']}`}
                        name='inputAgency'
                        value={props.inputAgency || ''}
                        onChange={props.onDetectInputChange}>
                        <option value=''>Select Agency</option>
                        {props.allAgencies.map(agency => (
                          <option
                            value={agency.name}
                            key={agency.name + agency.id}>
                            {agency.name}
                          </option>
                        ))}
                      </select>
                      <label
                        htmlFor='inputAgency'
                        className={styles['input-name-not-empty']}>
                        Agency *
                      </label>
                    </div>
                  </div>
                </div>
              )}
              {props.userProfile.permissions.auth.change_user &&
                renderAccess(props)}
            </div>
            <div className={styles['create-new-user-modal-footer']}>
              <button className='submit-button' onClick={props.onSubmit}>
                {props.formTitle === 'Edit user'
                  ? 'Update User Profile'
                  : 'Create User'}
              </button>
            </div>
          </>
        )}
        {props.newUserCreationStatus === 'success' && (
          <div className='container'>
            <div className='row'>
              <div className='col-12'>
                <div
                  className={`alert alert-success ${styles['success-message']}`}
                  role='alert'
                  isopen={true}>
                  {props.formTitle === 'Edit user'
                    ? 'User successfully updated!\nPage will refresh shortly.'
                    : 'Successfully created a new user!'}
                </div>
              </div>
            </div>
            <div className={'row ' + styles['login-button-container']}>
              {props.formTitle !== 'Edit user' && (
                <div className='col-12 col-md-6'>
                  <span
                    className={
                      styles['login-button'] + ' btn btn-lg btn-success'
                    }
                    onClick={e => {
                      e.preventDefault();
                      props.fieldReset();
                      dispatch(checkingCreateNewUserStatus(''));
                    }}>
                    Create Another User
                  </span>
                </div>
              )}
              {props.formTitle !== 'Edit user' && (
                <div className='col-12 col-md-6'>
                  <a
                    className={
                      styles['login-button'] + ' btn btn-lg btn-success'
                    }
                    href='/staff'>
                    View Staff Directory
                  </a>
                </div>
              )}
              {props.formTitle === 'Edit user' && (
                <div className='col-12 col-md-6 offset-md-3'>
                  <span
                    className={
                      styles['login-button'] + ' btn btn-lg btn-success'
                    }
                    onClick={confirmUpdate}>
                    Confirm
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </form>
    </>
  );
};

const mapStateToProps = state => {
  return {
    errorMessage: state.userReducer.errorMessage,
    allAgencies: state.reportTrackingReducer.allAgencies,
    userProfile: state.userReducer.userProfile,
    newUserCreationStatus: state.userReducer.newUserCreationStatus,
    committees: state.userReducer.committees,
    division: state.userReducer.division,
  };
};
export default connect(mapStateToProps)(UserForm);
