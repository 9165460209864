import { call, put, takeEvery } from 'redux-saga/effects';
import axios from 'axios';
import { getTargetFiles, delay } from '../utils/helper';
import { url } from '../config';
import { lsModelTextFields } from '../services/constants';
import {
  checkLsIsLoading,
  createNewLsRequestStatus,
  exportingFullLS,
  getNewCreatedLsNumber,
  isDuplicateLsLoading,
  lsRequestActions,
  openOrCloseLsRequestServicePopup,
  saveBillHistoryList,
  saveCurrentLsRequest,
  saveErrorMessage,
  saveLSProgress,
  storeDuplicateCheckResult,
  storeDuplicateLsRequest,
  storeLsRequestHistory,
  storeLsRequestStatusApprovals,
  storeLsRequests,
  updateTargetLsRequestStatus,
  changeOldStatusToNewStatus,
} from '../actions/lsRequestAction';
import { addToastInfo } from '../actions/userAction';

// Send API GET request for LS loading
function sendLoadLsRequestListRequest(nextApi, sortedBy, filtered, searchFields) {
  filtered.url = filtered.url
    .replace(/&/g, '%26')
    .replace(/:/g, '%3A')
    .replace(/,/g, '%2C')
    .replace(/'/g, '%27');
  if (nextApi) {
    return axios.get(nextApi);
  }

  let endpoint;
  if (filtered.bool) {
    endpoint =
    url +
    '/api/lsRequest/filterLsRequest/?filters=' +
    filtered.url +
    '&limit=30';
  } else {
    endpoint = url + '/api/lsRequest/lsRequest/';
  }

  return axios.get(endpoint, {
    params: {
      sortedBy: sortedBy,
    },
  });
}

function sendLoadLsRequestStatusApprovalList() {
  let endpoint = url + '/api/lsRequest/approveLsRequestStatus/';

  return axios.get(endpoint);
}

function newStatusChangeApiCall(action) {
  let pk;
  if (action.payloadObj.approved[0]) {
    pk = action.payloadObj.approved[0].ls_number;
  } else if (action.payloadObj.rejected[0]) {
    pk = action.payloadObj.rejected[0].ls_number;
  }

  // I had to send any of the ls_number sent in the payload as a pk, in order for the Patch request to go through, otherwise it will through a 'Method Not Allowed' error:
  let endpoint = url + `/api/lsRequest/approveLsNewStatus/${pk}/`;
  return axios.patch(endpoint, {
    payloadObj: action.payloadObj,
  });
}

function cleanInvalidBrackets(ls) {
  for (let field in lsModelTextFields) {
    if (
      ls[lsModelTextFields[field]] === null ||
      ls[lsModelTextFields[field]] === undefined
    ) {
      ls[lsModelTextFields[field]] = '';
    }
    ls[lsModelTextFields[field]] = ls[lsModelTextFields[field]]
      .replace(new RegExp('<', 'gi'), match => '&#60;')
      .replace(new RegExp('>', 'gi'), match => '&#62;');
  }

  return ls;
}

// Send API POST request for creating a new LS request
// have to be divided to two parts to save the formdata
// As the zip process return a Promise

// Save all the static information except for the files
function saveStaticDataForCreate(newLs) {
  const formData = new FormData();
  if (newLs.ls_number) formData.append('ls_number', newLs.ls_number);
  if (newLs.background_inspiration)
    formData.append('background_inspiration', newLs.background_inspiration);
  if (newLs.background_existing_laws)
    formData.append('background_existing_laws', newLs.background_existing_laws);
  formData.append('division', newLs.division || '');
  formData.append('first_prime', newLs.first_prime);
  formData.append('ls_type', newLs.ls_type);
  formData.append('description_problem', newLs.description_problem || '');
  formData.append(
    'description_legislative_solution',
    newLs.description_legislative_solution || ''
  );
  formData.append(
    'description_related_discussions',
    newLs.description_related_discussions || ''
  );
  formData.append('first_in_time', newLs.first_in_time || '');
  if (newLs.status) formData.append('status', newLs.status);
  formData.append('cm_notes', newLs.cm_notes || '');
  if (newLs.staff) formData.append('staff', newLs.staff);
  if (newLs.committee) formData.append('committee', newLs.committee);
  formData.append('confidential_comments', newLs.confidential_comments || '');
  formData.append(
    'attachment_descriptions',
    newLs.attachment_descriptions || ''
  );
  formData.append(
    'operational_requirement',
    newLs.operational_requirement ? 'True' : 'False'
  );
  formData.append('contact_person', newLs.contact_person);
  formData.append(
    'matters',
    newLs.matters.length === 0
      ? []
      : newLs.matters.map(law => JSON.stringify(law)).join(' , ')
  );
  formData.append(
    'reintroduction',
    newLs.reintroduction === true
      ? 'True'
      : newLs.reintroduction === false
      ? 'False'
      : null
  );
  formData.append('prev_sess_ls_number', newLs.prev_sess_ls_number);
  formData.append(
    'make_first_prime_public',
    newLs.make_first_prime_public ? 'True' : 'False'
  );
  if (newLs.overlapping_ls)
    formData.append('overlapping_ls', newLs.overlapping_ls);
  if (newLs.related_ls) formData.append('related_ls', newLs.related_ls);
  if (typeof newLs.duplicate_check !== 'undefined')
    formData.append(
      'duplicate_check',
      newLs.duplicate_check ? 'True' : 'False'
    );
  return formData;
}

function* saveFileData(ls, formData) {
  const bill = yield call(getTargetFiles, ls.bill);
  const billMatrix = yield call(getTargetFiles, ls.bill_matrix);
  const cmAttachment = yield call(getTargetFiles, ls.cm_attachment);
  const coversheets = yield call(getTargetFiles, ls.coversheet);
  const hearingReport = yield call(getTargetFiles, ls.hearing_report);
  const hearingSummary = yield call(getTargetFiles, ls.hearing_summary);
  const legalMemo = yield call(getTargetFiles, ls.legal_memo);
  const plainLanguageSummary = yield call(
    getTargetFiles,
    ls.plain_language_summary
  );
  const resourceLetter = yield call(getTargetFiles, ls.resource_letter);
  const attachmentFiles = {
    'bill': bill,
    'bill_matrix': billMatrix,
    'cm_attachment': cmAttachment,
    'coversheet': coversheets,
    'hearing_report': hearingReport,
    'hearing_summary': hearingSummary,
    'legal_memo': legalMemo,
    'plain_language_summary': plainLanguageSummary,
    'resource_letter': resourceLetter,
  };
  let attachments = Object.keys(attachmentFiles);
  for (let attachment of attachments) {
    if (attachmentFiles[attachment]) {
      attachmentFiles[attachment].forEach((file, idx) => {
        if (idx) {
          formData.append(`${attachment}-${idx}`, file);
        } else {
          formData.append(attachment, file);
        }
      });
    }
  }
}

function sendCreateNewLsRequest(formData) {
  return axios.post(url + '/api/lsRequest/createNewLsRequest/', formData);
}

// Send API GET request for a specific ls request
function getOneLsNumberRequest(lsNumber) {
  if (lsNumber !== null) {
    return axios.get(
      url + '/api/lsRequest/lsRequest/' + lsNumber.toString() + '/'
    );
  }
}

// Send HTTP.PUT to update a target ls request
function updateLsRequest(formData, ls_number) {
  let ls_number_encoded = ls_number.toString().replace('.', 'D'); // Temporary solution for Sub-LSR bug
  return axios.put(
    url + '/api/lsRequest/updateLsRequest/' + ls_number_encoded + '/',
    formData
  );
}

function retrieveLsRequestHistory(ls_number) {
  return axios.get(`${url}/api/lsRequest/history/${ls_number}/`);
}

function lsActivation(ls_number) {
  return axios.patch(`${url}/api/lsRequest/activation/${ls_number}/`);
}

function* saveLsRequestHistory(action) {
  if (action !== undefined) {
    try {
      const response = yield call(retrieveLsRequestHistory, action.ls_number);
      yield put(storeLsRequestHistory(response.data));
    } catch (e) {
      console.log(e);
    }
  }
}

function* fetchSingleLsr(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getOneLsNumberRequest, action.ls);
      response.data = cleanInvalidBrackets(response.data);
      yield put(saveCurrentLsRequest(response.data));
      action.callback();
    } catch (e) {
      console.log(e);
    }
  }
}

//HTTP.GET to get suggestions on assigning an LS based on role. Valid roles are: policy analyst, senior attorney, drafter
// function autoAssignRequest(role){
//   return axios.get(`${url}/api/lsRequest/autoAssign/?role=${role}`)
// }

// Send out GET request for checking ls duplication
// based on end user input ls_number
// GET request with parameter: search_ls_number
// Do it in query string instead of headers
function sendDuplicateCheckRequest(requestNum) {
  return axios.get(url + '/api/duplicate/lsUnique/', {
    params: { search_ls_number: requestNum },
  });
}

// Function for getting bill history
function getBillHisotryRequest(ls_number) {
  return axios.get(url + `/api/lsRequest/billHistory/?ls_number=${ls_number}`);
}

function deleteFileRequest(reportID, field) {
  return axios.delete(
    `${url}/api/lsRequest/deleteFile/${reportID}/?field=${field}`
  );
}

// LS Request Promise
// Store the pagination nextApi and prevApi in Redux Store
// Check if the data is under loading process
function* loadLsRequestsList(action) {
  if (action !== undefined) {
    try {
      yield put(checkLsIsLoading(true));
      const response = yield call(
        sendLoadLsRequestListRequest,
        action.nextApi,
        action.sortedBy,
        action.filtered
      );
      const { data } = response;
      let results, next, previous, count, highlights, search;
      if (data.hasOwnProperty('highlight')) {
        results = data.es_results;
        count = data.count;
        next = data.next;
        previous = data.previous;
        highlights = data.highlight
        // search = data.search.map(term =>
        //   term.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        // );
      } else {
        results = data.es_results;
        next = data.next;
        previous = data.previous;
        count = data.count;
        highlights = {}
      }

      results.forEach(ls => cleanInvalidBrackets(ls));
      yield put(storeLsRequests(results, previous, next, count, highlights));
    } catch (e) {
      console.log(e);
      yield put(storeLsRequests([], null, null, 0, false, true, null));
    }
  }
}

// Get list of LSRequest Approval
function* loadLsRequestStatusApprovalList(action) {
  if (action !== undefined) {
    try {
      // yield put(checkLsIsLoading(true));
      const response = yield call(sendLoadLsRequestStatusApprovalList);
      const { data } = response;
      let results = data.results;

      yield put(storeLsRequestStatusApprovals(results));
    } catch (e) {
      console.log(e);
      yield put(storeLsRequestStatusApprovals([]));
    }
  }
}

// Dispatch the New Status Change action to the backend
function* dispatchNewStatusChange(action) {
  try {
    const response = yield call(newStatusChangeApiCall, action);
    let toastBody = '';
    if (response.status !== 200) {
      toastBody =
        'Something went wrong with updating the status change request, please try again later.';
    } else {
      if (
        action.payloadObj.approved.length > 0 &&
        action.payloadObj.rejected.length === 0
      ) {
        toastBody = `${action.payloadObj.approved.length} status change(s) have been approved.`;
      } else if (
        action.payloadObj.rejected.length > 0 &&
        action.payloadObj.approved.length === 0
      ) {
        toastBody = `${action.payloadObj.rejected.length} status change(s) have been rejected.`;
      } else {
        toastBody = `${action.payloadObj.approved.length} status change(s) have been approved and\r\n${action.payloadObj.rejected.length} status change(s) have been rejected.`;
      }
    }
    const toast = {
      showToast: true,
      toastTitle: 'Status Approval Request Updated',
      toastBody,
      toastApp: '/img/Navbar/lsr.png',
      toastAppAlt: 'LSR Request Icon',
    };
    yield put(addToastInfo(toast));
  } catch (e) {
    console.log(e);
  }
}

// New LS Request Promise
// Form data is passing through action
// All the file zip process return Promise that has to use call for each of them
function* creatingNewLsRequest(action) {
  if (action !== undefined) {
    try {
      yield put(checkLsIsLoading(true));
      const formData = yield call(saveStaticDataForCreate, action.newLs);
      yield call(saveFileData, action.newLs, formData);
      const response = yield call(sendCreateNewLsRequest, formData);
      yield put(saveLSProgress(null));
      const ls_number = response.data.ls_number;
      yield put(getNewCreatedLsNumber(ls_number));
      const toast = {
        showToast: true,
        toastTitle: 'LS Request Created',
        toastBody: `LS Request #${ls_number} has been successfully created`,
        toastApp: '/img/Navbar/lsr.png',
        toastAppAlt: 'LSR Request Icon',
      };
      localStorage.setItem('toastInfo', JSON.stringify(toast));
      yield put(checkLsIsLoading(false));
      yield put(createNewLsRequestStatus(true));
      let path = window.location.pathname.includes('myTask')
        ? window.location.pathname.split('/').slice(0, 3).join('/')
        : window.location.pathname.split('/').slice(0, 2).join('/');
      window.location.assign(`${path}/${ls_number}`);
    } catch (e) {
      yield put(createNewLsRequestStatus(false));
      console.log(e);
    }
  }
}

// Save the target(clicked) ls request
// It is much slower than render the popup window
// Async has to be deployed
function* saveClickedLsRequest(action) {
  if (action !== undefined) {
    try {
      let lsFields = Object.keys(action.ls);
      let cleanedLs = {};
      for (let key of lsFields) {
        if (typeof action.ls[key] === "string") {
          cleanedLs[key] = action.ls[key].replace(/<\/?span[^>]*>/g, '');
        } else {
          cleanedLs[key] = action.ls[key]
        }
      };
      if (action.actionType === 'dropdown') {
        yield put(openOrCloseLsRequestServicePopup(false));
        yield put(saveCurrentLsRequest(cleanedLs));
        yield put(openOrCloseLsRequestServicePopup(true));
      } else {
        yield put(saveCurrentLsRequest(cleanedLs));
      }
    } catch (e) {
      console.log(e);
    }
  }
}

// Update clicked ls request
function* updatingTargetLsRequest(action) {
  if (action !== undefined) {
    try {
      yield put(updateTargetLsRequestStatus('working...'));
      const formData = yield call(saveStaticDataForCreate, action.ls);
      yield call(saveFileData, action.ls, formData);
      yield call(updateLsRequest, formData, action.ls.ls_number);
      const toast = {
        showToast: true,
        toastTitle: 'LS Request Updated',
        toastBody: `LS Request #${action.ls.ls_number} has been successfully updated`,
        toastApp: '/img/Navbar/lsr.png',
        toastAppAlt: 'LSR Request Icon',
      };
      localStorage.setItem('toastInfo', JSON.stringify(toast));
      yield put(updateTargetLsRequestStatus('success'));
      yield call(delay);
      let relocationURL;
      if (window.location.pathname.includes('allMyLs')) {
        relocationURL = `/myTasks/allMyLs/${action.ls.ls_number}`;
      } else if (window.location.pathname.includes('myActiveLs')) {
        relocationURL = `/myTasks/myActiveLs/${action.ls.ls_number}`;
      } else {
        relocationURL = `/lsRequestList/${action.ls.ls_number}`;
      }
      window.location.pathname = relocationURL;
    } catch (e) {
      yield put(updateTargetLsRequestStatus('fail'));
      yield put(saveErrorMessage(e.response.data || e.response.statusText));
    }
  }
}

function* getManuallyInputDuplicate(action) {
  if (action !== undefined) {
    try {
      yield put(isDuplicateLsLoading(true));
      const response = yield call(getOneLsNumberRequest, action.ls);
      yield put(storeDuplicateLsRequest(response.data));
      yield put(isDuplicateLsLoading(false));
    } catch (e) {
      yield put(isDuplicateLsLoading(false));
      yield put(updateTargetLsRequestStatus('fail'));
      yield put(saveErrorMessage(e.response.data || e.response.statusText));
    }
  }
}

// Check duplicates
function* checkDuplicateResponse(action) {
  if (action !== undefined) {
    try {
      yield put(checkLsIsLoading(true));
      const response = yield call(sendDuplicateCheckRequest, action.requestNum);
      const { data } = response;
      data.duplicate_check.forEach(ls => cleanInvalidBrackets(ls));
      yield put(storeDuplicateCheckResult(data.duplicate_check));
      yield put(checkLsIsLoading(false));
    } catch (e) {
      console.log(e);
    }
  }
}

// Bill history of a ls request
function* getBillHistoryResponse(action) {
  if (action !== undefined) {
    try {
      const response = yield call(getBillHisotryRequest, action.ls_number);
      yield put(saveBillHistoryList(response.data.results));
    } catch (e) {
      console.log(e);
    }
  }
}

function* exportLsRequests(action) {
  let minutes = 15;
  if (
    action !== undefined &&
    document.cookie.indexOf('exported_recently=true') === -1
  ) {
    try {
      let exp = new Date();
      exp.setMinutes(exp.getMinutes() + minutes);
      document.cookie = 'exported_recently=true;expires=' + exp.toUTCString();
      yield put(exportingFullLS(true));
      yield axios
        .get(url + '/api/lsRequest/export/')
        .catch(e => console.log(e));
      yield put(exportingFullLS(false));
    } catch (e) {
      console.log(e);
    }
  } else {
    alert(
      `You've already requested this data in the last ${minutes} minutes. Please try again later.`
    );
  }
}

function* deleteFileResponse(action) {
  if (action !== undefined) {
    try {
      yield call(deleteFileRequest, action.id, action.field);
    } catch (e) {
      console.log(e);
    }
  }
}

function* requestActivation(action) {
  if (action !== undefined) {
    try {
      const response = yield call(lsActivation, action.ls_number);
      if (response.status === 200) {
        addToastInfo(
          'Activation requested',
          `Activation has been requested for LS Request #${action.ls_number}`,
          '/img/Navbar/lsr.png',
          'LSR Request Icon'
        );
        // yield call(delay, 5);
        window.location.reload();
      }
    } catch (e) {
      console.log(e);
    }
  }
}

function* lsRequestAPI() {
  yield takeEvery(lsRequestActions.CHECK_DUPLICATE, checkDuplicateResponse);
  yield takeEvery(lsRequestActions.CLICKED_LS_REQUEST, saveClickedLsRequest);
  yield takeEvery(lsRequestActions.CREATE_NEW_LS_REQUEST, creatingNewLsRequest);
  yield takeEvery(lsRequestActions.DELETE_FILE, deleteFileResponse);
  yield takeEvery(lsRequestActions.EXPORT_ALL_LS_REQUESTS, exportLsRequests);
  yield takeEvery(lsRequestActions.EXPORTING, exportingFullLS);
  yield takeEvery(lsRequestActions.GET_BILL_HISTORY, getBillHistoryResponse);
  yield takeEvery(
    lsRequestActions.GET_DUPLICATE_LS_REQUEST,
    getManuallyInputDuplicate
  );
  yield takeEvery(
    lsRequestActions.GET_LS_REQUEST_HISTORY,
    saveLsRequestHistory
  );
  yield takeEvery(lsRequestActions.LOAD_LS_REQUESTS, loadLsRequestsList);
  yield takeEvery(
    lsRequestActions.LOAD_LS_REQUEST_STATUS_APPROVAL_LIST,
    loadLsRequestStatusApprovalList
  );
  yield takeEvery(
    lsRequestActions.UPDATE_NEW_STATUS_CHANGE,
    dispatchNewStatusChange
  );
  yield takeEvery(lsRequestActions.REQUEST_ACTIVATION, requestActivation);
  yield takeEvery(lsRequestActions.STORE_SINGLE_LS, fetchSingleLsr);
  yield takeEvery(
    lsRequestActions.UPDATE_TARGET_LS_REQUEST,
    updatingTargetLsRequest
  );
}

export default lsRequestAPI;
