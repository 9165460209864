import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import styles from '../../styles/ComboBox.module.scss';

/**
 * You will need to pass in the function that set's the selected value to the state
 * Props: focused, field, search, list, handleClick, clickOutside
 */
const ComboBox = props => {
    const dropdownRef = useRef(null)

    useEffect(()=>{
        function handleClickOutside(e) {
          if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
            if (props.menu && !props.menu.contains(e.target)) {
                props.clickOutside()
            }
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
    
        return(()=>{
          document.removeEventListener('mousedown', handleClickOutside);
        })
        
      }, [props.menu, props.clickOutside]) 
  
  //If the is a value or the value full matches a user in the user list then there is no need to show the list
  if (!props.focused || props.list.find(value => value[props.field] === props.search)) {
    return <div />;
  }

  const filteredList = props.list.filter(value =>{
    return value[props.field].toLowerCase().includes(props.search.toLowerCase())
  });

  return (
    <ul className={styles['ComboBox']} ref={dropdownRef}>
      {filteredList.map((value, id) => (
        <li onClick={props.handleClick} key={id}>
          {value[props.field]}
        </li>
      ))}
    </ul>
  );
};
ComboBox.propTypes = {
    list: PropTypes.array.isRequired,
    search: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired
};
export default ComboBox;
