import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import styles from '../../styles/wiki/Wiki.module.scss';
import { fullDisclaimer } from '../../services/constants';

const customStyles = {
  top: '15%',
  left: '0',
  right: 'auto',
  bottom: 'auto',
  transform: 'translate(0%, -15%)',
  minWidth: '50%',
  maxWidth: '900px',
  height: '40%',
  border: 'none',
  boxShadow: 'rgba(0, 0, 0, 0.29) 0px 0px 18px 0px',
  borderRadius: '8px',
  backgroundColor: 'rgb(40, 35, 123)',
};

const WikiDisclaimerPopup = () => {
  const [modal, setModal] = useState(true);
  const toggle = () => setModal(!modal);

  return (
    <Modal isOpen={modal} style={customStyles} backdrop='static'>
      <ModalHeader
        tag='h3'
        style={{ backgroundColor: 'rgb(40, 35, 123)', color: '#fff' }}>
        <img
          src='/img/Logo/clex logo white.png'
          alt='Council Legislative Exchange logo'
          style={{ margin: '-5px 10px 0px 0px', width: '90px' }}
        />
        <span>Welcome to CLEX (Council Legal Exchange)</span>
      </ModalHeader>
      <ModalBody>{fullDisclaimer}</ModalBody>
      <ModalFooter>
        <div className={styles['footer-message-container']}>
          <span className={styles['message-footer']}>
            If you believe that you are viewing this in error, and should not
            have access to this protected content, please email the{' '}
            <a href='mailto:WebDevelopmentUnit@council.nyc.gov'>
              Web Development Unit
            </a>
            .
          </span>
          <Button color='warning' onClick={toggle}>
            Accept
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default WikiDisclaimerPopup;
