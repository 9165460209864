import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';
import useInput from '../../customHooks/useInput';
import { acceptedFileTypes } from '../../utils/helper';
import { submitAgencyReport } from '../../actions/reportTrackingAction';
import styles from '../../styles/report-tracking/Agency.module.scss';
import { format } from 'date-fns';
import File from '../library/File';
import ComboBox from '../library/ComboBox'
import { Spinner } from 'reactstrap';

const UploadReport = props => {
  const [attachment, setAttachment] = useState([]);
  const [dueDate, setDueDate] = useState('');
  const [reportId, setReportId] = useState(0);
  const [reportFocus, setReportFocus] = useState(false)
  const [reportSearch, setReportSearch] = useState('')
  const [reportSelect, setReportSelect] = useState(null);
  const reports = useSelector(state => state.reportTrackingReducer.allReports);
  const { bind: bindReportTitle } = useInput(
    reports.length > 0 ? reports[0].id : -1
  );
  const dispatch = useDispatch();
  const message = useSelector(state => state.reportTrackingReducer.message);
  const comboBoxMenu = document.getElementById('comboBoxMenu');

  const uploadReport = e => {
    e.preventDefault();
    dispatch(
      submitAgencyReport(reportId, attachment, encodeURIComponent(dueDate))
    );
  };

  let dueDateDict = {};
  if (reportSelect) {
    reportSelect.attachment.forEach(report => {
      if (dueDateDict[report.due_date] && report.file) {
        dueDateDict[report.due_date].push(report);
      } else {
        if (report.file) {
          dueDateDict[report.due_date] = [report];
        } else {
          dueDateDict[report.due_date] = [];
        }
      }
    });
  }

  let getOrderedDates = Object.keys(dueDateDict).sort((a, b) => {
    a = new Date(a);
    b = new Date(b);
    return a > b ? -1 : a < b ? 1 : 0;
  });

  const onDeleteNewAttachment = (e, id) => {
    setAttachment(prevState => {
      prevState.splice(id, 1);
      return prevState;
    });
  };

  let findReport = (textSelect) => {
    let selected = null
    reports.forEach((rep)=> {
      if( rep.title === textSelect) {
        selected = rep
      }
    })
    return selected
  }

  let attaches = [];
  if (attachment.length > 0) {
    attaches = attachment.map((report, idx) => {
      return (
        <File
          key={idx}
          file={report}
          id={`new-${idx}`}
          getFileAndDownload={() => {}}
          onDelete={e => onDeleteNewAttachment(e, idx)}
          deletePermission={props.deletePermission}
        />
      );
    });
  }

  return (
    <form onSubmit={uploadReport} className={styles['form']}>
      <Alert
        color={message.includes('successfully') ? 'success' : 'danger'}
        isOpen={message !== ''}>
        {message}
      </Alert>
      <p className={styles['input-text-title']}>Select a report to upload</p>
      <div id="comboBoxMenu">
        <input
          name='title'
          type='text'
          {...bindReportTitle}
          className={styles['input-text-area']}
          value={reportSearch}
          onFocus={e => {
            setReportFocus(true)
            e.target.select()
          }}
          onChange={(e)=>setReportSearch(e.target.value)}
        >
        </input>
        <ComboBox
          search={reportSearch}
          list={reports}
          menu={comboBoxMenu}
          field='title'
          handleClick={e => {
            let rep = findReport(e.target.innerText)
            if(rep && reportSelect !== rep) {
              setDueDate('')
              setAttachment([])
              setReportSelect(rep)
              setReportId(rep.id)
            }
            setReportSearch(e.target.innerText)
            setReportFocus(false)
          }}
          clickOutside={()=>{
            setReportFocus(false)
            if (reportSelect){
              setReportSearch(reportSelect.title)
            }
          }}
          focused={reportFocus}
        />
      </div>
      <div className={styles['report-upload-ui']}>
        <div>
        {reportSelect &&
          <select
            value={dueDate}
            className={styles['file-dropdown']}
            onChange={e => {
              setDueDate(e.target.value);
              setAttachment([])
            }}>
            <option value=''>Select Due Date</option>
            {getOrderedDates.map((date, index) => {
              let empty = dueDateDict[date].length > 0 ? '' : ' (empty)';
              return (
                <option value={date} key={index}>
                  {format(new Date(date), 'M/d/yyyy') + empty}
                </option>
              );
            })}
          </select>
        }
        </div>
        {attachment.length > 0 && attaches}
        {dueDate && (
          <>
            {dueDateDict[dueDate].map((file, idx) => {
              return (
                <File
                  key={idx}
                  file={file}
                  id={`old-files-${idx}`}
                  getFileAndDownload={() => {}}
                  onDelete={onDeleteNewAttachment}
                  deletePermission={props.deletePermission}
                />
              );
            })}
            <br />
            <input
              type='file'
              name='attachment'
              accept={acceptedFileTypes}
              multiple
              onChange={e => {
                let files = e.target.files;
                setAttachment(prevState => {
                  let tempAttach = [...prevState];
                  for (let i = 0; i < files.length; i++) {
                    files[i].file_name = files[i].name;
                    tempAttach = [...tempAttach, files[i]];
                  }
                  return tempAttach;
                });
              }}
            />
          </>
        )}
        {props.isLoading && (
          <Spinner type='grow' className={styles['spinner-animation']} />
        )}
      </div>
      <div style={{ marginTop: '15px' }}>
        <input
          type='submit'
          className='submit-button'
          value='Submit'
          disabled={attachment.length === 0}
        />
      </div>
    </form>
  );
};

const mapStateToProps = state => {
  return {
    isLoading: state.reportTrackingReducer.isLoading,
    deletePermission:
      state.userReducer.userProfile.permissions.reportTracking.delete_attachment,
  };
};

export default connect(mapStateToProps)(UploadReport);
