// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';

import React from 'react';
import './index.css';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
// import { createBrowserHistory } from 'history'

import { Provider } from 'react-redux';
import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
// import { render } from 'react-dom';
import { createRoot } from 'react-dom/client'; // Import createRoot
import reducer from './reducers';
import saga from './sagas';
import register from './registerServiceWorker';

const sagaMiddleware = createSagaMiddleware();
const middleware = applyMiddleware(sagaMiddleware);
let devTools =
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
if (process.env.NODE_ENV === 'prod' || process.env.NODE_ENV === 'production') {
  devTools = a => a;
}
const store = createStore(reducer, compose(middleware, devTools));

sagaMiddleware.run(saga);

// ReactDOM.render(<App />, document.getElementById('root'));
// register();

// Create a root for rendering
const root = createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
  // document.getElementById('root')
);

// Call the register function to register your service worker
registerServiceWorker();
