import React, { useRef, useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  loadLsRequests,
  saveDocSearchFields,
  saveSearchFields,
} from '../../actions/lsRequestAction'; // Adjust the path as necessary
import styles from '../../styles/ls-request/NewLsMenu.module.scss';
import styles2 from '../../styles/ls-request/AdvSearchCheckbox.module.scss';
import { rolesUnderCouncilMembers } from '../../services/constants';
import { LsFields } from '../../services/LsFields';
import { getFilteredDefaultLSRDocumentTypes, getFilteredDefaultLSRFieldTypes } from '../../utils/helper';


const selectedColumnToSearchColumn = {
  'LSR #': 'LSR Number',
  'CM Attachment': 'CM_Attachments',
  // Add more mappings if necessary
};

const DocumentsSelection = ({
  onToggleDocumentField,
  selectedDocuments,
  setSelectedDocuments,
  saveDocSearchFields,
  setOptionsChanged
}) => {
  // Handler for toggling the selection state of a document type
  if (!selectedDocuments) {
    return null; // Or render a loading indicator
  }

  const allOthersSelected = Object.values(selectedDocuments)
    .slice(1)
    .every((ele) => ele === true);

  const onToggleField = (document, isDoc = true) => {
    if (document === 'Check All') {
      const newState = {};
      Object.keys(selectedDocuments).forEach((v) => {
        newState[v] = !allOthersSelected;
      });
      setSelectedDocuments(newState);
      saveDocSearchFields(newState);
    } else {
      setOptionsChanged(true)
      onToggleDocumentField(document, isDoc);
    }
  };

  const getText = (document) => {
    if (document === 'Check All') {
      return allOthersSelected === true ? 'Uncheck All' : document;
    } else {
      return document;
    }
  };

  return (
    <>
      {Object.keys(selectedDocuments).map((field, idx) => {
        return (
          <li key={idx} className={`${styles2['column-checkbox-container']}`}>
            <input
              className={styles2['column-checkbox']}
              id={`doc-${idx}`}
              type="checkbox"
              checked={selectedDocuments[field]}
              onChange={() => onToggleField(field)}
            />
            <label
              className={styles2['column-checkbox-label']}
              htmlFor={`doc-${idx}`}
            ></label>
            <label
              className={styles2['column-checkbox-text-label']}
              htmlFor={`doc-${idx}`}
            >
              {getText(field)}
            </label>
          </li>
        );
      })}
    </>
  );
};

const LsrSelection = ({
  onToggleLsrField,
  selectedFields,
  setSelectedFields,
  saveSearchFields,
  setOptionsChanged
}) => {
  // Handler for toggling the selection state of an LSR type
  if (!selectedFields) {
    return null; // Or render a loading indicator
  }

  const allOthersSelected = Object.values(selectedFields)
    .slice(1)
    .every((ele) => ele === true);

  const onToggleField = (field) => {
    if (field === 'Check All') {
      const newState = {};
      Object.keys(selectedFields).forEach((v) => {
        newState[v] = !allOthersSelected;
      });
      setSelectedFields(newState);
      saveSearchFields(newState);
    } else {
      setOptionsChanged(true)
      onToggleLsrField(field);
    }
  };

  const getText = (field) => {
    if (field === 'Check All') {
      return allOthersSelected === true ? 'Uncheck All' : field;
    } else {
      return field;
    }
  };

  return (
    <>
      {Object.keys(selectedFields).map((field, idx) => (
        <li key={idx} className={`${styles2['column-checkbox-container']}`}>
          <input
            className={styles2['column-checkbox']}
            id={`lsr-${idx}`}
            type="checkbox"
            checked={
              field === 'Check All' ? allOthersSelected : selectedFields[field]
            }
            onChange={() => onToggleField(field)}
          />
          <label
            className={styles2['column-checkbox-label']}
            htmlFor={`lsr-${idx}`}
          ></label>
          <label
            className={styles2['column-checkbox-text-label']}
            htmlFor={`lsr-${idx}`}
          >
            {getText(field)}
          </label>
        </li>
      ))}
    </>
  );
};

const SearchToggle = ({ activeButton, handleClick }) => {
  const lsrButtonRef = useRef(null);
  const documentButtonRef = useRef(null);
  const [backgroundStyle, setBackgroundStyle] = useState({});
  const [isInitialRender, setIsInitialRender] = useState(true);

  useEffect(() => {
    const adjustBackground = () => {
      const activeBtnRef =
        activeButton === 'LSR' ? lsrButtonRef.current : documentButtonRef.current;
      if (activeBtnRef) {
        const width = activeBtnRef.offsetWidth;
        const shift = activeBtnRef.offsetLeft;

        // Set initial position without transition
        setBackgroundStyle({
          width: `${width}px`,
          transform: `translateX(${shift}px)`,
          // Apply transition only after initial render
          transition: isInitialRender ? '' : 'transform 0.3s ease',
        });

        // After setting the initial position, enable transitions for subsequent updates
        if (isInitialRender) {
          setIsInitialRender(false);
        }
      }
    };

    adjustBackground();
  }, [activeButton, isInitialRender]);

  return (
    <div className="d-flex align-items-center justify-content-center mb-3">
      <div
        className={`btn-group ${styles['search-type-toggle']}`}
        role="group"
        aria-label="Search options"
      >
        <div
          className={styles['background-shift']}
          style={backgroundStyle}
        ></div>
        <button
          ref={lsrButtonRef}
          type="button"
          className={`btn btn-sm px-3 border-0 ${styles['search-type-btn']} ${
            activeButton === 'LSR' ? styles['btn-active'] : ''
          }`}
          onClick={(e) => {
            e.preventDefault();
            handleClick('LSR');
          }}
        >
          LSR Filters
        </button>
        <button
          ref={documentButtonRef}
          type="button"
          className={`btn btn-sm px-3 border-0 ${styles['search-type-btn']} ${
            activeButton === 'Document' ? styles['btn-active'] : ''
          }`}
          onClick={() => handleClick('Document')}
        >
          Document Filters
        </button>
      </div>
    </div>
  );
};

const LsAdvancedSearch = (props) => {
  const {
    activeView,
    onActiveViewChange,
    saveDocSearchFields,
    saveSearchFields,
    user,
    setOptionsChanged
  } = props;

  const [selectedDocuments, setSelectedDocuments] = useState(null);
  const [selectedFields, setSelectedFields] = useState(null);
  const [showMatchButton, setShowMatchButton] = useState(false);
  const [columnPreset, setColumnPreset] = useState(
    localStorage.getItem('active-table-column') ||
      `column-${user.username}`
  );

  useEffect(() => {
    if (user && user.role) {
      const storedDocFields = localStorage.getItem('doc-search-fields');
      const storedSearchFields = localStorage.getItem('lsr-search-fields');

      if (storedDocFields) {
        setSelectedDocuments(JSON.parse(storedDocFields));
      } else {
        const defaults = getFilteredDefaultLSRDocumentTypes(user.role, LsFields);
        setSelectedDocuments(defaults);
        saveDocSearchFields(defaults);
      }

      if (storedSearchFields) {
        setSelectedFields(JSON.parse(storedSearchFields));
      } else {
        const defaults = getFilteredDefaultLSRFieldTypes(user.role, LsFields);
        setSelectedFields(defaults);
        saveSearchFields(defaults);
      }
    }
  }, [user]);

  useEffect(() => {
    if (selectedDocuments) {
      localStorage.setItem(
        'doc-search-fields',
        JSON.stringify(selectedDocuments)
      );
    }
  }, [selectedDocuments]);

  useEffect(() => {
    if (selectedFields) {
      localStorage.setItem('lsr-search-fields', JSON.stringify(selectedFields));
    }
  }, [selectedFields]);

  useEffect(() => {
    if (!selectedDocuments || !selectedFields) return;
    const checkColumnMatch = () => {
      const savedColumns = localStorage
        .getItem(columnPreset)
        ?.split(',') || [];
      const translatedSavedColumns = savedColumns.map((col) => {
        return selectedColumnToSearchColumn[col] || col;
      });

      const docKeys = Object.keys(selectedDocuments).filter(
        (key) => selectedDocuments[key]
      );
      const fieldKeys = Object.keys(selectedFields).filter(
        (key) => selectedFields[key]
      );
      const allSelectedKeys = [...docKeys, ...fieldKeys];

      return (
        translatedSavedColumns.some(
          (column) => !allSelectedKeys.includes(column)
        ) ||
        allSelectedKeys.some((key) => !translatedSavedColumns.includes(key))
      );
    };
    setShowMatchButton(checkColumnMatch());
  }, [selectedDocuments, selectedFields, columnPreset]);

  const handleToggleView = (view) => {
    onActiveViewChange(view);
  };

  const matchColumnSelections = () => {
    localStorage.setItem('active-table-column', columnPreset);
    const updatedDocs = Object.fromEntries(
      Object.keys(selectedDocuments).map((key) => [key, false])
    );
    const updatedFields = Object.fromEntries(
      Object.keys(selectedFields).map((key) => [key, false])
    );

    if (localStorage.getItem(columnPreset)) {
      let savedColumns = localStorage.getItem(columnPreset).split(',');
      for (let column of savedColumns) {
        const columnName = selectedColumnToSearchColumn[column] || column;
        if (selectedDocuments[columnName] !== undefined) {
          updatedDocs[columnName] = true;
        } else if (selectedFields[columnName] !== undefined) {
          updatedFields[columnName] = true;
        }
      }
      setSelectedDocuments({ ...updatedDocs });
      setSelectedFields({ ...updatedFields });
      saveDocSearchFields({ ...updatedDocs });
      saveSearchFields({ ...updatedFields });
    }
  };

  const toggleDocument = (field, isDoc = false) => {
    if (isDoc) {
      const updatedDocs = {
        ...selectedDocuments,
        [field]: !selectedDocuments[field],
      };
      setSelectedDocuments(updatedDocs);
      saveDocSearchFields(updatedDocs);
    } else {
      const updatedFields = {
        ...selectedFields,
        [field]: !selectedFields[field],
      };
      setSelectedFields(updatedFields);
      saveSearchFields(updatedFields);
    }
  };

  // Prevent rendering until data is ready
  if (
    (activeView === 'LSR' && !selectedFields) ||
    (activeView === 'Document' && !selectedDocuments)
  ) {
    return null; // Or render a loading indicator
  }

  const contentView = (
    <>
      <p className="mb-0">
        {activeView === 'LSR'
          ? 'Select from the columns below to perform an advanced query search'
          : 'Select type of document to search through'}
      </p>
      <ul
        className={`mt-2 ${styles2['column-selector-container']}`}
        style={{
          position: 'relative',
          top: '0',
          maxWidth: '100%',
          width: '100%',
          backgroundColor: 'transparent',
        }}
      >
        {activeView === 'LSR' ? (
          <LsrSelection
            onToggleLsrField={toggleDocument}
            selectedFields={selectedFields}
            setSelectedFields={setSelectedFields}
            saveSearchFields={saveSearchFields}
            setOptionsChanged={setOptionsChanged}
          />
        ) : (
          <DocumentsSelection
            onToggleDocumentField={toggleDocument}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            saveDocSearchFields={saveDocSearchFields}
            setOptionsChanged={setOptionsChanged}
          />
        )}
      </ul>
    </>
  );

  return (
    <>
      <SearchToggle activeButton={activeView} handleClick={handleToggleView} />
      <div
        className={`flex-grow-1 overflow-auto ${styles2['column-selector-wrapper']}`}
      >
        {contentView}
      </div>
      <div className="d-flex border-top justify-content-end py-2">
        {showMatchButton && (
          <button
            type="button"
            className="btn btn-sm border-0"
            onClick={matchColumnSelections}
          >
            Match column selections
          </button>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.userReducer.userProfile,
    lsDocSearchFields: state.lsRequestsReducer.lsDocSearchFields,
    lsSearchFields: state.lsRequestsReducer.lsSearchFields,
  };
};

const mapDispatchToProps = (dispatch) => ({
  saveDocSearchFields: (fields) => dispatch(saveDocSearchFields(fields)),
  saveSearchFields: (fields) => dispatch(saveSearchFields(fields)),
  // Other handlers
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LsAdvancedSearch);
