import React, { useEffect, useState } from 'react';
import styles from '../../styles/ls-request/LsRequestSidebar.module.scss';
import { connect } from 'react-redux';
import { LsFields } from '../../services/LsFields';
import File from '../library/File';
import { formatDateTime, isConfidential } from '../../utils/helper';
import parse from 'html-react-parser';
import { ThreeDotsVertical } from '../../services/SvgLibrary';
import {
  activeStatuses,
  adminRoles,
  fourthTierRoles,
  lsModelTextFields,
  nonLegDivisionRoles,
  rolesUnderCouncilMembers,
  superAdminRoles,
} from '../../services/constants';
import {
  activateLsRequest,
  openOrCloseLsRequestServicePopup,
} from '../../actions/lsRequestAction';
import { getFileAndDownload } from '../../actions/fileDownloadAction';

const LsInfoDisplay = props => {
  const lsFieldList = JSON.parse(JSON.stringify(LsFields));
  const [filesToHighlight, setFilesToHighlight] = useState({})

  useEffect(() => {
    const highlightedFiles = {};
    if (props.highlights.file_hits) {
      for (let ls_id in props.highlights.file_hits) {
        const filesArray = props.highlights.file_hits[ls_id];
        filesArray.forEach(fileName => {
          highlightedFiles[fileName] = true;
        });
      }
    }
    setFilesToHighlight(highlightedFiles);
  }, [props.highlights])

  const createLsInfoDisplay = updateTargetLs => {
    const nonLeg = nonLegDivisionRoles.includes(props.userProfile.role);
    let filteredField = lsFieldList.filter(
      field => 
        !(
          nonLeg && 
          field.viewability === 'staffOnly' 
        ) &&
        field.viewability !== "none"
    );
    let displayLsPart = filteredField.map((ls, idx) => {
      let text = '';
      let excludedArray = [
        'ls_number',
        'ls_type',
        'first_prime',
        'date_received',
        'make_first_prime_public',
      ];
      if (props.infoLocation === 'sidebar') {
        excludedArray.push('submitted_by');
        excludedArray.push('status');
      }
      // Only Session 11 LSRs will have data in the background field, newer LSRs made in IWOMM won't
      if ((ls.field === 'background') & (updateTargetLs[ls.field] === '')) {
        excludedArray.push('background');
      }
      if (
        ((props.infoLocation !== 'sidebar' && ls.duplicate_check) ||
          props.infoLocation === 'sidebar') &&
        (updateTargetLs[ls.field] ||
          ls.type === 'checkbox' ||
          ls.field === 'operational_requirement' ||
          ls.field === 'reintroduction' ||
          ls.field === 'prev_sess_ls_number') &&
        !excludedArray.includes(ls.field) &&
        !(
          (ls.type === 'multi-select' || ls.type === 'file') &&
          updateTargetLs[ls.field].length === 0
        )
      ) {
        if (ls.field === 'date_received') {
          text = formatDateTime(updateTargetLs[ls.field]);
        } else if (ls.type === 'file') {
          if (updateTargetLs[ls.field].length > 0) {
            const files = updateTargetLs[ls.field];
            text = (
              <div key={idx}>
                {files.map((file, index) => {
                const highlighted = filesToHighlight.hasOwnProperty(file.file_name);
                return (
                  <File
                    id={`lsr_${ls.field}-${index}`}
                    file={file}
                    getFileAndDownload={props.getFileAndDownload}
                    key={index}
                    highlighted={highlighted}
                  />)
                })}
              </div>
            );
          } else {
            text = 'N/A';
          }
        } else if (ls.field === 'contact_person') {
          text = (
            <pre className={styles['pre']}>
              {parse(updateTargetLs[ls.field]['full_name'])}
              <br />
              {parse(updateTargetLs[ls.field]['phone'])}
              <br />
              <a href={`mailto:${updateTargetLs[ls.field]['email']}`}>
                {parse(updateTargetLs[ls.field]['email'])}
              </a>
            </pre>
          );
        } else if (ls.field === 'committee') {
          text = parse(
            updateTargetLs[ls.field]
              ? updateTargetLs[ls.field].committee_name
              : ''
          );
        } else if (ls.field === 'associated_report_tracking') {
          text =
            updateTargetLs[ls.field].length > 0
              ? updateTargetLs[ls.field].map((report, idx) => (
                  <React.Fragment key={report.id}>
                    <a
                      href={`${window.location.origin}/opReqs/${report.id}`}
                      onClick={e => {
                        e.preventDefault();
                        window.open(
                          `${window.location.origin}/opReqs/${report.id}`
                        );
                      }}>
                      {report.title}
                    </a>
                    {idx === updateTargetLs[ls.field].length - 1 ? '' : ', '}
                  </React.Fragment>
                ))
              : 'N/A';
        } else if (ls.field === 'matters') {
          if (updateTargetLs[ls.field].length > 0){
            text = updateTargetLs[ls.field].map((matter, idx)=>
              (<React.Fragment key={matter.intro}>
                <a
                  href={matter.link}
                  target='_blank'
                  rel='noopener noreferrer'>
                  {matter.law_number ? matter.law_number: matter.intro}
                </a>
                {idx === updateTargetLs[ls.field].length - 1 ? '' : ', '}
                </React.Fragment>
              )
            )
          }
        } else if (
          ['first_prime', 'staff', 'submitted_by'].includes(ls.field)
        ) {
          text = parse(
            updateTargetLs[ls.field] ? updateTargetLs[ls.field].full_name : ''
          );
        } else if (lsModelTextFields.includes(ls.field)) {
          updateTargetLs[ls.field] = updateTargetLs[ls.field]
            .replace(new RegExp('&lt;', 'gi'), match => '<')
            .replace(new RegExp('&gt;', 'gi'), match => '>');
          text = (
            <pre className={styles['pre']}>
              {parse(updateTargetLs[ls.field])}
            </pre>
          );
        } else if (
          ls.type === 'checkbox' ||
          ls.field === 'operational_requirement'
        ) {
          text = updateTargetLs[ls.field] ? 'Yes' : 'No';
        } else if (ls.field === 'reintroduction') {
          text =
            updateTargetLs[ls.field] === true
              ? 'Yes'
              : updateTargetLs[ls.field] === false
              ? 'No'
              : 'N/A';
        } else if (ls.field === 'prev_sess_ls_number') {
          text = updateTargetLs[ls.field] ? updateTargetLs[ls.field] : 'N/A';
        } else if (['overlapping_ls', 'related_ls'].includes(ls.field)) {
          text =
            updateTargetLs[ls.field].length > 0
              ? updateTargetLs[ls.field]
                  .sort((a, b) => (a['ls_number'] > b['ls_number'] ? 1 : -1))
                  .map((ls_num, idx) => (
                    <React.Fragment key={ls_num.ls_number}>
                      <a
                        href={`${
                          window.location.origin
                        }/lsRequestList/${ls_num.ls_number.toString()}`}
                        onClick={e => {
                          e.preventDefault();
                          window.open(
                            `${
                              window.location.origin
                            }/lsRequestList/${ls_num.ls_number.toString()}`
                          );
                        }}>
                        {ls_num.ls_number}
                      </a>
                      {idx === updateTargetLs[ls.field].length - 1 ? '' : ', '}
                    </React.Fragment>
                  ))
              : 'N/A';
        } else if (ls.field === 'last_activated') {
          let sixtyText;
          let activated = new Date(updateTargetLs[ls.field]);
          activated.setDate(activated.getDate() + 1);
          let today = new Date();
          let daysDifference = Math.ceil(
            Math.abs(today - activated) / (1000 * 60 * 60 * 24)
          ); // ms * s * min * hour
          if (daysDifference <= 60) {
            sixtyText = (
              <span
                style={{
                  color: '#4A5F96',
                  fontSize: '.9em',
                  float: 'right',
                  fontWeight: 'bold',
                }}>{`${60 - daysDifference} day(s) UNTIL 60 days`}</span>
            );
          } else {
            sixtyText = (
              <span
                style={{
                  color: '#CA4A4A',
                  fontSize: '.9em',
                  float: 'right',
                  fontWeight: 'bold',
                }}>{`${daysDifference - 60} day(s) PAST 60 days`}</span>
            );
          }
          text = (
            <div key={idx}>
              {parse(updateTargetLs[ls.field])}
              {
                daysDifference &&
                !rolesUnderCouncilMembers.includes(props.userProfile.role) &&
                activeStatuses.includes(ls["status"]) &&
                <>
                  <br/>
                  {sixtyText}
                </>
              }
            </div>
          );
        } else {
          text = <div key={idx}>{parse(updateTargetLs[ls.field])}</div>;
        }

        return (
          <fieldset
            className={
              props.placement === 'duplicate-check'
                ? `${styles['fieldset-styles']} ${styles['duplicate-check']}`
                : styles['fieldset-styles']
            }
            key={idx}>
            <legend className={styles['fieldset-legend-styles']}>
              {ls.title}:
            </legend>
            <span className={styles['fieldset-content-styles']}>{text}</span>
          </fieldset>
        );
      }
      return null;
    });
    return displayLsPart;
  };

  function getInfoClass(location) {
    if (location === 'sidebar') {
      return styles['info-sidebar-css']; //Ls request sidebar
    } else {
      return styles['info-select-css']; // Left side of duplicate checker
    }
  }

  function onOpenUpdate() {
    props.onChangeToUpdate();
    props.onGetLsRequest();
    document.body.style.overflow = 'hidden';
  }

  function requestActivation(ls) {
    const userInput = window.confirm(
      'An activation request will be submitted and reviewed internally.\nYou will be notified upon activation of any LS Request.\n\nDo you want to proceed?'
    );
    if (userInput) {
      props.activateLsRequest(ls.ls_number);
    }
  }

  function onOpenDuplicateCheck(id) {
    window.open(
      `${window.location.origin}/lsRequestList/LsDupSearch/${id.toString()}`
    );
  }

  let lsInfo = props.updateTargetLs;
  if (props.injected_ls) {
    lsInfo = props.injected_ls;
  }

  let lsDisplay = createLsInfoDisplay(lsInfo);
  return (
    <div className={getInfoClass(props.infoLocation)}>
      <div className={styles['sidebar-fixed']}>
        <div className={styles['sidebar-header']}>
          <div className={styles['info-icon']}></div>
          <div className={styles['sidebar-header-info']}>
            <p className={styles['heading']}>{`LSR #${
              lsInfo.ls_number
            } - ${parse(lsInfo.ls_type)}`}</p>
            <div className={styles['sidebar-header-subtext']}>
              <strong>{parse(lsInfo.first_prime.full_name)}</strong>
              <div className={styles['datetime']}>
                {formatDateTime(lsInfo.date_received)}
              </div>
            </div>
          </div>
          {props.infoLocation === 'sidebar' && (
            <>
              {!window.location.pathname.includes('LsDupSearch') && (
                <>
                  <button
                    type='button'
                    className={`btn-close btn-close-white ${styles['close-sidebar']}`}
                    aria-label='Close'
                    onClick={() => props.onOpenOrCloseSidebar(false)}
                    title='Close'></button>
                  <div
                    className={`dropdown dropstart ${styles['action-button-container']}`}>
                    <button
                      className={`btn ${styles['column-menu']}`}
                      type='button'
                      id={`ls-options-dropdown-${lsInfo.ls_number}-sidebar`}
                      data-bs-toggle='dropdown'
                      aria-expanded='false'
                      title='Select an option'>
                      <ThreeDotsVertical />
                    </button>
                    <ul
                      className={`dropdown-menu shadow ${styles['dropdown-menu-adjusted']}`}
                      aria-labelledby={`ls-options-dropdown-${lsInfo.ls_number}-sidebar`}>
                      {lsInfo.status === 'Low-Priority Hold' &&
                        lsInfo.status !== 'Withdrawn - Former CM' &&
                        !lsInfo.activation_requested && (
                          <li>
                            <button
                              className='dropdown-item'
                              type='button'
                              onClick={() => requestActivation(lsInfo)}>
                              Request Activation
                            </button>
                          </li>
                        )}
                      {props.userProfile.permissions.lsRequest_api.change_lsrequest &&
                      (lsInfo.status !== 'Withdrawn - Former CM' ||
                      [...superAdminRoles, ...adminRoles, ...fourthTierRoles].includes(props.userProfile.role)) && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={onOpenUpdate}>
                            Update LS Request
                          </button>
                        </li>
                      )}
                      {props.userProfile.permissions.lsRequest_api
                        .view_lsrequest && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              let path = window.location.pathname.includes(
                                'myTask'
                              )
                                ? window.location.pathname
                                    .split('/')
                                    .slice(0, 3)
                                    .join('/')
                                : window.location.pathname
                                    .split('/')
                                    .slice(0, 2)
                                    .join('/');
                              window.location.href = `${path}/${lsInfo.ls_number}/history`;
                            }}>
                            View Change History
                          </button>
                        </li>
                      )}
                      {!rolesUnderCouncilMembers.includes(props.userProfile.role) &&
                        lsInfo.status !== 'Withdrawn - Former CM' && (
                        <li>
                          <button
                            className='dropdown-item'
                            type='button'
                            onClick={() => {
                              onOpenDuplicateCheck(lsInfo.ls_number);
                            }}>
                            Duplicate Search
                          </button>
                        </li>
                      )}
                    </ul>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {props.infoLocation === 'sidebar' && (
          <div
            className={`${styles['sidebar-fixed-fields']} ${
              props.infoLocation ? styles['dup-fixed-fields'] : ''
            }`}>
            <div className={styles['field-box']}>
              Status
              <br />
              <strong>{parse(lsInfo.status)}</strong>
            </div>
            <div className={styles['field-box']}>
              Submitted by
              <br />
              <strong>{parse(lsInfo.submitted_by.full_name)}</strong>
            </div>
          </div>
        )}
      </div>
      <div className={styles['ls-sidebar-fields']}>{lsDisplay}</div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
    userList: state.userReducer.userList,
    committeeList: state.userReducer.committees,
    updateTargetLs: state.lsRequestsReducer.updateTargetLs,
    updateTargetLsStatus: state.lsRequestsReducer.updateTargetLsStatus,
    highlights: state.lsRequestsReducer.highlights
  };
};

const mapDispatchToProps = {
  activateLsRequest,
  openOrCloseLsRequestServicePopup,
  getFileAndDownload,
};

export default connect(mapStateToProps, mapDispatchToProps)(LsInfoDisplay);
