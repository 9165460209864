import React, { useEffect, useRef, useState } from 'react';
import styles from '../../styles/CheckboxMenu.module.scss';
import {
  nonLegDivisionRoles,
  rolesUnderCouncilMembers
} from '../../services/constants';
import { connect } from 'react-redux';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

/*
CheckboxMenu Props
(Required)
checkAll: (bool) The boolean for the checkAll checkbox
checkboxes: (array) The values annd booleans for all the checboxes using a 'show' field as a boolean
toggleAllCheckboxes: (func) The function to toggle all the checkboxes
toggleCheckbox: (func) The function to toggle an individual checkbox
toggleCheckboxMenu: (func) The function that toggles the whole menu
isOpen: (bool) value that stores whether the menu is open
param: (str) The name of the field that contains the names of the input checkbox values

(Optional)
columnNumber: (int) Allows the user to select the number or columns of the menu up to 3(default 3)
componentType: (str): Input "popover" for the default popover style, or input "div" (Set a default?)
inputStyle: (str) Input a customized style for the component
idValue: (str) allows setting a custom id to the menu, usually used to close popovers (default PopoverMenu)

(Presets which are Optional)
preset: (str) the name of the preset being used currently (optional) (Disables presets)
presetNameBase: (str) Input a unique preset name to be saved as cookies
togglePreset: (func) Implements toggling between presets

(LSRs only)
filterFlag: (bool) Flag for adding styling
alphabetize: (bool) Alphabatize the field if desired
skipFirst: (bool) Allows skipping the first value for the input checkboxes
lsField: (bool) For LSRs make a check to hide a field under certain conditions
*/
const CheckboxMenu = props => {
  const {
    checkAll,
    checkboxes,
    toggleAllCheckboxes,
    params,
    preset,
    toggleCheckbox,
    toggleCheckboxMenu,
    togglePreset,
    userProfile,
    idValue,
    filterFlag
  } = props;
  const outsideClickRef = useRef(null);
  const [checkboxArray, setCheckboxArray]= useState(checkboxes)
  // useEffect hook for component did mount
  function handleClick(e) {
    let id = 'PopoverMenu'
    if(idValue) {
      id = idValue
    }
    let columnMenuButton = document.getElementById(id);
    if (
      outsideClickRef.current &&
      !outsideClickRef.current.contains(e.target)
    ) {
      if (columnMenuButton && !columnMenuButton.contains(e.target)) {
        toggleCheckboxMenu();
      }
    }
  }
  // let checkboxArray = props.checkboxes

  useEffect(() => {
    if (toggleCheckboxMenu) {
      document.addEventListener('mouseup', handleClick, false);
    }

    // component did unmount
    return () => {
      if (toggleCheckboxMenu) {
        document.removeEventListener('mouseup', handleClick, false);
      }
    };
    
  }, [toggleCheckboxMenu]);

  useEffect(() => {
    let mutableCheckbox = props.checkboxes
    if(props.skipFirst) {
      mutableCheckbox = Array.from(mutableCheckbox.slice(1));
    }
  
    if(props.alphabetize) {
      mutableCheckbox.sort((a, b) => {
        let title1 = a[params].toLowerCase();
        let title2 = b[params].toLowerCase();
  
        if (title1 < title2) return -1;
        if (title1 > title2) return 1;
        return 0;
      });
    }
  
    if(props.lsField){
      mutableCheckbox = mutableCheckbox.filter(
        alphaField =>
          !(
            nonLegDivisionRoles.includes(props.userProfile.role) &&
            alphaField.field === 'confidential_comments'
          ) && alphaField.viewability !== 'none'
      )
    }
    setCheckboxArray(mutableCheckbox)
  }, [props.checkboxes, props.skipFirst, props.alphabetize, props.lsField, props.userProfile])

  const presetsOptions = () => {
    return [
      `${userProfile.username}`,
      `${userProfile.username}-preset-1`,
      `${userProfile.username}-preset-2`,
      `${userProfile.username}-preset-3`,
    ].map((value, idx) => {
      return (
        <div
          key={`${idx}_${value}`}
          className={`${styles['checkbox-container']} col-6 col-sm-3`}>
          <input
            className={styles['value-checkbox']}
            id={
              value === userProfile.username
                ? 'no-preset'
                : `${props.presetNameBase}-${value}`
            }
            type='radio'
            name='presets'
            value={`${props.presetNameBase}-${value}`}
            checked={preset === `${props.presetNameBase}-${value}`}
            readOnly
          />
          <label
            className={styles['checkbox-label']}
            htmlFor={
              value === userProfile.username
                ? 'no-preset'
                : `${props.presetNameBase}-${value}`
            }
            onClick={() => togglePreset(`${props.presetNameBase}-${value}`)}
          />
          <label
            className={styles['checkbox-text-label']}
            htmlFor={
              value === userProfile.username
                ? 'no-preset'
                : `${props.presetNameBase}-${value}`
            }
            onClick={() => togglePreset(`${props.presetNameBase}-${value}`)}>
            { value === `${userProfile.username}-preset-3` &&
              !rolesUnderCouncilMembers.includes(userProfile.role) && props.lsField
                ? 'DUP SEARCH'
                : value === userProfile.username
                ? 'DEFAULT'
              : value
                  .replace(`${userProfile.username}-`, '')
                  .split('-')
                  .join(' ')
                  .toUpperCase()}
          </label>
        </div>
      );
    });
  };

  const dropDownCommitteeMenu = checkboxArray.map((com, idx) => {
    return (
      <li key={idx} className={styles['checkbox-container']}>
        <input
          className={styles['value-checkbox']}
          id={`cbx-${idx}`}
          type='checkbox'
          checked={com.show}
          readOnly
        />
        <label
          className={styles['checkbox-label']}
          htmlFor={`cbx-${idx}`}
          onClick={() => {
            toggleCheckbox(com[params]);
          }}></label>
        <label
          id={`checkbox-tooltip-${idx}`}
          className={`${styles['checkbox-text-label']}`}
          htmlFor={`cbx-${idx}`}
          onClick={() => {
            toggleCheckbox(com[params]);
          }}>
          {com[params].slice(0, 20) +
            (com[params].length > 20 ? '...' : '')}
        </label>

        {com[params].length > 20 && (
          <UncontrolledPopover
            innerClassName={styles['popover-container']}
            className={styles['popover-outer-container']}
            placement='right'
            target={`checkbox-tooltip-${idx}`}
            fade={false}
            trigger='hover'>
            <PopoverBody>{com[params]}</PopoverBody>
          </UncontrolledPopover>
        )}
      </li>
    );
  });

  const checkAllOption = (
    <li className={`${styles['checkbox-container']} col-12`}>
      <input
        className={styles['value-checkbox']}
        id='cbx-all'
        type='checkbox'
        checked={checkAll}
        readOnly
      />
      <label
        className={styles['checkbox-label']}
        htmlFor='cbx-all'
        onClick={toggleAllCheckboxes}></label>
      <label
        className={styles['checkbox-text-label']}
        htmlFor='cbx-all'
        onClick={toggleAllCheckboxes}>
        Check All
      </label>
    </li>
  );

  let containerStyle = '';
  if (props.inputStyle) {
    containerStyle = props.inputStyle;
  } else if (props.componentType === 'popover') {
    containerStyle = styles['checkbox-selector-container'];
  } else if(props.componentType === 'div') {
    containerStyle = styles['checkbox-selector-container-div'];
  }
  
  let wrapperStyle = props.columnNumber <= 3 ? styles[`column-count-${props.columnNumber}`] : null;

  return (
    <div ref={outsideClickRef} className={`${containerStyle} ${
      filterFlag === false && styles['status']
    } row`}>
      {preset && presetsOptions()}
      {preset && <hr className='col' style={{ margin: '.25rem 0 .75rem 0' }} />}
      <div className={`${styles['column-selector-wrapper']} ${wrapperStyle}`}>
        <ul>
          {checkAllOption}
          {dropDownCommitteeMenu}
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(CheckboxMenu);
